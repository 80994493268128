/* astshelving */
.about__us {

  flex-direction: column;
  display: flex;
  min-height: 100vh;
  height: auto;
  padding-top: 130px;
}
.ab__wrapper {
  padding-left: 0rem;
}
.right__bgImg,
.left__content {
  width: 100%;
}
.right__bgImg {
  height: 45vh;
}
.reach__out {
  width: 177px;
  font-size: 22px;
  line-height: 33px;
}
.left__content {
  padding-top: 3rem;
  line-height: 28px;
}
.about__us {
  padding-top: 70px;
  /* margin-bottom: 5rem; */
}
.ab__wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 3rem;
}
.ab__wrapper div {
  margin-bottom: 4rem;
  width: 100%;
  padding: 0 2rem 0 2rem ;
}
.left__content.new{
  justify-content: space-between;
  padding: 0 0rem 0 4rem;
  align-items: flex-start;
}
.left__content.new .ab__div_three.any{
  width: 280px;
}
.icons_wrap_new{
  /* border: 5px solid #6b6b6b; */
  background: transparent;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: block;
  line-height: 65px;
  text-align: center;
  margin: 0px auto;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
margin-bottom: 1rem;
animation: mymove 6s ease-in-out infinite;
}
@keyframes mymove {

  0%{transform: rotate(0deg)}
  50%{transform: rotate(0deg)}
  75% {transform: rotate(180deg);}
  100%{transform: rotate(0deg)}
}

.ab__wrapper div h4{
  text-align: center;
margin-bottom: 2rem;
font-size: 18px;
}
.icons_wrap_new svg{
color: #6b6b6b;
font-size: 2rem;
}
.ab__wrapper div p{
  /* text-align: center; */
  opacity: 0.9;
  font-size: 13px;

}
.left__content.new .fst p{
  line-height: 24px;
}
.left__content.new .fst p svg{
 margin-right: 1rem;
 color: teal;
 font-size: 17px;
}
.ab__div_three.any.tw h4{
  margin-bottom: 1.5rem ;
}
.ab__div_three.any.tw p{
  opacity: 0.8;
  line-height: 26px;

}
.ab__div_three.any h6{
font-size: 14px;
margin-bottom: 1.5rem ;

}





.retn__policy p,
.retn > p,
.retn__text ul li,
.retn__policy p,
.retn__support h4,
.faq__wrap h1,
.faq__wrap p,
.pp__wrap p,
.terms__ne_ol li,
.faq__ol li,
.terms__list ol li:nth-child(4) ul li,
.top p,
.terms__list ol li p,
.faq__wrap ol {
  font-size: 14px;
  line-height: 30px;
}
.pp__wrap h1,
.terms__list ol li h1,
.terms__wrap h1,
.retn__policy h1,
.terms__list ol,
.faq__wrap > h1,
.pp__wrap h1:nth-child(1) {
  font-size: 18px;
}
.faq__wrap h1 {
  font-size: 15px;
}
.faq__wrap > h1 {
  font-size: 20px;
}

/* .right__bgImg,
.left__content {
  width: 50%;
} */
.right__bgImg {
  position: relative;
}
.right__bgImg img {
  object-fit: cover;
}
.pp__wrap .pointer__img {
  height: 16px;
  width: 16px;
  margin-top: 0px;
}
.reach__out {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 233px;
  font-family: var(--font-Montserrat);
  font-weight: 600;
  font-size: 42.627px;
  line-height: 50px;
  color: #ffffff;
  text-transform: capitalize;
}

.left__content {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ab__div_two h6,
.ab__div_one h6 {
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 12px;
}
.ab__div_one p {
  font-weight: 300;
}
.ab__div_one .mail__icon {
  margin-right: 1rem;
}
.ab__div_two p {
  font-weight: 300;
  font-size: 15px;
}



/* trems  */
.pp__wrap h1,
.terms__list ol li h1,
.terms__wrap h1 {
  text-transform: uppercase;
  margin-top: 1.3rem;
  margin-bottom: 1rem;
  color: #1b1b1b;
  font-weight: 300;
}
.faq__wrap h1,
.faq__wrap p,
.pp__wrap p,
.terms__ne_ol li,
.faq__ol li,
.terms__list ol li:nth-child(4) ul li,
.top p,
.terms__list ol li p {
  font-weight: 300;
  color: #1b1b1b;
  margin-bottom: 1.5rem;
}
.terms__list ol li:nth-child(4) ul li {
  list-style: disc;
}
.product__home,
.pp_home,
.faq__home,
.terms__home {
  padding-top: 150px;
  margin-bottom: 5rem;
}
.faq__wrap,
.pp__wrap,
.terms__wrap {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.terms__wrap > h1 {
  text-align: center;
  margin-bottom: 3rem;
}
.top {
  margin-bottom: 2rem;
}
.faq__wrap > h1,
.pp__wrap h1:nth-child(1) {
  text-align: center;
  margin-bottom: 3rem;
}
.faq__wrap h1,
.pp__wrap h1 {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}
.faq__wrap a {
  color: #95652a;
}
.faq__wrap a:hover {
  color: #95652a;
  opacity: 0.8;
}

/* product  */
.product__wrapp h1 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.08em;
  color: #1b1b1b;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}
.pr_sh {
  margin-top: 3rem;
}
.product__home {
  min-height: 100vh;
}
.pr_qu_one h4 {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.08em;
  color: #1b1b1b;
  position: relative;
}

.pr_qu_one {
  margin-bottom: 1.6rem;
}
.product__wrapp {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.pr_qu_one p {
  color: #1b1b1b;
  font-weight: 100;
  font-size: 14px;
  margin-top: 2rem;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
  height: 0;
}
.bi__plus {
  cursor: pointer;
}
.bi__plus:hover {
  color: #95652a;
}
.bi__plus {
  opacity: 1;
  visibility: visible;
  transition: var(--animation);
  margin-right: 0.5rem;
}
.bi__minus {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 7px;
  transition: var(--animation);
  margin-right: 0.5rem;
}
.pr_qu_one.active .bi__plus {
  opacity: 0;
  visibility: hidden;
}
.pr_qu_one.active .bi__minus {
  opacity: 1;
  visibility: visible;
}
.pr_qu_one.active p {
  opacity: 1;
  visibility: visible;
  height: fit-content;
  -moz-height: fit-content;
}
.pp__wrap ul li {
  list-style: none;
  display: flex;
  margin-bottom: 1.5rem;
}
.retn__text ul li {
  margin-bottom: 1rem;
}
.faq__ol li,
.terms__wrap .terms__ne_ol li,
.terms__list ol li:nth-child(4) ul li {
  list-style: none;
  margin-bottom: 1.5rem;
  display: -webkit-box;
}
.pointer__img {
  height: 10px;
  width: 10px;
  margin-right: 1.5rem;
  border-radius: 100%;
  background: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
}

/* return policy */

.retn__policy h1 {
  text-align: center;
  letter-spacing: 0.08em;
  color: #1b1b1b;
  margin-bottom: 3rem;
}
.retn__policy {
  min-height: 100vh;
}
.retn__policy p {
  font-weight: 300;
  line-height: 28px;
  color: #1b1b1b;
  margin-bottom: 1.5rem;
}
.retn > p {
  margin-bottom: 0.5rem;
}
.retn__policy p,
.retn > p,
.retn__text ul li {
  font-weight: 300;
  color: #1b1b1b;
  list-style: none;

  display: -webkit-box;
}
.retn__support h4 {
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #1b1b1b;
  margin-bottom: 0;
}
.retn__support {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}
.rent__mail__icon {
  font-size: 1.3rem;
  margin-right: 1rem;
  cursor: pointer;
}
.retn__policy {
  padding-top: 170px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.retn__heads {
  text-align: start;
}
.retn {
  width: 80%;
}


@media screen and (max-width: 896px) {

  .retn__policy {
    width: 70%;
  }

  .reach__out {
    width: 213px;
    font-size: 50.627px;
    line-height: 60px;
  }
}
@media screen and (max-width: 768px) {
  .pointer__img {
    height: 15px;
    width: 15px;
  }
  .retn__policy {
    padding-top: 120px;
  }
  .ab__wrapper{
    flex-direction: column;
  }
  /* .retn__policy p,
  .retn > p,
  .retn__text ul li,
  .retn__policy p,
  .retn__support h4,
  .faq__wrap h1,
  .faq__wrap p,
  .pp__wrap p,
  .terms__ne_ol li,
  .faq__ol li,
  .terms__list ol li:nth-child(4) ul li,
  .top p,
  .terms__list ol li p,
  .faq__wrap ol  {
    font-size: 12px;
    line-height: 22px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1,
  .retn__policy h1,
  .terms__list ol,
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    font-size: 14px;
  } */
  .product__home,
  .pp_home,
  .faq__home,
  .terms__home {
    padding-top: 100px;
  }
  .retn__policy {
    width: 80%;
  }
  .about__us {
    padding-top: 130px;
  }
  .left__content {
    line-height: 25px;
  }

  .reach__out {
    width: 160px;
    font-size: 30.627px;
    line-height: 44px;
  }
}
@media screen and (max-width: 580px) {
  .about__us {
    display: flex;
    flex-direction: column;
  }
  .about__us {
    height: auto;
  }

  .right__bgImg,
  .left__content {
    width: 100%;
  }
  .right__bgImg {
    height: 25vh;
  }
  .reach__out {
    width: 177px;
    font-size: 22px;
    line-height: 33px;
  }
  .left__content {
    padding-top: 3rem;
    line-height: 28px;
  }
 
  .pointer__img {
    height: 12px;
    width: 12px;
  }

  .ab__wrapper div {
    margin-bottom: 3rem;
  }
  .retn__policy p,
  .retn > p,
  .retn__text ul li,
  .retn__policy p,
  .retn__support h4,
  .faq__wrap h1,
  .faq__wrap p,
  .pp__wrap p,
  .terms__ne_ol li,
  .faq__ol li,
  .terms__list ol li:nth-child(4) ul li,
  .top p,
  .terms__list ol li p,
  .faq__wrap ol {
    font-size: 12px;
    line-height: 28px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1,
  .retn__policy h1,
  .terms__list ol,
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    font-size: 14px;
  }
  .retn__policy {
    padding-top: 90px;
  }
  .product__home,
  .pp_home,
  .faq__home,
  .terms__home {
    padding-top: 60px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1 {
    line-height: 15px;
  }
}

@media screen and (max-width: 420px) {
  .pointer__img {
    height: 10px;
    width: 10px;
    margin-top: 5px;
  }

  .retn__policy p,
  .retn > p,
  .retn__text ul li,
  .retn__policy p,
  .retn__support h4,
  .faq__wrap h1,
  .faq__wrap p,
  .pp__wrap p,
  .terms__ne_ol li,
  .faq__ol li,
  .terms__list ol li:nth-child(4) ul li,
  .top p,
  .terms__list ol li p,
  .faq__wrap ol {
    font-size: 11px;
    line-height: 24px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1,
  .retn__policy h1,
  .terms__list ol,
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    font-size: 12px;
  }
}
@media screen and (max-width: 370px) {
  .ab__wrapper {
    padding: 1rem;
  }
  .left__content {
    font-size: 12px;
  }
  .ab__div_two p {
    font-size: 13px;
  }
  .pointer__img {
    height: 8px;
    width: 8px;
    margin-top: 6px;
  }
  ol,
  ul {
    padding-left: 1rem;
  }
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    text-align: center;
    margin-bottom: 2rem;
  }
}

.footer_new > p {
  margin-bottom: 0rem;
}
.footer_new p {
  font-size: 13px;
  margin-bottom: 1rem;
  color: #464545cc;
  line-height: 25px;
}
.footer_content,
.footer_foot {
  background: #f7f7f7;
}
.footer {
  display: flex;
  justify-content: space-around;
  padding-left: 45px;
  height: 400px;
  color: var(--text-color-black);
  background: #f7f7f7;
  padding: 0;
  margin: 0;
  width: 100vw;
}
.footer_content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 2rem;
  padding-top: 3rem;
}
.footer_content h1 {
  font-weight: var(--normal-font-weight);
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #464545cc;
  text-transform: uppercase;
}
.footer_text_wrap {
  height: 100%;
  display: flex;
  width: 500px;
}
.footer_text {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: 30px;
  font-size: 13px;
  letter-spacing: 0.05em;
  padding: 0;
}
.footer_foot h1:hover {
  color: #95621a;
}
.footer_text span {
  font-size: 12px;
  cursor: pointer;
  opacity: 0.8;
}
.footer_text span:hover {
  color: #5c3d2e;
}
.footer_text.icon {
  margin-top: -4rem;
  padding-left: initial;
  width: 220px;
}
.footer_icons {
  margin-bottom: 3rem;
}
.footer_text.icon p {
  line-height: 20px !important;
}
.react__icon {
  height: 35px;
  width: 35px;
  background-color: #e6e6e6;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
  color: Black;
}
#In__icon,
#fb__icon,
#Tw__icon,
#Ln__icon {
  transition: all 0.3s ease;
}
#fb__icon:hover {
  color: #4267b2;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}
#In__icon:hover {
  color: #cd486b;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}
#Tw__icon:hover {
  color: #1da1f2;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}
#Ln__icon:hover {
  color: #075e54;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}

.footer_foot p {
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  opacity: 0.9;
  text-align: start;
  margin-left: 4.5rem;
}
.footer_foot p a:nth-child(2) {
  text-decoration: none;
  opacity: 1 !important;
  color: #1781bb !important;
}
.footer_foot p a:nth-child(1) {
  text-decoration: none;
  color: #5c3d2e !important;
}
.footer_foot p a:hover {
  color: #9e7a46;
}

@media screen and (max-width: 768px) {
  .footer_text.tw {
    width: 70px;
    margin-left: 3rem;
  }
  .footer_text_wrap {
    width: 100%;
    justify-content: space-evenly;
  }
  .footer_text.icon {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .footer_foot {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer_text.tw {
    width: fit-content;
    -moz-width: fit-content;
    margin-left: 0rem;
  }
  .footer_text {
    width: fit-content;
  }
  .footer_foot p {
    margin: 0;
  }
  .footer {
    height: 300px;
  }
  .footer_foot p {
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 470px) {
  .footer_text_wrap {
    justify-content: space-between;
  }
}

@media screen and (max-width: 400px) {
  .footer_text_wrap {
    flex-direction: column;
    align-items: center;
  }
  .footer_text {
    max-width: 100% !important;
    width: 100%;
    align-items: center;
  }
  .footer_text.main {
    margin-bottom: 2rem;
  }
  .footer_new p {
    text-align: center;
  }
}

@media screen and (max-width: 360px) {
  .footer_text a,
  .footer_content h1 {
    font-size: 11px;
    line-height: 20px;
  }
  .react__icon {
    height: 20px;
    width: 20px;
  }
  #In__icon,
  #fb__icon,
  #Tw__icon,
  #Ln__icon {
    width: 10px;
    height: 10px;
  }
  .footer_foot p {
    margin-bottom: 2rem;
    font-size: 10px;
    align-self: center;
  }
  .footer_text_wrap {
    justify-content: space-between;
  }
}

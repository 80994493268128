
.mega-drop {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  right: 0;

  width: fit-content;
  -moz-width: fit-content;
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out all 500ms;
  z-index: 8;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}
.mega-drop .content {
  border-radius: 10px;

  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  z-index: 8;
}
.content .row {
  line-height: 30px;
}
.row a {
  text-decoration: none;
}
.mega-link-wrapper {
  justify-content: center;
  display: grid;
  width: auto;
  padding: 0;
  width: 100%;
}
.mega-link-wrapper > h4 {
  text-align: center;
  text-transform: uppercase;
  color: #111;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.content .row header {
  font-size: 20px;
  color: #cc9b6d;
  font-weight: 500;
  text-transform: uppercase;
}
.row-wrapper {
  height: auto;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.login__image {
  width: 40% !important;
}
.row .mega-link {
  margin-top: 0px;
  padding: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 30px;
}
.row .mega-link li {
  font-size: 14px;
  width: fit-content;
  -moz-width: fit-content;
  display: block;
  background: transparent;
  color: #111;
  cursor: pointer;
  margin: 0;
}
.row .mega-link li:hover {
  color: #cc9b6d;
  opacity: 1;
}
.mega-link > li::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: solid 2px rgba(0, 0, 0, 0.8);
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transition: transform 0.4s ease-in-out;
}
.mega-link > li:hover::after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform-origin: 0% 100%;
}
.arrow_wrapper {
  width: 20px;
  height: 20px;
  display: grid;
  justify-content: center;
  margin-top: 5px;
}
.nav-link:hover .down {
  opacity: 0;
  visibility: hidden;
}
.nav-link:hover .up {
  opacity: 1;
  visibility: visible;
}
.down {
  transition: 0.3s;
}
.up {
  margin-top: -18px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
#product_link > a {
  color: #fff !important;
  display: flex;
}
.nav-link {
  text-transform: uppercase;
  list-style: none;
  display: flex;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 5px;
  transition: all ease-in-out 350ms;
  cursor: pointer;
}
.nav-link:hover .mega-drop {
  opacity: 1;
  visibility: visible;
  top: 50px;
  transition: all 0.3s ease;
}

.queries p {
  margin-bottom: 0;
  font-size: 14px;
  margin-left: 1rem;
  color: #fff;
}
.queries .qu_icon svg {
  font-size: 21px;
  color: #fff;
}
.navBrown #product_link > a {
  color: #333 !important;
}
.navbar_wrap.active #product_link > a {
  color: #333 !important;
}
.queries {
  display: flex;
  align-items: center;
  justify-content: center;
}
.queries .qu_icon svg {
  animation: shake 2s;
  animation-iteration-count: infinite;
}
@keyframes shake {
  0.5% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  1% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  15% {
    transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
  }
  15% {
    transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
  }
  25% {
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
  }
  35% {
    transform: rotate(6deg);
    -webkit-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
  }
  50% {
    transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}
.qu_icon {
  animation: zoom 2s ease infinite;
}
@keyframes zoom {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1 ,1);
    -ms-transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1 ,1);
    -ms-transform: scale(1, 1);
  }
}
.bars_wrap {
  color: #fff;
}
.navBrown .menu__bar_ul li {
  color: #333;
}
.navbar_wrap.navBrown .queries .qu_icon svg,
.navbar_wrap.navBrown .queries p {
  color: #111;
}
.navbar_wrap.active.navBrown .queries .qu_icon svg,
.navbar_wrap.active.navBrown .queries p {
  color: rgb(0, 0, 0);
}
.navbar_wrap.active .queries .qu_icon svg,
.navbar_wrap.active .queries p {
  color: #000;
}
.product_drop-new {
  color: currentColor !important;
  background: transparent !important;
  color: #111;
  border: none !important;
  padding: 0;
}
.navbar_wrap.navBrown .product_drop-new {
  color: #333 !important;
}
.navbar_wrap.active.navBrown .product_drop-new {
  color: rgb(0, 0, 0) !important;
}
.dropdown-menu {
  background: #fff !important;
  height: fit-content !important;
  -moz-height: fit-content;
  min-width: 9rem !important;
}
.dropdown-toggle::after {
  margin-bottom: -2px !important;
}

.userName {
  padding-left: 1rem;
}
.menu__bar_ul a {
  text-decoration: none;
}
a:link h1 {
  text-decoration: none !important;
  color: none !important;
}

.forgot {
  color: #fff !important;
}

.forgot :hover {
  color: #000 !important;
}

.highlight {
  color: #f3ca91 !important;
}

.highlight :hover {
  color: #fff !important;
}
.noItems_cart {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.weight_btn {
  margin-bottom: 0;
}
.card_price {
  font-size: 18px !important;
}
.navbar_icon_gp {
  color: white;
  justify-content: space-evenly;
}
.user__icon,
.order__icon,
.info__icon {
  cursor: pointer;
  font-size: 20px;
  margin-right: 0.8rem;
  opacity: 0.8;
  transition: var(--animation);
}
.acct_history,
.acct_info,
.acct_header,
.acct_user_ac {
  color: #fff;
}

#brown_hover:hover {
  color: #95621a !important;
}

.nav_row.navHideT {
  z-index: 11;
}
.bars.bar_ul {
  height: 80px;
}
.current-menu {
  font-weight: 600;
  font-size: 15px;
}
.navbar_wrap.navBrown ul a li {
  color: #333;
}
.navbar_wrap.navBrown ul a li:hover {
  color: #88430e;
}
.navbar_wrap.navBrown .icon_gp_user,
.navbar_wrap.navBrown .icon_gp_bag {
  color: #5c3d2e;
}
.navbar_wrap.navBrown .icon_gp_user:hover,
.navbar_wrap.navBrown .icon_gp_bag:hover {
  color: #88430e;
}
.navbar_wrap.active.navBrown .icon_gp_user,
.navbar_wrap.active.navBrown .icon_gp_bag {
  color: #fff;
}
.navbar_wrap.active .menu__bar_ul {
  padding-top: 0;
}
.menu__bar_ul a {
  text-decoration: none;
  color: #fff !important;
  display: flex;
}
.nav__two {
  position: fixed;
  width: 100vw;
  padding-top: 55px;
  padding-bottom: 0;
  transition: all 0.3s ease-in-out;
  margin: 0;
  padding: 0;
}
.nav__wrap.navBrown .menu__bar_ul a li {
  color: #5c3d2e;
}
.navbar_wrap.active #product__link {
  color: #333 !important;
}
.navbar_wrap.navBrown #product__link {
  color: #333 !important;
}

.nav__two.navHide {
  opacity: 0;
  visibility: hidden;
}
.nav__two.navShow {
  opacity: 0;
  visibility: hidden;
  padding-top: 50px;
}
.nav__wrap {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.nav__wrap.actived {
  height: 40px;
  padding: 1rem;
  background: #fff;
}
.nav__wrap.actived ul a li {
  color: #fff;
}
.navbar_wrap.active .menu__bar_ul li {
  color: #333;
}
.nav__wrap.actived ul a li:hover,
.menu__bar_ul li:hover {
  color: #cc9b6d;
}
.navbar_wrap {
  height: var(--navbar-height);
  align-items: center;
  display: grid;
  position: absolute;
  position: fixed;
  z-index: 15;
  transition: all 0.3s ease-in-out;
  width: 100vw;
}
.navbar_wrap.active {
  height: 55px;
  background: #fff;
}
.navbar_wrap.active .layamer_logo_wrap > img {
  width: 190px;
  height: 40px;
}
.navbar_wrap.active .icon_gp_bag,
.user__icon {
  font-size: 22px;
}
.navbar_wrap.active .cart_number {
  height: 24px;
  width: 24px;
}
.navbar_wrap.active .cart_number p {
  font-size: 16px;
}
.nav_row {
  z-index: 12;
  width: 100vw;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.fa__bars {
  opacity: 0;
  visibility: hidden;
  color: #fff;
  font-size: 20px;
}
.bars {
  width: 50px;
  height: 100%;
  cursor: pointer;
  z-index: 9;
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: var(--animation);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}
.bars div {
  width: 20px;
  height: 1.79px;
  background: var(--div-bg-white);
  transition: var(--animation);
}
.bar1 {
  transition: var(--animation);
  transform-origin: left top;
  margin-bottom: 8px;
}
.bar3 {
  transition: var(--animation);
  transform-origin: left bottom;
  transform: scaleZ(0deg);
  -webkit-transform: scaleZ(0deg);
  -ms-transform: scaleZ(0deg);
}
.bars.active .bar1 {
  transform: rotateZ(40deg) translate(-1px, -1px);
}
.bars.active .bar3 {
  transform: rotateZ(-40deg) translate(-1px, 0px);
}
.home_menu {
  position: relative;
  transition: var(--animation);
  opacity: 0;
  transform: translateX(0rem);
  -webkit-transform: translateX(0rem);
  -ms-transform: translateX(0rem);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.home_menu.active {
  transform: translateX(6rem);
  -webkit-transform: translateX(6rem);
  -ms-transform: translateX(6rem);
  opacity: 1;
}
.home_menu.active .drop-item {
  opacity: 1;
  visibility: visible;
}
.drop-item {
  text-transform: uppercase;
  font-weight: 300;
  font-size: var(--text-font-size);
  text-align: center;
  color: var(--text-color-black);
  text-decoration: none;
  transition: ease all 0.4s;
  opacity: 0;
  visibility: hidden;
  background: transparent;
}
.drop-item:nth-child(1) {
  margin-right: 30px;
}
.drop-item:hover {
  color: #cc9b6d !important;
}
.layamer_logo_wrap > img {
  width: 220px;
  height: 50px;
  transition: 0.5s;
}
.navbar_icon_gp {
  justify-content: space-evenly;
}
.icon_gp_user {
  width: 26px;
  height: 26px;
}
.icon_gp_user,
.icon_gp_bag {
  font-size: 17px;
  cursor: pointer;
  color: var(--text-color-white);
}
.icon_gp_bag {
  font-size: 25px;
  transition: var(--animation);
}
.icon_gp_bag:hover,
.icon_gp_user:hover {
  color: #cc9b6d;
}
.cart_header .icon_gp_bag {
  color: #111;
}
.cart_number {
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: 3px;

  background: #11111159;
  color: #fff;
  transition: var(--animation);
}
.shopping_bag {
  transition: var(--animation);
}
.cart_number p {
  font-size: 13px;
  margin-bottom: 0px;
  cursor: pointer;
  font-weight: 500;
  transition: var(--animation);
}
.cart_header .cart_number p {
  color: #fff;
}


.menu__bar_ul {
  display: flex;
  justify-content: center;
  list-style: none;
  align-items: center;
  margin-bottom: 0;

  width: 100vw;
  cursor: pointer;
}
.menu__bar_ul li {
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  text-align: start;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}
/* navbar 2  start*/

/* //my account// */
.acct_dropdown {
  display: none;
  width: 336px;
  height: 105vh;
  left: auto;
  display: grid;
  align-items: center;
  text-align: center;
  background: linear-gradient(
    164.53deg,
    rgb(0 0 0 / 78%) -28.7%,
    rgb(0 0 0 / 68%) 152.85%
  );
  backdrop-filter: blur(10px);

  -moz-backdrop-filter: blur(10px);

  border-radius: 0px;
  transform: translateX(350px);
  -webkit-transform: translateX(350px);
  -ms-transform: translateX(350px);
  overflow: hidden;
  transition: var(--animation-4s);
  animation: sidebar-close 0.4s backwards;
  opacity: 0;
  z-index: 16;
  position: fixed;
  color: #fff;
}
.acct_dropdown.active {
  animation: sidebar-open 0.4s forwards;
  transition: var(--animation-4s);
  opacity: 1;
}
@keyframes sidebar-open {
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
@keyframes sidebar-close {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    transform: translateX(350px);
    -webkit-transform: translateX(350px);
    -ms-transform: translateX(350px);
  }
}
.acct_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 241px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
}
.acct_header a div {
  width: 200px;
  margin-bottom: 1.55rem;
}
.close_btn {
  position: absolute;
  top: 2rem;
  right: 1rem;
}
.user__icon {
  opacity: 1;
  transition: var(--animation);
}

.acct_header h1 {
  opacity: 1 !important;
  font-size: 22px;
}
.acct_info span:after,
.acct_history span:after,
.acct_header h1:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #888;
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}
.acct_info span:hover:after,
.acct_history span:hover:after,
.acct_header h1:hover:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform-origin: bottom center;
}

.acct_header h1,
.acct_history span,
.acct_info span {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  opacity: 0.8;
  transition: var(--animation);
  display: inline-block;
  position: relative;
  width: 130px;
  text-align: start;
}
.acct_buttons {
  margin-bottom: 30px;
}
.acct_buttons button {
  width: 180px;
  height: 35px;
  font-size: 16px;
  border-radius: 15px;
  backdrop-filter: blur(17px);
  -moz-backdrop-filter: blur(17px);
  text-transform: uppercase;
}
#log_in {
  color: #000000;
  background: darkseagreen;
}
#log_in:hover {
  opacity: 0.9;
  border: 1px solid var(--success-color);
  font-weight: 500;
}
#log_out {
  color: #fff;
}
#log_out:hover {
  opacity: 0.9;
  border: 1px solid var(--danger-color);
  font-weight: 500;
}
.acct_login {
  color: var(--text-color-black);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  transition: var(--animation);
  border: 1px solid #fff;
}
.vsc__close {
  font-size: 30px;
}
/* MY ACCOUNT END */

/* cart START*/
.side_cart.brown {
  background: linear-gradient(
    164.53deg,
    rgba(64, 64, 64, 0.258) -28.7%,
    rgba(41, 37, 37, 0) 152.85%
  );
}
.side_cart {
  background: #ffffff40;
  z-index: 16;
  font-weight: var(--normal-font-weight);
  font-size: var(--text-font-size);
  line-height: var(--normal-line-height);
  width: 460px;
  color: #111;
  backdrop-filter: blur(17px);
  -moz-backdrop-filter: blur(17px);

  border-radius: 0px;
  transform: translateX(650px);
  -webkit-transform: translateX(650px);
  -ms-transform: translateX(650px);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  animation: sideCart-close 0.5s backwards;
  opacity: 0;
  left: auto;
  height: 105vh;
  padding-bottom: 6rem;
  position: fixed;
}
.side_cart::-webkit-scrollbar {
  display: none;
}
.side_cart.active {
  animation: sideCart-open 0.5s forwards;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}
@keyframes sideCart-open {
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
@keyframes sideCart-close {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    transform: translateX(650px);
    -webkit-transform: translateX(650px);
    -ms-transform: translateX(650px);
  }
}

.cart_header {
  border-bottom: 1px solid #111;
  width: 229px;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.cart_header span {
  height: 63px;
  text-transform: uppercase;
  line-height: 60px;
}
.cart_number.my_bag {
  padding-top: 4px;
}
/* {cart default start} */
.cart__default {
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  height: 80%;
  align-items: center;
  justify-content: center;
}
.cart__default h1 {
  font-size: 20px;
  text-align: center;
  margin-top: 1rem;
  text-transform: uppercase;
}
.ri__cart_icon {
  font-size: 80px;
  width: 100px;
}
/* {cart default end} */

/* card ONE,TWO START  */
.close__icon__hover:hover {
  color: rgb(112, 35, 35);
  cursor: pointer;
}
.close__cart {
  font-size: 2rem;
}
.close_btns {
  height: 23px;
  width: 23px;
  top: -1.1rem;
  left: 90%;
  border: 1px solid rgb(154 151 151);
  border-radius: 100%;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart_item_card {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: auto;
  height: auto;
  padding: 2rem;
  background: #ffffff85;
  color: #111;
  border: 2.05833px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(34.9917px);
  -moz-backdrop-filter: blur(34.9917px);
  border-radius: 20px;
  justify-content: space-between;
}

.cart_item_card .card_content .table_wrap {
  margin-top: 0;
}
.card_content span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.card_content p {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 550;
}
.item_name {
  width: max-content;
  height: auto;
  margin-bottom: 2rem;
}
.item_name h1,
.item_name h2 {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
  font-family: var(--font-Montserrat);
}
.out_ofs {
  font-size: 10px;
}
.item_name p {
  font-size: 15.1899px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: var(--font-Montserrat);
}
.table_wrap {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.table__item {
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
.table__item .length {
  margin-top: 5px;
  margin-bottom: 13px;
}
.item_details {
  margin-left: -1rem;
  font-size: 14px;
  line-height: 18px;
}
.item_details tr {
  line-height: 1.5rem;
}
.item_details tr td {
  padding-left: 0.8rem;
  padding-right: 0.5rem;
}
.btn-group {
  margin-left: -15px;
}
.btn-group span {
  border: 0.6328px solid #000;
  box-sizing: border-box;
  border-radius: 0;
  width: 20.63px;
  height: 18.99px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.btn span {
  margin-left: 8px;
  margin-right: 8px;
  line-height: 1px;
  border: none !important;
  font-size: 14px;
}
.plus_minus {
  background: #555;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transition: all ease 0.3s;
}
.plus_minus:hover {
  background: #333;
}
.card_img img {
  width: 110px;
  height: 150px;
  border-radius: 16px;
  object-fit: fill;
}
.line_break {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
/* CARD ONE,TWO END */

/* card THREE START*/
.sp_Card {
  height: auto;
  width: 350px;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  border: 2.05833px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  backdrop-filter: blur(34.9917px);
  -moz-backdrop-filter: blur(34.9917px);

  border-radius: 30.875px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 3rem;
  max-height: fit-content;
  -moz-max-height: fit-content;
}
.sp_Card > h1 {
  font-size: 16px;
  line-height: 30px;
  margin: 2rem;
  font-family: var(--font-Montserrat);
  width: fit-content;
  -moz-width: fit-content;
  border-bottom: 1px solid #cfcfcf;
}
.sp_sub_card p {
  font-size: 16px !important;
  line-height: 30px !important;
}
.sp_sub_card {
  margin-bottom: 2rem;
  justify-content: space-around;
}
.sp_card_img img {
  cursor: pointer;
  width: 120px;
  height: 160px;
  border-radius: 16px;
}
.add_btn {
  width: 156px;
  height: 30px;
  margin-top: 1rem;
  padding: 0;
  font-size: 11.3924px;
  line-height: 18px;
  color: var(--text-color-black);
  overflow: hidden;
  position: relative;
  border: 0.632911px solid #111;
  display: flex !important;
}
.add_btn:after {
  background-color: var(--div-bg-white);
  content: "";
  height: 22px;
  opacity: 0.2;
  position: absolute;
  transform: rotate(120deg);
  -webkit-transform:  rotate(120deg);
  -ms-transform:  rotate(120deg);
  transition: all 1.8s cubic-bezier(0.19, 1, 0.22, 1);
  width: 70px;
  z-index: -10;
  top: -7px;
  bottom: 0;
  left: -83px;
  right: 0;
}
.add_btn span {
  z-index: 20;
  font-family: var(--font-Montserrat);
  font-size: 13px;
}
.add_btn:hover::after {
  left: 150px;
}
.add_btn:hover {
  border: 0.632911px solid #8c5c37;
}
/* CARD THREE END */

/*CART buttons START*/
.btn_bottom {
  flex-direction: column;
}
.btn_bottom button a {
  text-decoration: none;
  color: currentColor;
}
.btn_bottom button {
  width: 200px;
  height: 55px;
  border-radius: 15px;
  line-height: var(--extra-line-height);
  margin-bottom: 2rem;
  font-family: var(--font-Montserrat);
  font-weight: var(--normal-font-weight);
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
}
.btn_explore {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);

  transition: var(--animation);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_explore {
  transition: var(--animation);
}
.btn_explore:hover {
  border: 1px solid #fff;
}
.acct_login:hover,
.btn_explore:hover {
  letter-spacing: 3px;
}
.register_btn button::before,
.acct_login::before,
.btn_explore::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(-40deg) translateX(0);
  transition: all 0.5s ease-out;
}
.register_btn button:hover::before,
.acct_login:hover::before,
.btn_explore:hover::before {
  transform: skewX(0deg) translateX(100%);
  border-radius: 15px;
}

.btn_checkout {
  box-sizing: border-box;
  backdrop-filter: blur(17px);
  -moz-backdrop-filter: blur(17px);
  transition: all ease-in-out 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  background: #184d47;
  color: #c6ebc9;
}
.btn_checkout:hover {
  color: #c6ebc9;
  letter-spacing: 1px;
  border: 2px solid #c6ebc9;
}
/* CART BUTTONS END */
/* CART END */

/* {LOGIN SECTION START } */
.login__wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100vh;
  position: fixed;
  z-index: 10;
  display: flex;
  transform: translateY(0rem);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
  background: #f0f0f0f0;
}
.login__wrapper.show {
  display: flex;
  transform: translateY(0rem);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
}
.login__modal {
  position: absolute;

  top: 25%;
  position: initial;
  width: 70%;
  height: 70%;
  max-height: 400px;
  max-width: 680px;
  background: #ffff;
  box-shadow: rgba(177, 137, 137, 0.1) 0px 4px 12px;

  backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  border-radius: 25px;
  color: #000000;
  position: absolute;
  z-index: 15;
  justify-content: space-evenly;
  transform: translateY(0rem);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
  transition: all ease-in-out 0.6s;
  display: flex;
  align-items: center;
}
.login__modal .login__image img {
  height: 100%;
}
.login__modal.active {
  transform: translateY(0);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
}
.close__icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.close__icon:hover {
  color: red;
}
.register__terms {
  margin-bottom: 2rem;
}
.register__img,
.login__image {
  width: 30%;
  height: 80%;
  border-radius: 15px;
}
.login__image img {
  object-fit: cover;
  border-radius: 15px;
}
.login__content {
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}
.login__content h1 {
  margin-bottom: 2rem;
  font-size: 18px;
}

.input__gp {
  flex-direction: column;
  width: 100%;
}
.input__gp input::placeholder {
  color: #333;
}
.input__gp input:focus {
  background: transparent;
  color: #333;
}
.input__gp input {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 2px solid #666;
  padding: 5px 10px;
  outline: none;
  height: 30px;
  font-size: 14px;
  margin-bottom: 2rem;
  color: rgb(0, 0, 0);
  border-radius: 0;
}

.input__gp input {
  margin-bottom: 1.5rem;
}
.login__button {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
}
.btn--login {
  width: 149px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 10%);
  -webkit-backdrop-filter: blur(63px);
  backdrop-filter: blur(63px);
  border-radius: 7px;
  font-size: 14px;
  text-align: center;
  height: 30px;
  transition: all 0.6s;
  position: relative;
  cursor: pointer;
  border: 2px solid #184d47;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #184d47;
  color: #ffff;
}
.btn--login::before {
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background: #185f57;
  border-radius: 5px;
  z-index: -2;
}
.btn--login:hover:before {
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}
.login__button p {
  font-weight: 300;
  font-size: 10px;
  line-height: 30px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  cursor: pointer;
  transition: var(--animation);
  color: #333;
}
.login__button p:hover {
  color: #c4c4c4;
}
.create__ac {
  width: 100%;
  flex-direction: column;
  margin-top: 3rem;
}
.create__ac p {
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}
.create__ac button {
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  width: 100%;
  height: 30px;
  border-radius: 7px;
  color: rgb(0, 0, 0);
  display: inline-block;
  text-align: center;
  position: relative;
  transition: 0.5s all ease;
  background: transparent;
  border: 1px solid #c4c4c4;
}
.create__ac button:hover {
  letter-spacing: 1.3px;
}
/* {LOGIN SECTION END } */

/* {CREATE ACCOUNT START} */
.register__modal {
  --gray-color: #fff;
  color: var(--gray-color);
  transition: all ease 0.4s;
}
.register__modal.active {
  transform: translateY(0);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
}
.register_btn p,
.register__terms p {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--gray-color);
}
.register__terms p a {
  color: var(--gray-color);
}
.register__content h1 {
  margin-right: 4rem;
}
.register_btn button {
  border: none;
  width: 150px;
  height: 35px;
  font-size: 14px;
  border-radius: 14px;
  color: var(--gray-color) !important;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  transition: all 0.3s ease;
  border: 1px solid transparent;
}
.register_btn button:hover {
  letter-spacing: 2px;
  border: 2px solid #c4c4c4;
}
.register__form input {
  margin-bottom: 1.3rem;
  background: transparent;
}
.register__form input::placeholder {
  color: var(--gray-color);
}
.register__form {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
/* {CREATE ACCOUNT END} */

@media screen and (min-width: 1080px) {
  .personal__info,
  .shipping__address {
    max-width: 350px;
  }
}

@media screen and (max-width: 1080px) {
  .icon_gp_user {
    margin-right: 1.4rem;
  }
  .cart_number {
    height: 25px;
    width: 25px;
  }
  .cart_number p {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .add_btn {
    width: 140px;
  }

  .btn--login {
    width: 100px;
  }
  .register_btn p,
  .register__terms p {
    font-size: 10px;
  }
  .register_btn button {
    width: 100px;
    height: 30px;
    font-size: 11px;
  }
}
@media screen and (max-width: 896px) {
  .add_btn {
    width: 140px;
  }
}
@media screen and (max-width: 768px) {
  .login__image {
    display: none;
  }
  .login__content {
    width: 80%;
  }

  .layamer_logo_wrap > img {
    width: 180px;
    height: 40px;
  }
  .acct_history span,
  .acct_info span {
    font-size: 15px;
  }
  .user__icon,
  .order__icon,
  .info__icon {
    margin-right: 0;
    font-size: 18px;
  }
  .acct_header h1 {
    font-size: 18px;
  }
  .acct_buttons button {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
  .menu__bar_ul.active a li:hover {
    color: #88430e !important;
  }
  .navbar_wrap.navBrown .bars div {
    background-color: #88430e;
  }
  .bars.active .bar1,
  .bars.active .bar3 {
    background: #fff !important;
  }

  .navbar_wrap.active.navBrown .bars div {
    background-color: #fff;
  }
  .navbar_wrap.navBrown .menu__bar_ul a li {
    color: #333;
  }
  .nav__wrap.actived {
    background: transparent;
  }
  .nav__two.navHide,
  .bars,
  .fa__bars,
  .nav__wrap .menu__bar_ul.active {
    opacity: 1;
    visibility: visible;
  }

  .nav__wrap {
    margin-top: 0px;
    display: block;
    height: 0 !important;
    padding: 0 !important;
  }
  .register_btn button {
    width: 140px;
    height: 35px;
    font-size: 13px;
  }
  .nav__two {
    position: fixed;
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    margin: 0;
    padding: 0;
  }
  .nav__two.navHide {
    opacity: 1;
    visibility: visible;
  }
  .nav__two.navShow {
    padding-top: 25px;
    opacity: 1;
    visibility: visible;
    z-index: 0;
  }
  .nav__two.navShow.actived {
    background: #fff !important;
    padding-top: 10px;
    padding-bottom: 5px;
    height: 75px;
  }
  .menu__bar_ul.main {
    display: none;
  }

  .menu__bar_ul {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 580px) {
  .bars_wrap {
    width: fit-content;
    -moz-width: fit-content;
    padding-right: 1rem;
  }
  .side_cart {
    width: 375px;
  }
  .cart_item_card {
    max-width: 340px;
  }
  .sp_Card {
    width: 300px;
    border-radius: 20px;
  }

  .icon_gp_user {
    width: 16px;
    height: 16px;
  }
  .bars {
    margin: 0;
  }

  .userName {
    font-size: 12px !important;
  }
  .layamer_logo_wrap > img {
    width: 160px;
    height: 30px;
  }

  .navbar_wrap.active {
    height: 45px;
  }
  .navbar_wrap.active .layamer_logo_wrap > img {
    width: 130px;
    height: 20px;
  }
  .navbar_wrap.active .icon_gp_bag,
  .user__icon {
    font-size: 15px;
  }
  .navbar_wrap.active .cart_number {
    height: 15px;
    width: 15px;
  }
  .navbar_wrap.active .cart_number p {
    font-size: 12px;
  }
  .cart__wrapper {
    padding-top: 60px;
  }
  .acct_dropdown {
    width: 240px;
  }
  .acct_header h1,
  .acct_history span,
  .acct_info span {
    width: 83px;
  }
  .acct_header a div {
    width: 150px;
  }

  .acct_buttons button {
    width: 180px;
    height: 35px;
    font-size: 22px;
    line-height: 0px;
  }
  .acct_history span,
  .acct_info span {
    font-size: 13px;
  }
  .user__icon,
  .order__icon,
  .info__icon {
    margin-right: 0;
    font-size: 15px;
  }
  .acct_header h1 {
    font-size: 15px;
  }
  .acct_buttons button {
    width: 135px;
    height: 35px;
    font-size: 13px;
  }
  .icon_gp_user,
  .icon_gp_bag {
    font-size: 15px;
  }

  .cart_number {
    height: 15px;
    width: 15px;
  }
  .cart_number p {
    font-size: 12px;
    margin-bottom: -2px;
  }
}

@media screen and (max-width: 520px) {
  .layamer_logo_wrap > img {
    width: 140px;
    height: 30px;
  }

  .register__form input {
    margin-bottom: 1rem;
    font-size: 12px;
  }

  .register__terms {
    margin-bottom: 1rem;
  }

  .register_btn button {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
  .bars_wrap {
    padding: 0;
  }
  .icon_gp_user {
    margin-right: 0rem;
  }
  .login__modal {
    max-height: 400px;
  }
  .input__gp input:focus ~ .login__modal {
    height: 100%;
    max-height: auto;
  }
  #forgot .login__content input {
    height: 27px;
    font-size: 14px;
  }
  .verify__account {
    padding-top: 50px !important;
  }
  .menu__bar_ul li {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 420px) {
  .layamer_logo {
    padding-left: 2rem;
  }
  .menu__bar_ul li {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .queries .qu_icon svg {
    font-size: 17px;
  }
  .side_cart {
    width: 300px;
  }
  .item_details tr td:nth-child(1),
  .item_details tr td:nth-child(2) {
    display: none;
  }
  .item_details {
    margin-left: 0rem;
  }
  .card_img img {
    width: 82px;
    height: 120px;
    border-radius: 15px;
    object-fit: fill;
  }
  .item_name h1,
  .item_name h2 {
    font-size: 16px;
    line-height: 12px;
  }
  .plus_minus {
    width: 15px;
    height: 15px;
  }
  .card_price {
    font-size: 16px !important;
  }
  .cart_item_card {
    max-width: 260px;
    padding: 1rem;
  }
  .item_name {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
  .sp_Card {
    width: 230px;
  }
  .sp_sub_card .item_name {
    width: 100px;
  }
  .sp_sub_card .item_name h1 {
    line-height: 20px;
  }
  .sp_sub_card p {
    line-height: 35px !important;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .sp_card_img img {
    cursor: pointer;
    width: 90px;
    height: 120px;
    border-radius: 16px;
    object-fit: fill;
  }
  .navbar_icon_gp {
    width: 30%;
    padding: 0;
  }
  .acct_header a div {
    margin-bottom: 0.7rem;
  }
  .menu__bar_ul li {
    font-size: 14px;
    padding-bottom: 5px;
  }
  .register__terms p {
    line-height: 14px;
  }
  .input__gp input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.678);
    border-radius: 0px;
  }
  #create_new .input__gp input {
    margin-bottom: 0rem;
  }
  .bars div {
    width: 18px;
    height: 1px;
  }
  .register_btn button {
    width: 80px;
    height: 28px;
    font-size: 10px;
    border-radius: 10px;
  }

  .btn--login {
    width: 85px;
    height: 30px;
    border-radius: 8px;
    line-height: 0;
    font-size: 12px;
  }
  .register__terms {
    margin-top: 1rem;
  }

  .acct_header {
    width: 140px;
    height: 60px;
  }
  .acct_buttons button {
    width: 130px;
    font-size: 12px;
  }
  .acct_header h1 {
    font-size: 13px;
  }
  .acct_history span,
  .acct_info span {
    font-size: 12px;
  }
  .user__icon,
  .order__icon,
  .info__icon {
    margin-right: 0;
    font-size: 12px;
  }
  .icon_gp_user,
  .icon_gp_bag {
    font-size: 15px;
  }

  .cart_number {
    height: 15px;
    width: 15px;
  }
  .cart_number p {
    font-size: 12px;
    margin-bottom: -2px;
  }

  .close__cart {
    font-size: 1.3rem;
  }
  .close_btn {
    top: 1rem;
  }
  .vsc__close {
    font-size: 1.3rem;
  }
  .bars.bar_ul {
    margin-top: -1rem;
  }

  .login__button p {
    font-size: 9px;
  }
  .register__content h1 {
    margin-bottom: 0;
  }
  .close__icon {
    top: 1rem;
    right: 1rem;
  }

  .weights .weight__kg,
  .weights p,
  .weights table {
    padding: 0 1rem 0 1rem;
  }
  .weights .table__items {
    margin-left: 1rem;
  }
  .userName {
    padding-left: 3px;
  }
  .nav-link {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 370px) {
  .userName {
    font-size: 10px !important;
  }

  .btn span {
    margin-left: 0px;
    margin-right: 0px;
    line-height: 1px;
    font-size: 13px;
  }

  .add_btn {
    width: 108px;
    height: 23px;
  }
  .btn span {
    font-size: 10px;
  }

  .btn_bottom button {
    width: 149px;
    height: 46px;
    font-size: 10px;
  }
  .cart_number {
    height: 15px;
    width: 15px;
  }
  .cart_number p {
    font-size: 12px;
  }
  .bars {
    margin-left: 1rem;
  }
  .layamer_logo_wrap > img {
    width: 119px;
    height: 20px;
  }
}

@media screen and (max-width: 270px) {
  .queries .qu_icon svg {
    display: none;
  }
  .btn--login {
    width: 70px;
    height: 25px;
  }
}

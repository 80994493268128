@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Poppins:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Marcellus&display=swap);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root{
 --div-bg-white:#ffffff;
 --div-bg-black:#000000;
 --text-color-black:#000000;
 --text-color-white:#ffffff;
 --normal-font-weight:500;
 --normal-font-size:18px;
 --normal-line-height:20px;
 --extra-line-height:40px;
 --text-font-size:20px;
 --font-poppins: 'Lato', sans-serif;; /* for all fonts */
 --font-Playfair: Playfair Diaspiay ;   /* for herosection fonts */
 --font-Montserrat: "Montserrat",sans-serif ; /* for other fonts */
 --font-opensans: 'Open Sans', sans-serif;
 --animation:all 0.3s ease-in-out;
 --animationLogo:all 0.5s ease-in-out;
 --animation-4s:all 0.4s ease-in-out;
 --navbar-height:80px;
 --home-height:100vh;
 --full-max-width:1400px;
 --screen-width:100%;
 --introduction-height: 50vh;
 --productSection-height: 95vh;
 --testimonial-height:570px;
 --joinSection-height: 55vh;
 --footer-height: 300px;
 --dark-choco: #4c2910;
 --success-color: #4BB543;
 --danger-color: 	#FF0E0E;
}
 
*{
  font-style: normal;
  font-weight: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif !important;
  font-family: var(--font-poppins) !important;
  color: #000000 !important;
  color: var(--text-color-black) !important;
  background: #ffffff;
  background: var(--div-bg-white);
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
button,
img,
.close_btns{
  cursor: pointer;
  text-transform: uppercase;
}
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.app {
  width: 100%;
  height: 100%;

}
a{
  color: #151e27 !important;
}


/* Loader */

 .loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  width: 80px;
  height: 80px;
  padding-left: 0;
}

.loader:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fa9c23;
  border-color: #fa9c23 transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #f1f1f1;
}
.toolbar-class {
  border: 1px solid #ccc;
}
.preview {
  padding: 1rem;
  margin-top: 1rem;
}
.db_product_form *{
  font-style: unset;
  font-weight: unset;
}
.preloaderBg {
  z-index: 20;
  background: #fff;
  width: 100%;
  min-height: 100vh;
  height: 105vh;
  text-align: center;
  position: relative;
  position: fixed;
  align-items: center;
  justify-content: center;
  display: flex;
}
.preloader {
  margin: auto;
  background-size: 150px;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
}
.styles_WrapperUi__2TxXl {
  background-color: transparent !important;
}
.styles_visible__3TqW5 {
  height: 105% !important;
  position: fixed !important;
  margin-top: 0rem !important;
}
.preloader img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
  padding: 1.5rem;
}
.preloader2 {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #95621a;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1s ease-in-out infinite;
          animation: spin 1s ease-in-out infinite;
  margin: auto;
  position: absolute;
  top: 0%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 420px) {
  .preloader {
    width: 65px;
    height: 65px;
  }
  .preloader img {
    width: 100px;
    height: 70px;
  }
  .preloader2 {
    width: 65px;
    height: 65px;
  }
}

/* alwanu shurooq */

/* contact form */
.form-group div {
  padding: 0;
}
#contact {
  width: 100%;
  height: 100%;
  background: #f1f1f1;
}
#contact.white {
  background: #fff;
}
.hero_text_new {
  font-size: 16px;
  line-height: 25px;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  color: rgba(0, 0, 0, 0.782);
  text-transform: uppercase;
  letter-spacing: 4px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  max-width: 840px;
  padding-bottom: 60px;
}

/* Left contact page */
.form-horizontal {
  max-width: 400px;
  font-family: "Lato";
  font-weight: 400;
}
.form-control,
textarea {
  width: 100%;
  background-color: #0000;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
  height: 35px;
  border: 1px solid #777 !important;
}

#new_edit .alt-send-button svg {
  margin-top: 10px;
}

.fa-phone,
.envolp {
  display: flex;
  font-size: 15px;
  margin-right: 1.5rem;
  margin-top: 5px;
}

.send-button {
  height: 30px;
  width: 400px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cc9b6d;
  border-color: #cc9b6d;
}

.alt-send-button {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
.alt-send-button svg {
  margin-top: 7px;
  font-size: 18px;
}
.send-text {
  display: block;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  line-height: unset !important;
}

.send-button:hover .alt-send-button {
  -webkit-transform: translate3d(0px, -29px, 0px);
          transform: translate3d(0px, -29px, 0px);
}

/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}
.list-item.tw {
  align-items: flex-end !important;

  margin-bottom: 0;
  margin-top: 5rem;
}
.list-item {
  color: #424242;
  display: flex;
  align-items: unset;
  justify-content: flex-start;
  margin-bottom: 2rem;
  font-size: 14px;
}
.contact-list hr {
  margin-bottom: 2rem;
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  margin: 0;
  font-size: 14px;
}
.place {
  line-height: 15px;
  margin-bottom: 1rem;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  -webkit-transform: scale(1.12);
          transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

.Blog_new .slick-list {
  width: 80% !important;
  margin-left: auto;
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container,
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  .form-control,
  textarea {
    margin: 0 auto;
  }

  .name,
  .email,
  textarea {
    width: 280px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }
  .social-media-list {
    left: 0;
  }
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
}

.Blog_new {
  height: 80vh;
  background: #f2dac3;
  position: relative;
  min-height: 70vh;
}
.Blog_new .slick-slider,
.Blog_new .slick-list,
.Blog_new .slick-track {
  height: 100%;
}
.fading h1 {
  font-size: 60px;
  padding-left: 4rem;
  font-weight: 600;
  margin-bottom: 5rem;
  letter-spacing: 2px;
}
.arrowBgPrev.wrap,
.arrowBgNext.wrap {
  position: absolute;
  top: 60%;
  left: 5rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 10 !important;
  color: #000;
  background: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowBgPrev,
.arrowBgNext {
  font-size: 2rem;
}
.arrowBgNext.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 60%;
  cursor: pointer;
  opacity: 0.6;
  left: 10rem;
  color: #000;
  left: 12rem;
  z-index: 10 !important;
}
.Blog_new .fading {
  width: 250px;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: opacity(0.9);
          filter: opacity(0.9);
  background: #f2dac3;

  position: absolute;
  left: 0;
  z-index: 9;
}
.Blog_new .slick-slide {
  min-height: 100px !important;
  width: 280px !important;
  height: auto !important;
  background: #ffffff;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.Blog_new .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_card {
  padding: 2rem;
}
.blog__title {
  font-size: 16px;
  margin-bottom: 1.5rem;
}
.blog__header {
  font-size: 11px;
  margin-bottom: 1rem;
}
.blog__img {
  width: 232px;
  height: 232px;
}
.blog__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0rem;
  border-radius: 10px;
}

/* //herosection SART// */
.hero_section {
  height: 100vh;
  width: 100%;
}
.hero_section img {
  z-index: -9;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* {HERO SLIDER START} */
.hero__slider {
  height: var(--home-height);
  background: transparent;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--text-color-black);
  line-height: 60px;
  text-align: center;
  font-family: var(--font-Montserrat);
}
.slick-slide .slick-active .slick-current {
  background: #fff;
}
.slick-dots {
  bottom: 20px !important;
}
.slick-prev,
.slick-next {
  z-index: 11 !important;
}
.slick-prev {
  left: 1rem !important;
}
.hero__slider .react__arrowPrev,
.hero__slider .react__arrowNext {
  color: #fff;
}

.react__arrowPrev {
  position: absolute;
  top: 50%;
  left: 10px;
  font-size: 3rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #000;
}

.react__arrowNext {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 3rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #000;
}
.react__arrowPrev:hover,
.react__arrowNext:hover {
  opacity: 1;
}
.slick-next {
  right: 1rem !important;
}
.slick-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: var(--home-height) !important;
}

#slider_text h3 {
  margin-bottom: 2rem;
}

.slider_content_one {
  overflow: hidden;
  height: 100vh;
  width: 100vw !important;
  display: block !important;
}

/* {HERO SLIDER END} */
/* {PREFERENCE MODAL START} */
.preference__wrap {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100vh;
  position: fixed;
  z-index: 16;
  display: none;
}
.preference {
  position: absolute;
  top: 50%;
  position: initial;
  width: 705px;
  height: 380px;
  flex-direction: column;
  align-items: flex-start;
  color: #1b1b1b;
  justify-content: space-evenly;
  background: linear-gradient(
    164.53deg,
    rgba(41, 41, 41, 0.102) -28.7%,
    rgba(41, 37, 37, 0.15) 152.85%
  );
  -webkit-backdrop-filter: blur(63px);
          backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);
  border-radius: 25px;
  transition: all ease-in-out 0.6s;
  -webkit-transform: translateY(-100rem);
          transform: translateY(-100rem);
}
.preference.active {
  -webkit-transform: translateY(0rem);
          transform: translateY(0rem);
}
.preference__wrap.show {
  display: flex;
}

.pref__head h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #1b1b1b;
  margin-bottom: -3rem;
  margin-left: 3rem;
}
.preference hr {
  width: 80%;
  opacity: 0.2;
  border: 1px solid #ffffff;
  margin-left: 3rem;
  margin-right: auto;
}
.pref__drop {
  margin-left: auto;
  margin-right: auto;
}
.pref__drop_wrap {
  padding-right: 3rem;
}
.pref__drop_wrap p {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
.country__dropdown,
.currency__dropdown,
.country__drop__list {
  width: 277px;
  height: 47px;
  justify-content: flex-start;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.2652) -28.7%,
    rgba(255, 255, 255, 0.39) 152.85%
  );
  -webkit-backdrop-filter: blur(63px);
          backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);

  border-radius: 10px;
}
.country__drop__list {
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.3652) -28.7%,
    rgba(255, 255, 255, 0.39) 152.85%
  );
  -webkit-backdrop-filter: blur(0px);
          backdrop-filter: blur(0px);
  -moz-backdrop-filter: blur(0px);

  -webkit-backdrop-filter: contrast(0%);

          backdrop-filter: contrast(0%);
  -moz-backdrop-filter: contrast(0%);
}
.currency__dropdown p,
.country__dropdown p,
.country__drop__list option {
  flex: 1 1;
  display: flex;
  margin-bottom: 0;
  margin-left: 1.5rem;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.down_arrow {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.country__drop__list {
  max-height: 15rem;
  height: 12.4rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
  background: #dadadad7;
}
.country__drop__list.active {
  visibility: visible;
  opacity: 1;
}
.country__drop__list::-webkit-scrollbar {
  display: none;
}
.country__drop__list option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.3rem;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0.3rem;
  padding-left: 1.5rem;
  cursor: pointer;
}
.country__drop__list option:hover {
  color: var(--text-color-white);
  background: #1b1b1b;
}
.pref__footer {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
}
.pref__footer button {
  font-weight: var(--normal-font-weight);
  font-size: var(--text-font-size);
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.04em;
  width: 270px;
  height: 45px;
  border-radius: 10px;
  margin-bottom: 0.8rem;
  font-size: 18px;
  color: var(--text-color-black);
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  border: none;
  transition: var(--animation);
}
.pref__footer button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
  color: #95621a;
}

.pref__footer p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.pref_close_btn {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
/* {PREFERENCE MODAL END} */

/* HERO SECTION END */

/* {INTRODUCTION SECTION START} */
.intro_section {
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 50vh;
  background: #8c5c37;
  color: var(--text-color-white);
  width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: -webkit-fit-content;
  height: fit-content;

  -moz-height: fit-content;
}
.intro_logo > img {
  width: 200px;
  height: 220px;
}
.intro_section > img {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}
.content_text p {
  font-weight: var(--normal-font-weight);
  font-size: var(--normal-font-size);
  line-height: 28px;
  color: var(--text-color-white);
}
/* {INTRODUCTION SECTION END} */

/* {PRODUCT SECTION START} */
.section__head {
  text-align: center;
  font-family: var(--font-Montserrat);
  font-weight: 800;
  font-size: 40px;
  color: var(--text-color-black);
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.product_section {
  height: -webkit-fit-content;
  height: fit-content;
  -moz-height: fit-content;

  background: var(--div-bg-white);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100vw;
}
.product_name_wrap {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.product_section h1 {
  letter-spacing: 0.02em;
  color: #333;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;

  align-self: flex-start;
  border-bottom: 1px solid #333;
  margin-left: 2rem;
}
.product_section > hr {
  background-color: #8c5c37;
  width: 95%;
  margin-top: 0.3rem;
}
/* PRODUCT SECTION CARD START */

.card__contianer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card__contianer .card_wrap {
  width: 250px !important;
  margin-left: 2rem;
  margin-right: 2rem;
  height: -webkit-fit-content;
  height: fit-content;
  -moz-height: fit-content;

  padding: 0;
}
.product_name_div {
  width: 100%;
}
.card__contianer .row {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin-left: 0;
  justify-content: center;
}
.card_wrap {
  position: relative;
  z-index: 0;
}
.card__img {
  width: 250px;
  height: 250px;
}
.card__img img {
  object-fit: cover;
}
.card__label {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.card__label label {
  width: 128px;
  font-size: 29px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--text-color-white);
}
.AddToCart__label {
  position: absolute;
  z-index: 1;
  width: 200px;
  top: 200px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.102) -28.7%,
    rgba(255, 255, 255, 0.15) 152.85%
  );
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  visibility: hidden;
  opacity: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddToCart__label p {
  margin: 10px;
  color: #5c3d2e;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  overflow: hidden;
  transition: var(--animation);
}
.add__cart_btn .btn {
  font-size: 14px;
}
.add__cart_btn a {
  text-decoration: none;
  color: currentColor;
  transition: var(--animation);
}
.add__cart_btn:hover a {
  letter-spacing: 3px;
  color: #95621a;
}
.add__cart_btn a span {
  position: absolute;
  display: block;
  transition: 0.5s ease;
}
.add__cart_btn a span:nth-child(1) {
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(1) {
  width: 100%;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.add__cart_btn a span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(3) {
  width: 100%;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}
.add__cart_btn a span:nth-child(2) {
  top: 0;
  left: 0;
  width: 1px;
  height: 0;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(2) {
  height: 100%;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.add__cart_btn a span:nth-child(4) {
  bottom: 0;
  right: 0;
  width: 1px;
  height: 0;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(4) {
  height: 100%;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}
.add__cart_btn a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  -webkit-transform: skewX(-40deg) translateX(0);
          transform: skewX(-40deg) translateX(0);
  transition: all 0.5s ease-out;
}
.add__cart_btn:hover a::before {
  -webkit-transform: skewX(0deg) translateX(100%);
          transform: skewX(0deg) translateX(100%);
  border-radius: 10px;
}
.product_name h4 {
  padding-top: 1rem;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
  color: #333;
}
.product_name h4:nth-child(2) {
  font-weight: 600;
}
.product_name p {
  color: #95621a;
  font-weight: 300;
  font-size: 15px;
  line-height: 50px;
  text-align: start;
  margin-bottom: 0;
  margin-top: -1rem;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.product_name p:hover {
  color: #a86d20d5;
}
.card_wrap:hover .AddToCart__label {
  opacity: 1;
  visibility: visible;
}
.card_wrap:hover .card__img_second {
  opacity: 1;
  visibility: visible;
}
.card__img_second {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation-4s);
}
.card__img_second img {
  object-fit: cover;
  cursor: default;
}

/* PRODUCT SECTION CARD END    */

/* {PRODUCT SECTION END} */

/* {TESTIMONIAL SECTION -SLIDER START} */
.testimonial {
  --bg-testimonial: #ffffff;
  background: var(--bg-testimonial);
  height: 570px;
  color: #000;
}
.testimonial .slick-slider,
.testimonial .slick-list,
.testimonial .slick-track {
  height: 570px !important;
}
.testimonial .slick-initialized .slick-slide {
  height: 570px !important;
  width: 100vw;
}
#tm__slider__wrap {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
.quotation__mark {
  width: 30px;
  height: 30px;
  margin-bottom: 2rem;
}
.quotation__mark svg {
  font-size: 2rem;
  color: #cc9b6d;
}
.quote__text {
  width: 500px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
.tm__images {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  border: 2px solid #fff;
  margin-bottom: 10px;
}
.tm__images img {
  border-radius: 100%;
  object-fit: cover;
}
#tm__slider__wrap h1 {
  font-size: 18px;
  margin-bottom: 0;
}
.testimonial .slick-dots li {
  margin-left: 20px;
}
.testimonial .slick-dots li button:before {
  color: var(--bg-testimonial);
  background: rgb(0, 0, 0);
  width: 35px;
  height: 4px;
  border-radius: 10px;
}
.testimonial .slick-dots li.slick-active button:before {
  color: rgb(255, 255, 255);
}
.testimonial .slick-dots {
  margin-left: -23px;
}
/* {TESTIMONIAL SECTION -SLIDER END} */

/* //mailing section START// */

.fade__bg {
  width: 45%;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0px);
          transform: translate(-50%, 0px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.fade__bg label {
  width: 100%;
  padding: 8rem;
}
.fade__bg_rt {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 1), transparent);
  width: 100%;
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.fade__bg.second label {
  margin-left: 11rem;
}
.fade__bg_rt label {
  margin-right: 4rem;
  width: 300px;
  text-align: end;
}
.mail_section {
  width: 100vw;
  height: 240px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 0rem;
  background: #fff;
  background-position-x: right;
}
.mail_section img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.mail_section h4 {
  font-weight: 200;

  font-size: 30px;

  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0;
}
.mail_section h1 {
  font-weight: 600;

  font-size: 30px;

  color: #ffffff;
  text-transform: uppercase;
}
.mail_header > h1 {
  font-family: var(--font-Montserrat);
  font-weight: 800;
  font-size: 40px;
  line-height: var(--extra-line-height);
  text-align: center;
  margin-bottom: 20px;
  --text-color: #1b1b1b;
}
.mail_section_content {
  flex-direction: column;
  font-weight: var(--normal-font-weight);
  font-size: var(--normal-font-size);
  line-height: 28px;
  text-align: center;
  color: #1b1b1b;
}
.mail_section_content p {
  margin-bottom: 30px;
}
.join_btn {
  background: var(--div-bg);
  border: 2px solid #1b1b1b;
  padding: 18px;
  width: 285px;
  font-size: larger;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  transition: var(--animation);
  z-index: 0;
  height: 50px;
}
.join_btn::after {
  content: "";
  background-color: var(--div-bg-black);
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: var(--animation);
  z-index: -1;
}
.join_btn:hover::after {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  -webkit-animation: fillup var(--animation);
          animation: fillup var(--animation);
}

/* mahalo extra css */
.hero_section {
  position: relative;
  width: 100vw;
}
.hero_section label {
  position: absolute;
  font-family: var(--font-Lacquer);
  font-size: 42.125px;
  line-height: 52px;
  color: #e5dac8;
  left: 8rem;
  bottom: 17rem;
  width: 477px;
}
.txt__hover {
  font-weight: 600;
}
.hero__fade {
  background: linear-gradient(
    179.09deg,
    rgba(0, 0, 0, 0.86) 0.58%,
    rgba(0, 0, 0, 0) 80.22%
  );
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes drop {
  0% {
    bottom: 0px;
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    bottom: -100px;
  }
}

@keyframes drop {
  0% {
    bottom: 0px;
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    bottom: -100px;
  }
}
.drops {
  -webkit-filter: url("#liquid");
  filter: url("#liquid");
  z-index: 1;
  opacity: 0;
  -webkit-animation: fade-in 0.1s linear 0.4s forwards;
          animation: fade-in 0.1s linear 0.4s forwards;
}
.drop {
  bottom: 0;
  margin: auto;
  background-color: #572a12;
  top: 3rem;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  position: absolute;
}
.drop.t {
  left: 3px;
  width: 8px;
  height: 18px;
  top: 6px;
}
.txt__hover {
  position: relative;
}
.txt__hover:hover {
  color: #cc9b6d;
}

.drop.f {
  width: 6px;
  height: 17px;
  left: 3rem;
  top: -2rem;
}

.drop.s {
  width: 8px;
  height: 18px;
  left: 3.6rem;
  top: 4rem;
}
.txt__hover.b:hover .drop.f.se,
.txt__hover.e:hover .drop.tr.se,
.txt__hover.e:hover .drop.s {
  -webkit-animation: drop 4.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
          animation: drop 4.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
  opacity: 1;
  visibility: visible;
}
.txt__hover.e:hover .drop.s.se {
  -webkit-animation: drop 6.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
          animation: drop 6.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
  opacity: 1;
  visibility: visible;
}
.drop.tr {
  width: 8px;
  height: 18px;
  left: 1.2rem;
  top: 4rem;
}
.txt__hover.b:hover .drop.f,
.txt__hover.e:hover .drop.tr {
  -webkit-animation: drop 2.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
          animation: drop 2.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
  opacity: 1;
  visibility: visible;
}
/* induduction */

.content_heder h1 {
  font-family: var(--font-raleway);
  font-weight: 800;
  font-size: 100.582px;
  line-height: 85px;
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  color: #8c5c37;
  text-transform: uppercase;
  margin-right: 2rem;
}
.content_text p {
  font-family: var(--font-raleway);
  font-size: 16px;
  color: #ffffff;
}
.content_text {
  display: flex;
}
.intro_content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  height: -webkit-fit-content;

  height: fit-content;
  -moz-height: fit-content;

  padding: 3rem;
}
.content_heder {
  height: -webkit-fit-content;
  height: fit-content;
  -moz-height: fit-content;

  width: 800px;
}
@-webkit-keyframes fillup {
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  0% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}
@keyframes fillup {
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  0% {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
  }
}
.join_btn:hover {
  color: var(--text-color-white);
}

@media screen and (max-width: 896px) {
  .layamer_logo_wrap {
    margin-left: 3rem;
  }
  .hero_section label {
    bottom: 0rem;
  }
  .hero_section label {
    width: 448px;
  }
  .content_heder h1 {
    font-size: 90px;
    line-height: 75px;
  }
  .content_text {
    top: 7rem;
    left: 12rem;
  }

  .arrowBgNext.wrap {
    left: 15%;
  }
}

@media screen and (max-width: 768px) {
  .Blog_new .slick-slide {
    width: 180px !important;
    border-radius: 12px;
  }
  .arrowBgNext.wrap {
    left: 11rem;
  }
  .blog__img {
    width: 160px;
    height: 160px;
  }
  .fade__bg_rt label {
    width: 182px;
    margin-right: 1rem;
  }
  .content_heder h1 {
    font-size: 65px;
    line-height: 55px;
  }

  .hero_section label {
    width: 470px;
  }
}

@media screen and (max-width: 580px) {
  .Blog_new .slick-list {
    width: 100% !important;
    margin-left: auto;
  }
  .hero_section label {
    font-size: 30.125px;
    line-height: 40px;
    width: 336px;
    left: 5rem;
  }
  .quote__text {
    width: 350px;
  }
  .card__img {
    width: 180px;
    height: 180px;
  }
  .card__img_second {
    width: 180px;
    height: 180px;
  }
  .product_name_wrap {
    width: 180px;
  }
  .AddToCart__label {
    width: 150px;
    top: 150px;
  }
  .mail_section h4 {
    font-size: 28px;
  }
  .mail_section h1 {
    font-size: 28px;
  }
  .fade__bg label {
    margin-left: 1rem;
    width: 100px;
  }
  .card__img,
  .card__img_second {
    width: 160px;
    height: 160px;
  }

  .content_text p {
    font-size: 14px;
  }
  .AddToCart__label {
    width: 130px;
    top: 130px;
    height: 30px;
  }
  .AddToCart__label p {
    font-size: 11px;
  }
  .product_name_wrap {
    width: 160px;
  }
  .card__contianer .card_wrap {
    width: 160px !important;
  }
  .footer_foot p {
    text-align: center;
    margin-left: 0rem;
  }
  .content_text {
    flex-direction: column;
    align-items: center;
  }
  .content_heder {
    position: relative;
  }
  .content_heder h1 {
    font-size: 40px;
    line-height: 35px;
    margin-bottom: 2rem;
  }
  .content_text h1 {
    position: absolute;
    top: 0;
    left: 155px;
  }
  .product_section > h1 {
    font-size: 18px;
  }
  .card__contianer {
    padding-top: 1rem;
  }
  .product_name p {
    font-size: 12px;
    line-height: 50px;
  }

  .Blog_new .fading {
    width: 200px;
  }
  .arrowBgPrev.wrap,
  .arrowBgNext.wrap {
    bottom: 10px !important;
    top: unset;
  }
  .Blog_new .fading {
    display: none;
  }
  .fading h1 {
    font-size: 21px;
  }
  .Blog_new {
    height: 80vh;
  }
  .fade__bg label {
    width: 100%;
    padding: 2rem;
  }
}
@media screen and (max-width: 420px) {
  .mail_section.two img {
    object-position: right;
  }
  .fade__bg label {
    margin-left: -8rem;
  }
  .fade__bg.two label h4 {
    color: #333 !important;
  }
  .fade__bg.two label h1 {
    color: #333 !important;
  }
  .mail_section img {
    object-fit: cover;
    object-position: left !important;
  }

  .mail_section.two img {
    object-position: right !important;
  }
  .fade__bg {
    left: auto;
  }
  .hero_section label {
    font-size: 26.125px;
    line-height: 31px;
    width: 292px;
    left: 3rem;
  }
  .hero_text_new {
    font-size: 15px;
    line-height: 22px;
  }
  .cart__wrapper {
    padding: 1.5rem;
  }
  .mail_section h4 {
    font-size: 20px;
  }
  .mail_section h1 {
    font-size: 20px;
  }

  .fade__bg_rt label {
    width: 215px;
  }
  .quote__text {
    width: 275px;
  }
  .tm__images {
    height: 100px;
    width: 100px;
  }

  .product_section > h1 {
    font-size: 14px !important;
  }
  .product_name h4 {
    font-size: 13px;
  }

  .mail_section .fade__bg label {
    margin-bottom: 0;
  }
  .card__img,
  .card__img_second {
    width: 130px;
    height: 130px;
  }
  .card__contianer .card_wrap {
    width: 130px !important;
  }
  .card__contianer .row {
    padding: 0 !important;
  }
}
@media screen and (max-width: 370px) {
  .arrowBgNext.wrap {
    left: 8rem;
  }
  .arrowBgPrev.wrap {
    left: 3rem;
  }
  .card__img,
  .card__img_second {
    width: 120px;
    height: 120px;
  }
  .card__contianer .card_wrap {
    width: 120px !important;
  }
  .footer_foot p {
    font-size: 11px;
  }

  .fade__bg label {
    margin-left: -8rem;
    width: 125px;
  }
  .fade__bg_rt label {
    width: 134px;
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .hero_section label {
    font-size: 26.125px;
    line-height: 33px;
    width: 277px;
    text-align: start;
    position: absolute;
    left: 50%;
    top: 0%;
    -webkit-transform: translate(-50%, 50%) !important;
            transform: translate(-50%, 50%) !important;
  }
  .content_heder h1 {
    font-size: 35px;
  }
  .content_text h1 {
    left: 138px;
  }
}

@media screen and (max-width: 320px) {
  .quote__text {
    width: 225px;
  }
  .mail_section h4 {
    font-size: 20px;
  }
  .mail_section h1 {
    font-size: 20px;
  }
}


.mega-drop {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  right: 0;

  width: -webkit-fit-content;

  width: fit-content;
  -moz-width: fit-content;
  opacity: 0;
  visibility: hidden;
  transition: ease-in-out all 500ms;
  z-index: 8;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}
.mega-drop .content {
  border-radius: 10px;

  background-color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: center;
  z-index: 8;
}
.content .row {
  line-height: 30px;
}
.row a {
  text-decoration: none;
}
.mega-link-wrapper {
  justify-content: center;
  display: grid;
  width: auto;
  padding: 0;
  width: 100%;
}
.mega-link-wrapper > h4 {
  text-align: center;
  text-transform: uppercase;
  color: #111;
  font-size: 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.content .row header {
  font-size: 20px;
  color: #cc9b6d;
  font-weight: 500;
  text-transform: uppercase;
}
.row-wrapper {
  height: auto;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}
.login__image {
  width: 40% !important;
}
.row .mega-link {
  margin-top: 0px;
  padding: 0;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  line-height: 30px;
}
.row .mega-link li {
  font-size: 14px;
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;
  display: block;
  background: transparent;
  color: #111;
  cursor: pointer;
  margin: 0;
}
.row .mega-link li:hover {
  color: #cc9b6d;
  opacity: 1;
}
.mega-link > li::after {
  content: "";
  display: block;
  width: 100%;
  border-bottom: solid 2px rgba(0, 0, 0, 0.8);
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}
.mega-link > li:hover::after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}
.arrow_wrapper {
  width: 20px;
  height: 20px;
  display: grid;
  justify-content: center;
  margin-top: 5px;
}
.nav-link:hover .down {
  opacity: 0;
  visibility: hidden;
}
.nav-link:hover .up {
  opacity: 1;
  visibility: visible;
}
.down {
  transition: 0.3s;
}
.up {
  margin-top: -18px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
#product_link > a {
  color: #fff !important;
  display: flex;
}
.nav-link {
  text-transform: uppercase;
  list-style: none;
  display: flex;
  color: #fff;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  background-color: transparent;
  border-radius: 5px;
  transition: all ease-in-out 350ms;
  cursor: pointer;
}
.nav-link:hover .mega-drop {
  opacity: 1;
  visibility: visible;
  top: 50px;
  transition: all 0.3s ease;
}

.queries p {
  margin-bottom: 0;
  font-size: 14px;
  margin-left: 1rem;
  color: #fff;
}
.queries .qu_icon svg {
  font-size: 21px;
  color: #fff;
}
.navBrown #product_link > a {
  color: #333 !important;
}
.navbar_wrap.active #product_link > a {
  color: #333 !important;
}
.queries {
  display: flex;
  align-items: center;
  justify-content: center;
}
.queries .qu_icon svg {
  -webkit-animation: shake 2s;
          animation: shake 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes shake {
  0.5% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  1% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  15% {
    transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
  }
  15% {
    transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
  }
  25% {
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
  }
  35% {
    transform: rotate(6deg);
    -webkit-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
  }
  50% {
    transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}
@keyframes shake {
  0.5% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  1% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  5% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  15% {
    transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
  }
  15% {
    transform: rotate(15deg);
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
  }
  25% {
    transform: rotate(20deg);
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
  }
  35% {
    transform: rotate(6deg);
    -webkit-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
  }
  50% {
    transform: rotate(-15deg);
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}
.qu_icon {
  -webkit-animation: zoom 2s ease infinite;
          animation: zoom 2s ease infinite;
}
@-webkit-keyframes zoom {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1 ,1);
    -ms-transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1 ,1);
    -ms-transform: scale(1, 1);
  }
}
@keyframes zoom {
  0% {
    transform: scale(1, 1);
    -webkit-transform: scale(1 ,1);
    -ms-transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    -ms-transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
    -webkit-transform: scale(1 ,1);
    -ms-transform: scale(1, 1);
  }
}
.bars_wrap {
  color: #fff;
}
.navBrown .menu__bar_ul li {
  color: #333;
}
.navbar_wrap.navBrown .queries .qu_icon svg,
.navbar_wrap.navBrown .queries p {
  color: #111;
}
.navbar_wrap.active.navBrown .queries .qu_icon svg,
.navbar_wrap.active.navBrown .queries p {
  color: rgb(0, 0, 0);
}
.navbar_wrap.active .queries .qu_icon svg,
.navbar_wrap.active .queries p {
  color: #000;
}
.product_drop-new {
  color: currentColor !important;
  background: transparent !important;
  color: #111;
  border: none !important;
  padding: 0;
}
.navbar_wrap.navBrown .product_drop-new {
  color: #333 !important;
}
.navbar_wrap.active.navBrown .product_drop-new {
  color: rgb(0, 0, 0) !important;
}
.dropdown-menu {
  background: #fff !important;
  height: -webkit-fit-content !important;
  height: fit-content !important;
  -moz-height: fit-content;
  min-width: 9rem !important;
}
.dropdown-toggle::after {
  margin-bottom: -2px !important;
}

.userName {
  padding-left: 1rem;
}
.menu__bar_ul a {
  text-decoration: none;
}
a:link h1 {
  text-decoration: none !important;
  color: none !important;
}

.forgot {
  color: #fff !important;
}

.forgot :hover {
  color: #000 !important;
}

.highlight {
  color: #f3ca91 !important;
}

.highlight :hover {
  color: #fff !important;
}
.noItems_cart {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.weight_btn {
  margin-bottom: 0;
}
.card_price {
  font-size: 18px !important;
}
.navbar_icon_gp {
  color: white;
  justify-content: space-evenly;
}
.user__icon,
.order__icon,
.info__icon {
  cursor: pointer;
  font-size: 20px;
  margin-right: 0.8rem;
  opacity: 0.8;
  transition: var(--animation);
}
.acct_history,
.acct_info,
.acct_header,
.acct_user_ac {
  color: #fff;
}

#brown_hover:hover {
  color: #95621a !important;
}

.nav_row.navHideT {
  z-index: 11;
}
.bars.bar_ul {
  height: 80px;
}
.current-menu {
  font-weight: 600;
  font-size: 15px;
}
.navbar_wrap.navBrown ul a li {
  color: #333;
}
.navbar_wrap.navBrown ul a li:hover {
  color: #88430e;
}
.navbar_wrap.navBrown .icon_gp_user,
.navbar_wrap.navBrown .icon_gp_bag {
  color: #5c3d2e;
}
.navbar_wrap.navBrown .icon_gp_user:hover,
.navbar_wrap.navBrown .icon_gp_bag:hover {
  color: #88430e;
}
.navbar_wrap.active.navBrown .icon_gp_user,
.navbar_wrap.active.navBrown .icon_gp_bag {
  color: #fff;
}
.navbar_wrap.active .menu__bar_ul {
  padding-top: 0;
}
.menu__bar_ul a {
  text-decoration: none;
  color: #fff !important;
  display: flex;
}
.nav__two {
  position: fixed;
  width: 100vw;
  padding-top: 55px;
  padding-bottom: 0;
  transition: all 0.3s ease-in-out;
  margin: 0;
  padding: 0;
}
.nav__wrap.navBrown .menu__bar_ul a li {
  color: #5c3d2e;
}
.navbar_wrap.active #product__link {
  color: #333 !important;
}
.navbar_wrap.navBrown #product__link {
  color: #333 !important;
}

.nav__two.navHide {
  opacity: 0;
  visibility: hidden;
}
.nav__two.navShow {
  opacity: 0;
  visibility: hidden;
  padding-top: 50px;
}
.nav__wrap {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  padding: 2rem;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.nav__wrap.actived {
  height: 40px;
  padding: 1rem;
  background: #fff;
}
.nav__wrap.actived ul a li {
  color: #fff;
}
.navbar_wrap.active .menu__bar_ul li {
  color: #333;
}
.nav__wrap.actived ul a li:hover,
.menu__bar_ul li:hover {
  color: #cc9b6d;
}
.navbar_wrap {
  height: var(--navbar-height);
  align-items: center;
  display: grid;
  position: absolute;
  position: fixed;
  z-index: 15;
  transition: all 0.3s ease-in-out;
  width: 100vw;
}
.navbar_wrap.active {
  height: 55px;
  background: #fff;
}
.navbar_wrap.active .layamer_logo_wrap > img {
  width: 190px;
  height: 40px;
}
.navbar_wrap.active .icon_gp_bag,
.user__icon {
  font-size: 22px;
}
.navbar_wrap.active .cart_number {
  height: 24px;
  width: 24px;
}
.navbar_wrap.active .cart_number p {
  font-size: 16px;
}
.nav_row {
  z-index: 12;
  width: 100vw;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.fa__bars {
  opacity: 0;
  visibility: hidden;
  color: #fff;
  font-size: 20px;
}
.bars {
  width: 50px;
  height: 100%;
  cursor: pointer;
  z-index: 9;
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: var(--animation);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}
.bars div {
  width: 20px;
  height: 1.79px;
  background: var(--div-bg-white);
  transition: var(--animation);
}
.bar1 {
  transition: var(--animation);
  -webkit-transform-origin: left top;
          transform-origin: left top;
  margin-bottom: 8px;
}
.bar3 {
  transition: var(--animation);
  -webkit-transform-origin: left bottom;
          transform-origin: left bottom;
  transform: scaleZ(0deg);
  -webkit-transform: scaleZ(0deg);
  -ms-transform: scaleZ(0deg);
}
.bars.active .bar1 {
  -webkit-transform: rotateZ(40deg) translate(-1px, -1px);
          transform: rotateZ(40deg) translate(-1px, -1px);
}
.bars.active .bar3 {
  -webkit-transform: rotateZ(-40deg) translate(-1px, 0px);
          transform: rotateZ(-40deg) translate(-1px, 0px);
}
.home_menu {
  position: relative;
  transition: var(--animation);
  opacity: 0;
  transform: translateX(0rem);
  -webkit-transform: translateX(0rem);
  -ms-transform: translateX(0rem);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.home_menu.active {
  transform: translateX(6rem);
  -webkit-transform: translateX(6rem);
  -ms-transform: translateX(6rem);
  opacity: 1;
}
.home_menu.active .drop-item {
  opacity: 1;
  visibility: visible;
}
.drop-item {
  text-transform: uppercase;
  font-weight: 300;
  font-size: var(--text-font-size);
  text-align: center;
  color: var(--text-color-black);
  text-decoration: none;
  transition: ease all 0.4s;
  opacity: 0;
  visibility: hidden;
  background: transparent;
}
.drop-item:nth-child(1) {
  margin-right: 30px;
}
.drop-item:hover {
  color: #cc9b6d !important;
}
.layamer_logo_wrap > img {
  width: 220px;
  height: 50px;
  transition: 0.5s;
}
.navbar_icon_gp {
  justify-content: space-evenly;
}
.icon_gp_user {
  width: 26px;
  height: 26px;
}
.icon_gp_user,
.icon_gp_bag {
  font-size: 17px;
  cursor: pointer;
  color: var(--text-color-white);
}
.icon_gp_bag {
  font-size: 25px;
  transition: var(--animation);
}
.icon_gp_bag:hover,
.icon_gp_user:hover {
  color: #cc9b6d;
}
.cart_header .icon_gp_bag {
  color: #111;
}
.cart_number {
  height: 20px;
  width: 20px;
  text-align: center;
  border-radius: 6px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: 3px;

  background: #11111159;
  color: #fff;
  transition: var(--animation);
}
.shopping_bag {
  transition: var(--animation);
}
.cart_number p {
  font-size: 13px;
  margin-bottom: 0px;
  cursor: pointer;
  font-weight: 500;
  transition: var(--animation);
}
.cart_header .cart_number p {
  color: #fff;
}


.menu__bar_ul {
  display: flex;
  justify-content: center;
  list-style: none;
  align-items: center;
  margin-bottom: 0;

  width: 100vw;
  cursor: pointer;
}
.menu__bar_ul li {
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  text-align: start;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}
/* navbar 2  start*/

/* //my account// */
.acct_dropdown {
  display: none;
  width: 336px;
  height: 105vh;
  left: auto;
  display: grid;
  align-items: center;
  text-align: center;
  background: linear-gradient(
    164.53deg,
    rgb(0 0 0 / 78%) -28.7%,
    rgb(0 0 0 / 68%) 152.85%
  );
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);

  -moz-backdrop-filter: blur(10px);

  border-radius: 0px;
  transform: translateX(350px);
  -webkit-transform: translateX(350px);
  -ms-transform: translateX(350px);
  overflow: hidden;
  transition: var(--animation-4s);
  -webkit-animation: sidebar-close 0.4s backwards;
          animation: sidebar-close 0.4s backwards;
  opacity: 0;
  z-index: 16;
  position: fixed;
  color: #fff;
}
.acct_dropdown.active {
  -webkit-animation: sidebar-open 0.4s forwards;
          animation: sidebar-open 0.4s forwards;
  transition: var(--animation-4s);
  opacity: 1;
}
@-webkit-keyframes sidebar-open {
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
@keyframes sidebar-open {
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
@-webkit-keyframes sidebar-close {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    transform: translateX(350px);
    -webkit-transform: translateX(350px);
    -ms-transform: translateX(350px);
  }
}
@keyframes sidebar-close {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    transform: translateX(350px);
    -webkit-transform: translateX(350px);
    -ms-transform: translateX(350px);
  }
}
.acct_header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 241px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
}
.acct_header a div {
  width: 200px;
  margin-bottom: 1.55rem;
}
.close_btn {
  position: absolute;
  top: 2rem;
  right: 1rem;
}
.user__icon {
  opacity: 1;
  transition: var(--animation);
}

.acct_header h1 {
  opacity: 1 !important;
  font-size: 22px;
}
.acct_info span:after,
.acct_history span:after,
.acct_header h1:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #888;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.acct_info span:hover:after,
.acct_history span:hover:after,
.acct_header h1:hover:after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
}

.acct_header h1,
.acct_history span,
.acct_info span {
  cursor: pointer;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  opacity: 0.8;
  transition: var(--animation);
  display: inline-block;
  position: relative;
  width: 130px;
  text-align: start;
}
.acct_buttons {
  margin-bottom: 30px;
}
.acct_buttons button {
  width: 180px;
  height: 35px;
  font-size: 16px;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
  -moz-backdrop-filter: blur(17px);
  text-transform: uppercase;
}
#log_in {
  color: #000000;
  background: darkseagreen;
}
#log_in:hover {
  opacity: 0.9;
  border: 1px solid var(--success-color);
  font-weight: 500;
}
#log_out {
  color: #fff;
}
#log_out:hover {
  opacity: 0.9;
  border: 1px solid var(--danger-color);
  font-weight: 500;
}
.acct_login {
  color: var(--text-color-black);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  transition: var(--animation);
  border: 1px solid #fff;
}
.vsc__close {
  font-size: 30px;
}
/* MY ACCOUNT END */

/* cart START*/
.side_cart.brown {
  background: linear-gradient(
    164.53deg,
    rgba(64, 64, 64, 0.258) -28.7%,
    rgba(41, 37, 37, 0) 152.85%
  );
}
.side_cart {
  background: #ffffff40;
  z-index: 16;
  font-weight: var(--normal-font-weight);
  font-size: var(--text-font-size);
  line-height: var(--normal-line-height);
  width: 460px;
  color: #111;
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
  -moz-backdrop-filter: blur(17px);

  border-radius: 0px;
  transform: translateX(650px);
  -webkit-transform: translateX(650px);
  -ms-transform: translateX(650px);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  -webkit-animation: sideCart-close 0.5s backwards;
          animation: sideCart-close 0.5s backwards;
  opacity: 0;
  left: auto;
  height: 105vh;
  padding-bottom: 6rem;
  position: fixed;
}
.side_cart::-webkit-scrollbar {
  display: none;
}
.side_cart.active {
  -webkit-animation: sideCart-open 0.5s forwards;
          animation: sideCart-open 0.5s forwards;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}
@-webkit-keyframes sideCart-open {
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
@keyframes sideCart-open {
  100% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
}
@-webkit-keyframes sideCart-close {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    transform: translateX(650px);
    -webkit-transform: translateX(650px);
    -ms-transform: translateX(650px);
  }
}
@keyframes sideCart-close {
  0% {
    transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
  }
  100% {
    transform: translateX(650px);
    -webkit-transform: translateX(650px);
    -ms-transform: translateX(650px);
  }
}

.cart_header {
  border-bottom: 1px solid #111;
  width: 229px;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.cart_header span {
  height: 63px;
  text-transform: uppercase;
  line-height: 60px;
}
.cart_number.my_bag {
  padding-top: 4px;
}
/* {cart default start} */
.cart__default {
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  height: 80%;
  align-items: center;
  justify-content: center;
}
.cart__default h1 {
  font-size: 20px;
  text-align: center;
  margin-top: 1rem;
  text-transform: uppercase;
}
.ri__cart_icon {
  font-size: 80px;
  width: 100px;
}
/* {cart default end} */

/* card ONE,TWO START  */
.close__icon__hover:hover {
  color: rgb(112, 35, 35);
  cursor: pointer;
}
.close__cart {
  font-size: 2rem;
}
.close_btns {
  height: 23px;
  width: 23px;
  top: -1.1rem;
  left: 90%;
  border: 1px solid rgb(154 151 151);
  border-radius: 100%;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart_item_card {
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: auto;
  height: auto;
  padding: 2rem;
  background: #ffffff85;
  color: #111;
  border: 2.05833px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(34.9917px);
          backdrop-filter: blur(34.9917px);
  -moz-backdrop-filter: blur(34.9917px);
  border-radius: 20px;
  justify-content: space-between;
}

.cart_item_card .card_content .table_wrap {
  margin-top: 0;
}
.card_content span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.card_content p {
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 550;
}
.item_name {
  width: -webkit-max-content;
  width: max-content;
  height: auto;
  margin-bottom: 2rem;
}
.item_name h1,
.item_name h2 {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
  font-family: var(--font-Montserrat);
}
.out_ofs {
  font-size: 10px;
}
.item_name p {
  font-size: 15.1899px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: var(--font-Montserrat);
}
.table_wrap {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.table__item {
  height: 120px;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
.table__item .length {
  margin-top: 5px;
  margin-bottom: 13px;
}
.item_details {
  margin-left: -1rem;
  font-size: 14px;
  line-height: 18px;
}
.item_details tr {
  line-height: 1.5rem;
}
.item_details tr td {
  padding-left: 0.8rem;
  padding-right: 0.5rem;
}
.btn-group {
  margin-left: -15px;
}
.btn-group span {
  border: 0.6328px solid #000;
  box-sizing: border-box;
  border-radius: 0;
  width: 20.63px;
  height: 18.99px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}
.btn span {
  margin-left: 8px;
  margin-right: 8px;
  line-height: 1px;
  border: none !important;
  font-size: 14px;
}
.plus_minus {
  background: #555;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transition: all ease 0.3s;
}
.plus_minus:hover {
  background: #333;
}
.card_img img {
  width: 110px;
  height: 150px;
  border-radius: 16px;
  object-fit: fill;
}
.line_break {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
/* CARD ONE,TWO END */

/* card THREE START*/
.sp_Card {
  height: auto;
  width: 350px;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  border: 2.05833px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(34.9917px);
          backdrop-filter: blur(34.9917px);
  -moz-backdrop-filter: blur(34.9917px);

  border-radius: 30.875px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 3rem;
  max-height: -webkit-fit-content;
  max-height: fit-content;
  -moz-max-height: fit-content;
}
.sp_Card > h1 {
  font-size: 16px;
  line-height: 30px;
  margin: 2rem;
  font-family: var(--font-Montserrat);
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;
  border-bottom: 1px solid #cfcfcf;
}
.sp_sub_card p {
  font-size: 16px !important;
  line-height: 30px !important;
}
.sp_sub_card {
  margin-bottom: 2rem;
  justify-content: space-around;
}
.sp_card_img img {
  cursor: pointer;
  width: 120px;
  height: 160px;
  border-radius: 16px;
}
.add_btn {
  width: 156px;
  height: 30px;
  margin-top: 1rem;
  padding: 0;
  font-size: 11.3924px;
  line-height: 18px;
  color: var(--text-color-black);
  overflow: hidden;
  position: relative;
  border: 0.632911px solid #111;
  display: flex !important;
}
.add_btn:after {
  background-color: var(--div-bg-white);
  content: "";
  height: 22px;
  opacity: 0.2;
  position: absolute;
  transform: rotate(120deg);
  -webkit-transform:  rotate(120deg);
  -ms-transform:  rotate(120deg);
  transition: all 1.8s cubic-bezier(0.19, 1, 0.22, 1);
  width: 70px;
  z-index: -10;
  top: -7px;
  bottom: 0;
  left: -83px;
  right: 0;
}
.add_btn span {
  z-index: 20;
  font-family: var(--font-Montserrat);
  font-size: 13px;
}
.add_btn:hover::after {
  left: 150px;
}
.add_btn:hover {
  border: 0.632911px solid #8c5c37;
}
/* CARD THREE END */

/*CART buttons START*/
.btn_bottom {
  flex-direction: column;
}
.btn_bottom button a {
  text-decoration: none;
  color: currentColor;
}
.btn_bottom button {
  width: 200px;
  height: 55px;
  border-radius: 15px;
  line-height: var(--extra-line-height);
  margin-bottom: 2rem;
  font-family: var(--font-Montserrat);
  font-weight: var(--normal-font-weight);
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
}
.btn_explore {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);

  transition: var(--animation);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_explore {
  transition: var(--animation);
}
.btn_explore:hover {
  border: 1px solid #fff;
}
.acct_login:hover,
.btn_explore:hover {
  letter-spacing: 3px;
}
.register_btn button::before,
.acct_login::before,
.btn_explore::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  -webkit-transform: skewX(-40deg) translateX(0);
          transform: skewX(-40deg) translateX(0);
  transition: all 0.5s ease-out;
}
.register_btn button:hover::before,
.acct_login:hover::before,
.btn_explore:hover::before {
  -webkit-transform: skewX(0deg) translateX(100%);
          transform: skewX(0deg) translateX(100%);
  border-radius: 15px;
}

.btn_checkout {
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
  -moz-backdrop-filter: blur(17px);
  transition: all ease-in-out 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  background: #184d47;
  color: #c6ebc9;
}
.btn_checkout:hover {
  color: #c6ebc9;
  letter-spacing: 1px;
  border: 2px solid #c6ebc9;
}
/* CART BUTTONS END */
/* CART END */

/* {LOGIN SECTION START } */
.login__wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100vh;
  position: fixed;
  z-index: 10;
  display: flex;
  transform: translateY(0rem);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
  background: #f0f0f0f0;
}
.login__wrapper.show {
  display: flex;
  transform: translateY(0rem);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
}
.login__modal {
  position: absolute;

  top: 25%;
  position: initial;
  width: 70%;
  height: 70%;
  max-height: 400px;
  max-width: 680px;
  background: #ffff;
  box-shadow: rgba(177, 137, 137, 0.1) 0px 4px 12px;

  -webkit-backdrop-filter: blur(10px);

          backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  border-radius: 25px;
  color: #000000;
  position: absolute;
  z-index: 15;
  justify-content: space-evenly;
  transform: translateY(0rem);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
  transition: all ease-in-out 0.6s;
  display: flex;
  align-items: center;
}
.login__modal .login__image img {
  height: 100%;
}
.login__modal.active {
  transform: translateY(0);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
}
.close__icon {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.close__icon:hover {
  color: red;
}
.register__terms {
  margin-bottom: 2rem;
}
.register__img,
.login__image {
  width: 30%;
  height: 80%;
  border-radius: 15px;
}
.login__image img {
  object-fit: cover;
  border-radius: 15px;
}
.login__content {
  font-weight: 600;
  font-size: 40px;
  line-height: 30px;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}
.login__content h1 {
  margin-bottom: 2rem;
  font-size: 18px;
}

.input__gp {
  flex-direction: column;
  width: 100%;
}
.input__gp input::-webkit-input-placeholder {
  color: #333;
}
.input__gp input:-ms-input-placeholder {
  color: #333;
}
.input__gp input::placeholder {
  color: #333;
}
.input__gp input:focus {
  background: transparent;
  color: #333;
}
.input__gp input {
  width: 100%;
  border: none;
  background: transparent;
  border-bottom: 2px solid #666;
  padding: 5px 10px;
  outline: none;
  height: 30px;
  font-size: 14px;
  margin-bottom: 2rem;
  color: rgb(0, 0, 0);
  border-radius: 0;
}

.input__gp input {
  margin-bottom: 1.5rem;
}
.login__button {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 3rem;
}
.btn--login {
  width: 149px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 10%);
  -webkit-backdrop-filter: blur(63px);
  backdrop-filter: blur(63px);
  border-radius: 7px;
  font-size: 14px;
  text-align: center;
  height: 30px;
  transition: all 0.6s;
  position: relative;
  cursor: pointer;
  border: 2px solid #184d47;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #184d47;
  color: #ffff;
}
.btn--login::before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background: #185f57;
  border-radius: 5px;
  z-index: -2;
}
.btn--login:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}
.login__button p {
  font-weight: 300;
  font-size: 10px;
  line-height: 30px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  cursor: pointer;
  transition: var(--animation);
  color: #333;
}
.login__button p:hover {
  color: #c4c4c4;
}
.create__ac {
  width: 100%;
  flex-direction: column;
  margin-top: 3rem;
}
.create__ac p {
  font-weight: 300;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 0.02em;
  margin-bottom: 10px;
}
.create__ac button {
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  width: 100%;
  height: 30px;
  border-radius: 7px;
  color: rgb(0, 0, 0);
  display: inline-block;
  text-align: center;
  position: relative;
  transition: 0.5s all ease;
  background: transparent;
  border: 1px solid #c4c4c4;
}
.create__ac button:hover {
  letter-spacing: 1.3px;
}
/* {LOGIN SECTION END } */

/* {CREATE ACCOUNT START} */
.register__modal {
  --gray-color: #fff;
  color: var(--gray-color);
  transition: all ease 0.4s;
}
.register__modal.active {
  transform: translateY(0);
  -webkit-transform:translateY(0rem);
  -ms-transform:translateY(0rem);
}
.register_btn p,
.register__terms p {
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: var(--gray-color);
}
.register__terms p a {
  color: var(--gray-color);
}
.register__content h1 {
  margin-right: 4rem;
}
.register_btn button {
  border: none;
  width: 150px;
  height: 35px;
  font-size: 14px;
  border-radius: 14px;
  color: var(--gray-color) !important;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  transition: all 0.3s ease;
  border: 1px solid transparent;
}
.register_btn button:hover {
  letter-spacing: 2px;
  border: 2px solid #c4c4c4;
}
.register__form input {
  margin-bottom: 1.3rem;
  background: transparent;
}
.register__form input::-webkit-input-placeholder {
  color: var(--gray-color);
}
.register__form input:-ms-input-placeholder {
  color: var(--gray-color);
}
.register__form input::placeholder {
  color: var(--gray-color);
}
.register__form {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
/* {CREATE ACCOUNT END} */

@media screen and (min-width: 1080px) {
  .personal__info,
  .shipping__address {
    max-width: 350px;
  }
}

@media screen and (max-width: 1080px) {
  .icon_gp_user {
    margin-right: 1.4rem;
  }
  .cart_number {
    height: 25px;
    width: 25px;
  }
  .cart_number p {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .add_btn {
    width: 140px;
  }

  .btn--login {
    width: 100px;
  }
  .register_btn p,
  .register__terms p {
    font-size: 10px;
  }
  .register_btn button {
    width: 100px;
    height: 30px;
    font-size: 11px;
  }
}
@media screen and (max-width: 896px) {
  .add_btn {
    width: 140px;
  }
}
@media screen and (max-width: 768px) {
  .login__image {
    display: none;
  }
  .login__content {
    width: 80%;
  }

  .layamer_logo_wrap > img {
    width: 180px;
    height: 40px;
  }
  .acct_history span,
  .acct_info span {
    font-size: 15px;
  }
  .user__icon,
  .order__icon,
  .info__icon {
    margin-right: 0;
    font-size: 18px;
  }
  .acct_header h1 {
    font-size: 18px;
  }
  .acct_buttons button {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
  .menu__bar_ul.active a li:hover {
    color: #88430e !important;
  }
  .navbar_wrap.navBrown .bars div {
    background-color: #88430e;
  }
  .bars.active .bar1,
  .bars.active .bar3 {
    background: #fff !important;
  }

  .navbar_wrap.active.navBrown .bars div {
    background-color: #fff;
  }
  .navbar_wrap.navBrown .menu__bar_ul a li {
    color: #333;
  }
  .nav__wrap.actived {
    background: transparent;
  }
  .nav__two.navHide,
  .bars,
  .fa__bars,
  .nav__wrap .menu__bar_ul.active {
    opacity: 1;
    visibility: visible;
  }

  .nav__wrap {
    margin-top: 0px;
    display: block;
    height: 0 !important;
    padding: 0 !important;
  }
  .register_btn button {
    width: 140px;
    height: 35px;
    font-size: 13px;
  }
  .nav__two {
    position: fixed;
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    margin: 0;
    padding: 0;
  }
  .nav__two.navHide {
    opacity: 1;
    visibility: visible;
  }
  .nav__two.navShow {
    padding-top: 25px;
    opacity: 1;
    visibility: visible;
    z-index: 0;
  }
  .nav__two.navShow.actived {
    background: #fff !important;
    padding-top: 10px;
    padding-bottom: 5px;
    height: 75px;
  }
  .menu__bar_ul.main {
    display: none;
  }

  .menu__bar_ul {
    padding-top: 2rem;
  }
}

@media screen and (max-width: 580px) {
  .bars_wrap {
    width: -webkit-fit-content;
    width: fit-content;
    -moz-width: fit-content;
    padding-right: 1rem;
  }
  .side_cart {
    width: 375px;
  }
  .cart_item_card {
    max-width: 340px;
  }
  .sp_Card {
    width: 300px;
    border-radius: 20px;
  }

  .icon_gp_user {
    width: 16px;
    height: 16px;
  }
  .bars {
    margin: 0;
  }

  .userName {
    font-size: 12px !important;
  }
  .layamer_logo_wrap > img {
    width: 160px;
    height: 30px;
  }

  .navbar_wrap.active {
    height: 45px;
  }
  .navbar_wrap.active .layamer_logo_wrap > img {
    width: 130px;
    height: 20px;
  }
  .navbar_wrap.active .icon_gp_bag,
  .user__icon {
    font-size: 15px;
  }
  .navbar_wrap.active .cart_number {
    height: 15px;
    width: 15px;
  }
  .navbar_wrap.active .cart_number p {
    font-size: 12px;
  }
  .cart__wrapper {
    padding-top: 60px;
  }
  .acct_dropdown {
    width: 240px;
  }
  .acct_header h1,
  .acct_history span,
  .acct_info span {
    width: 83px;
  }
  .acct_header a div {
    width: 150px;
  }

  .acct_buttons button {
    width: 180px;
    height: 35px;
    font-size: 22px;
    line-height: 0px;
  }
  .acct_history span,
  .acct_info span {
    font-size: 13px;
  }
  .user__icon,
  .order__icon,
  .info__icon {
    margin-right: 0;
    font-size: 15px;
  }
  .acct_header h1 {
    font-size: 15px;
  }
  .acct_buttons button {
    width: 135px;
    height: 35px;
    font-size: 13px;
  }
  .icon_gp_user,
  .icon_gp_bag {
    font-size: 15px;
  }

  .cart_number {
    height: 15px;
    width: 15px;
  }
  .cart_number p {
    font-size: 12px;
    margin-bottom: -2px;
  }
}

@media screen and (max-width: 520px) {
  .layamer_logo_wrap > img {
    width: 140px;
    height: 30px;
  }

  .register__form input {
    margin-bottom: 1rem;
    font-size: 12px;
  }

  .register__terms {
    margin-bottom: 1rem;
  }

  .register_btn button {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
  .bars_wrap {
    padding: 0;
  }
  .icon_gp_user {
    margin-right: 0rem;
  }
  .login__modal {
    max-height: 400px;
  }
  .input__gp input:focus ~ .login__modal {
    height: 100%;
    max-height: auto;
  }
  #forgot .login__content input {
    height: 27px;
    font-size: 14px;
  }
  .verify__account {
    padding-top: 50px !important;
  }
  .menu__bar_ul li {
    margin-left: 0rem;
  }
}
@media screen and (max-width: 420px) {
  .layamer_logo {
    padding-left: 2rem;
  }
  .menu__bar_ul li {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .queries .qu_icon svg {
    font-size: 17px;
  }
  .side_cart {
    width: 300px;
  }
  .item_details tr td:nth-child(1),
  .item_details tr td:nth-child(2) {
    display: none;
  }
  .item_details {
    margin-left: 0rem;
  }
  .card_img img {
    width: 82px;
    height: 120px;
    border-radius: 15px;
    object-fit: fill;
  }
  .item_name h1,
  .item_name h2 {
    font-size: 16px;
    line-height: 12px;
  }
  .plus_minus {
    width: 15px;
    height: 15px;
  }
  .card_price {
    font-size: 16px !important;
  }
  .cart_item_card {
    max-width: 260px;
    padding: 1rem;
  }
  .item_name {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
  .sp_Card {
    width: 230px;
  }
  .sp_sub_card .item_name {
    width: 100px;
  }
  .sp_sub_card .item_name h1 {
    line-height: 20px;
  }
  .sp_sub_card p {
    line-height: 35px !important;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .sp_card_img img {
    cursor: pointer;
    width: 90px;
    height: 120px;
    border-radius: 16px;
    object-fit: fill;
  }
  .navbar_icon_gp {
    width: 30%;
    padding: 0;
  }
  .acct_header a div {
    margin-bottom: 0.7rem;
  }
  .menu__bar_ul li {
    font-size: 14px;
    padding-bottom: 5px;
  }
  .register__terms p {
    line-height: 14px;
  }
  .input__gp input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.678);
    border-radius: 0px;
  }
  #create_new .input__gp input {
    margin-bottom: 0rem;
  }
  .bars div {
    width: 18px;
    height: 1px;
  }
  .register_btn button {
    width: 80px;
    height: 28px;
    font-size: 10px;
    border-radius: 10px;
  }

  .btn--login {
    width: 85px;
    height: 30px;
    border-radius: 8px;
    line-height: 0;
    font-size: 12px;
  }
  .register__terms {
    margin-top: 1rem;
  }

  .acct_header {
    width: 140px;
    height: 60px;
  }
  .acct_buttons button {
    width: 130px;
    font-size: 12px;
  }
  .acct_header h1 {
    font-size: 13px;
  }
  .acct_history span,
  .acct_info span {
    font-size: 12px;
  }
  .user__icon,
  .order__icon,
  .info__icon {
    margin-right: 0;
    font-size: 12px;
  }
  .icon_gp_user,
  .icon_gp_bag {
    font-size: 15px;
  }

  .cart_number {
    height: 15px;
    width: 15px;
  }
  .cart_number p {
    font-size: 12px;
    margin-bottom: -2px;
  }

  .close__cart {
    font-size: 1.3rem;
  }
  .close_btn {
    top: 1rem;
  }
  .vsc__close {
    font-size: 1.3rem;
  }
  .bars.bar_ul {
    margin-top: -1rem;
  }

  .login__button p {
    font-size: 9px;
  }
  .register__content h1 {
    margin-bottom: 0;
  }
  .close__icon {
    top: 1rem;
    right: 1rem;
  }

  .weights .weight__kg,
  .weights p,
  .weights table {
    padding: 0 1rem 0 1rem;
  }
  .weights .table__items {
    margin-left: 1rem;
  }
  .userName {
    padding-left: 3px;
  }
  .nav-link {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 370px) {
  .userName {
    font-size: 10px !important;
  }

  .btn span {
    margin-left: 0px;
    margin-right: 0px;
    line-height: 1px;
    font-size: 13px;
  }

  .add_btn {
    width: 108px;
    height: 23px;
  }
  .btn span {
    font-size: 10px;
  }

  .btn_bottom button {
    width: 149px;
    height: 46px;
    font-size: 10px;
  }
  .cart_number {
    height: 15px;
    width: 15px;
  }
  .cart_number p {
    font-size: 12px;
  }
  .bars {
    margin-left: 1rem;
  }
  .layamer_logo_wrap > img {
    width: 119px;
    height: 20px;
  }
}

@media screen and (max-width: 270px) {
  .queries .qu_icon svg {
    display: none;
  }
  .btn--login {
    width: 70px;
    height: 25px;
  }
}

.footer_new > p {
  margin-bottom: 0rem;
}
.footer_new p {
  font-size: 13px;
  margin-bottom: 1rem;
  color: #464545cc;
  line-height: 25px;
}
.footer_content,
.footer_foot {
  background: #f7f7f7;
}
.footer {
  display: flex;
  justify-content: space-around;
  padding-left: 45px;
  height: 400px;
  color: var(--text-color-black);
  background: #f7f7f7;
  padding: 0;
  margin: 0;
  width: 100vw;
}
.footer_content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 2rem;
  padding-top: 3rem;
}
.footer_content h1 {
  font-weight: var(--normal-font-weight);
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #464545cc;
  text-transform: uppercase;
}
.footer_text_wrap {
  height: 100%;
  display: flex;
  width: 500px;
}
.footer_text {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  line-height: 30px;
  font-size: 13px;
  letter-spacing: 0.05em;
  padding: 0;
}
.footer_foot h1:hover {
  color: #95621a;
}
.footer_text span {
  font-size: 12px;
  cursor: pointer;
  opacity: 0.8;
}
.footer_text span:hover {
  color: #5c3d2e;
}
.footer_text.icon {
  margin-top: -4rem;
  padding-left: 0;
  padding-left: initial;
  width: 220px;
}
.footer_icons {
  margin-bottom: 3rem;
}
.footer_text.icon p {
  line-height: 20px !important;
}
.react__icon {
  height: 35px;
  width: 35px;
  background-color: #e6e6e6;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  cursor: pointer;
  color: Black;
}
#In__icon,
#fb__icon,
#Tw__icon,
#Ln__icon {
  transition: all 0.3s ease;
}
#fb__icon:hover {
  color: #4267b2;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}
#In__icon:hover {
  color: #cd486b;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}
#Tw__icon:hover {
  color: #1da1f2;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}
#Ln__icon:hover {
  color: #075e54;
  transform: translateY(-0.2em);
  -webkit-transform: translateY(-0.2em);
  -ms-transform: translateY(-0.2em);
}

.footer_foot p {
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  letter-spacing: 0.05em;
  opacity: 0.9;
  text-align: start;
  margin-left: 4.5rem;
}
.footer_foot p a:nth-child(2) {
  text-decoration: none;
  opacity: 1 !important;
  color: #1781bb !important;
}
.footer_foot p a:nth-child(1) {
  text-decoration: none;
  color: #5c3d2e !important;
}
.footer_foot p a:hover {
  color: #9e7a46;
}

@media screen and (max-width: 768px) {
  .footer_text.tw {
    width: 70px;
    margin-left: 3rem;
  }
  .footer_text_wrap {
    width: 100%;
    justify-content: space-evenly;
  }
  .footer_text.icon {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .footer_foot {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer_text.tw {
    width: -webkit-fit-content;
    width: fit-content;
    -moz-width: fit-content;
    margin-left: 0rem;
  }
  .footer_text {
    width: -webkit-fit-content;
    width: fit-content;
  }
  .footer_foot p {
    margin: 0;
  }
  .footer {
    height: 300px;
  }
  .footer_foot p {
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 470px) {
  .footer_text_wrap {
    justify-content: space-between;
  }
}

@media screen and (max-width: 400px) {
  .footer_text_wrap {
    flex-direction: column;
    align-items: center;
  }
  .footer_text {
    max-width: 100% !important;
    width: 100%;
    align-items: center;
  }
  .footer_text.main {
    margin-bottom: 2rem;
  }
  .footer_new p {
    text-align: center;
  }
}

@media screen and (max-width: 360px) {
  .footer_text a,
  .footer_content h1 {
    font-size: 11px;
    line-height: 20px;
  }
  .react__icon {
    height: 20px;
    width: 20px;
  }
  #In__icon,
  #fb__icon,
  #Tw__icon,
  #Ln__icon {
    width: 10px;
    height: 10px;
  }
  .footer_foot p {
    margin-bottom: 2rem;
    font-size: 10px;
    align-self: center;
  }
  .footer_text_wrap {
    justify-content: space-between;
  }
}

/* blog */
.blog_right::-webkit-scrollbar {
  width: 7px;
}
#review .form-control,
textarea {
  background-color: #fff;
  color: rgb(0 0 0);
  border: none; 
}
#new_edit .react-responsive-modal-modal {
  width: 100%;
  max-width: 550px !important;
}
#new_edit img {
  position: absolute;
  left: -42px;
  height: 88%;
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;
  opacity: 0.7;
  cursor: default;
}
#new_edit h1{
  padding-bottom: 0rem;
  letter-spacing: 1px;
  text-transform: unset;
}
#new_edit .edit_p {
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;
  margin: 0;
  padding-top: 5px;
  margin-left: auto;
    margin-right: auto;
}
#new_edit section {

  margin-left: 16rem !important;
}
#new_edit .send-text{
  font-size: 12px;
  margin-top: 0px;
  text-transform: lowercase;
  font-weight: 400;
}
.new_cancel{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.cancel_new_btn{
  width: 90px;
  font-size: 12px;
  font-weight: 400;

  height: 30px;
  padding: 0;
  background: transparent;
  color: #000;
  text-transform: lowercase;
  border: 1px solid #333;
}
#new_edit h1 {
  padding-bottom: 0rem;
  font-size: 18px;
}
#new_edit .contact-wrapper .form-horizontal {
  max-width: 250px !important;
  
}
#new_edit .new_edit_input {
  width: 100%;
  min-width: 230px;
  -moz-min-width: 230px;
}

.new_edit_input::-webkit-outer-spin-button,
.new_edit_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#new_edit .send-button {
  width: 90px!important;
  height: 30px!important;
  margin-right: 2rem;
} 
#new_edit {
  display: flex;
}
.blog_right::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 20px;
}

.blog_right::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}

body::-webkit-scrollbar {
  display: none;
}

.blog_text {
  width: 100%;
  font-size: 14px;
  text-align: justify;
}
.blog-new {
  padding-top: 150px;
  min-height: 100vh;
  padding-bottom: 4rem;
  align-items: stretch !important;
}
.blog_left {
  width: 60%;
  padding: 6rem;
  padding-top: 3rem;
  height: auto;
  min-height: 100vh;
}

.sc-bdVaJa.ghaOHp {
  position: fixed;
  bottom: 14rem;
}
.blog_right {
  width: 35%;
  padding: 1rem 2rem 2rem 2rem;
  height: -webkit-fit-content;
  height: fit-content;
  -moz-height: fit-content;
  position: sticky;
  top: 100px;
  overflow-y: scroll;
  height: 75vh;
  border-radius: 30px;
  overflow-x: hidden;
}

.blog_right .blog__img {
  margin: 0 2rem 0 2rem;
}
.blog_right .blog_card {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 13px;
  margin-bottom: 2rem;
}
.blog_content p {
  margin-bottom: 2rem;
  opacity: 0.7;
}
.blog_content h3 {
  max-width: 70%;
  margin-bottom: 6rem;
}
.blog_single_img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.blog_single_img {
  width: 100%;
  height: 500px;
  margin: 0 0 6rem 0;
}
.blog_right .blog_card {
  width: 100%;
  height: 160px;
  justify-content: space-between;
  min-width: -webkit-fit-content;
  min-width: fit-content;
  -moz-min-width: fit-content;
}
.blog_right .blog_card_data {
  width: 90%;
}
.blog_right .blog__title {
  width: 100%;
}
.blog_right .blog__img {
  width: 150px;
  height: 110px;

  margin-left: 0;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none;
}
#review .login__content h1 {
  font-size: 18px;
  margin-bottom: 0rem;
}
#review .login__content p {
  font-size: 14px;
}
textarea:focus,
input:focus {
  outline: none !important;
}
.verify__account > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
.verify__modal .login__content h1 {
  font-size: 18px;
}

#verify_ac p {
  font-size: 14px;
}
#verifyCheck {
  width: 80%;
  padding: 2rem;
}
#verifyCheck h4 {
  font-size: 18px;
  line-height: 30px;
}
#new_modal {
  max-height: 360px;
}
#new_modal .login__image {
  height: 60%;
}
.maill svg {
  font-size: 100px;
}
.maill {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  -webkit-animation: shake 3s infinite;
          animation: shake 3s infinite;
}
@-webkit-keyframes shake {
  0.5% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  1% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    -ms-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
  }
  .5% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
  }
  25% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
  }
  .50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);

  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
}
@keyframes shake {
  0.5% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  1% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
    -ms-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
  }
  .5% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
  }
  25% {
    -webkit-transform: rotate(12deg);
            transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
  }
  .50% {
    -webkit-transform: rotate(-15deg);
            transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);

  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
}
#ver_modal {
  max-width: 500px;
  max-height: 400px;
}
.forgot button {
  background: #111 !important;
  -webkit-backdrop-filter: blur(63px);
          backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);
}
#forgot .login__content {
  width: 100%;
}
#forgot {
  width: 260px;
}
#modal-very ,
#modal_rest{
  max-height: 380px;
  color: #333;
  background: #ffff;
  padding: 5rem;

  max-width: 500px;

}
#modal-very .login__image {
  height: 60%;
}
/* resetPass */
.form-control:focus {
  outline: none !important;
  box-shadow: none;
}

textarea:focus,
input:focus {
  outline: none !important;
}
.verify__account > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
.verify__modal .login__content p {
  font-size: 14px;
  line-height: 22px;
}
#modal_rest .create__ac button,
#modal-very .create__ac button {
  background-color: #28a745 !important;
}
.reset button {
  background: #111 !important;
  -webkit-backdrop-filter: blur(63px);
          backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);
}

/* revieew */
#review .login__content {
  width: 270px;
}
.reviewImage {
  width: 55%;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none;
}
textarea:focus,
input:focus {
  outline: none !important;
}
.verify__account > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
.verify__modal {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -ms-transform:  translateY(0);
    -moz-transform:  translateY(0);
  background: linear-gradient(
    164.53deg,
    rgba(64, 64, 64, 0.258) -28.7%,
    rgba(41, 37, 37, 0) 152.85%
  );
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
    -moz-backdrop-filter: blur(17px);
  border-radius: 25px;
  color: #ffffff;
  z-index: 15;
  width: 70%;
  height: 70%;
  max-height: 500px;
  justify-content: space-evenly;
  transition: all ease-in-out 0.6s;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
  margin-top: 2rem;
  max-width: 700px;
}
#verfyAC button,
.review button {
  background-color: #28a745 !important;
}
#review textarea {
  margin-bottom: 0 !important;
}
.review {
  margin-top: 2rem;
}
/* verify */
.verify__account {
  padding-top: 120px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1f1
}
.verify__account > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}
#modal_rest .login__image {
  height: 65%;
}
#modal_rest .login__content {
  width: 100%;
}
#modal_rest {
  max-height: 370px;
}
#modal_rest form {
  width: 270px;
}
#rest_modal_n .rest_modal_n {
  width: 100%;
}
.verify button {
  background: #111 !important;
  -webkit-backdrop-filter: blur(63px);
          backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);
}
#verify_ac {
  width: 270px;
}
#verify_ac .login__content {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .blog_left {
    padding: 2rem;
    padding-top: 2rem;
  }
  .blog_right {
    width: 40%;
  }
  .blog_left {
    width: 50%;
    padding: 2rem;
    padding-top: 2rem;
  }

}
@media screen and (max-width: 820px) {
  .blog-new {
    flex-direction: column;
  }
  .blog_left,
  .blog_right {
    width: 100%;
  }
  .blog_left {
    margin-bottom: 3rem;
  }
  .blog_right::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .navbar_icon_gp {
    display: none;
}
  #new_edit section {
    margin-left: 6rem !important;
}
#new_edit img {
  position: absolute;
  left: -272px;
  height: 90%;
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;

}
  #modal-very {
    max-height: 320px;
  max-width: 80%;
  padding: 0;

  }

  #modal-very .login__content p {
    font-size: 12px;
  }
  #modal-very .create__ac {
    margin-top: 2rem;
  }
  #verify_ac {
    width: 60%;
  }

  #verify_ac p {
    font-size: 11px;
    line-height: 15px;
  }
  #verify_ac .create__ac button {
    font-weight: 500;
    font-size: 10px;
  }
  #review .login__content p {
    font-size: 12px;
  }

  #review .login__content {
    width: 100%;
  }

  .maill svg {
    font-size: 80px;
  }
  #verifyCheck h4 {
    font-size: 15px;
    line-height: 25px;
  }
  #ver_modal {
    max-width: 500px;
    max-height: 300px;
  }

  #modal_rest form {
    width: 70%;
  }

  #modal_rest .login__content p {
    font-size: 13px;
    line-height: 19px;
  }
}

@media screen and (max-width: 470px) {
  #new_edit section {
    margin-left: 0rem !important;
}
#new_edit img {
  opacity: 0.5;
  width: 200px;
  left: -6rem;
  object-fit: cover;
}

  #modal_rest {
    max-height: 300px;
  }
  #modal-very {
    max-height: 270px;
  }
  #forgot {
    width: 70%;
  }
  .create__ac {
    margin-top: 2rem;
  }
  .maill svg {
    font-size: 50px;
  }
  #new_modal {
    max-height: 245px;
  }
  #ver_modal {
    max-width: 500px;
    max-height: 260px;
  }
}
@media screen and (max-width: 420px) {
  #new_edit .new_edit_input {
    width: 100%;
    min-width: 100% !important;
  -moz-min-width: 100% !important;

}
  .verify__modal {
    height: 50%;
  }
  .verify__modal .login__content h1 {
    font-size: 16px;
  }

  #review .login__content p {
    font-size: 8px;
    line-height: 20px;
  }

  #modal-very .create__ac {
    margin-top: 1rem;
  }

  .login__content button {
    height: 25px;
    justify-content: center;
    align-items: center;
    line-height: 0;
  }
  #new_edit section{
    width: 200px;
  }
  #new_edit .edit_p {

    margin-left: 5px;
}
#new_edit .contact-wrapper .form-horizontal {
  max-width: 180px !important;
}
.cancel_new_btn {
  margin-left: 2rem;
  font-size: 13px;
}
#new_edit .send-text {
  font-size: 11px;
  margin-top: 1px;
}
.queries p {
  padding-right: 20px;
}
}
@media screen and (max-width: 320px) {
  .blog_left,
  .blog_right {
    margin-right: -8px;
  }
  .blog_card {
    padding: 1rem;
  }

}

/* Astsheling */


.mrAr::-webkit-outer-spin-button,
.mrAr::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product___details > img {
  position: absolute;
  z-index: -1;
  object-fit: contain;
  right: 0;
  top: -25rem;
  height: 100%;
  width: 36%;
  -webkit-animation: spin 10s cubic-bezier(0.47, 0.46, 1, 1) infinite;
          animation: spin 10s cubic-bezier(0.47, 0.46, 1, 1) infinite;
  opacity: 0.091;
  overflow: hidden !important;
  border-radius: 50%;
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform:  rotate(0deg);;
    -ms-transform:  rotate(0deg);;
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform:   rotate(360deg);
    -ms-transform:  rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
    -webkit-transform:  rotate(0deg);;
    -ms-transform:  rotate(0deg);;
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform:   rotate(360deg);
    -ms-transform:  rotate(360deg);
  }
}

.verify__modal {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  background: transparent;
  border-radius: 25px;
  color: #ffffff;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-height: 400px;
  min-height: 300px;
  justify-content: space-evenly;
  transition: all ease-in-out 0.6s;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 4rem;
  margin-top: 1rem;
  max-width: 700px;
  padding: 1rem;
  padding-top: 0;
}
.create__ac button {
  color: #fff;
}
.react-responsive-modal-modal {
  max-width: 800px;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  border-radius: 15px;
}
.login__image {
  margin: 0 2rem 0 2rem;
}
.login__image img {
  object-fit: cover;
  border-radius: 15px;
  height: 313px;
}
.react-responsive-modal-closeButton svg {
  color: red !important;
  fill: red;
}
.react-responsive-modal-modal {
  padding: 3rem !important;
  max-width: 700px !important;
  max-height: 500px!important;
  overflow: hidden;
  padding-bottom: 0 !important;
}

a:link h1 {
  text-decoration: none !important;
  color: unset !important;
}
.form-control[readonly] {
  background: transparent;
  outline: none !important;
  width: 35px !important;
  margin: 5px;
  padding: 0 !important;
  border: none;
}

.inputSpan {
  padding-left: 20px;
}

.greenColor {
  color: green;
}

.redColor {
  color: red;
}
.product___slider .react__arrowPrev {
  position: unset;

  font-size: 2.4rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #000;
}
.product___slider .react__arrowNext {
  position: unset;

  font-size: 2.4rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #000;
}
/* item table */
.table__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
}
.table__items tr td:nth-child(2) {
  padding-left: 1rem;
  padding-right: 2rem;
}
.table__items tr td:nth-child(1) {
  font-size: 16px;
  font-weight: 500;
  color: #111;
  line-height: 50px;
  letter-spacing: 1px;
}
.product___button.hide_big {
  display: none;
}

/* css by am */
.single___product {
  padding-top: 135px;
}
.product___slider {
  z-index: 7;
}
.slider__pdt_video,
.product___slider .slick-list,
.product___slider .slick-slider,
.product___slider {
  width: 500px;
  height: 500px;
  position: sticky;
  top: 60px;

  max-width: 600px;
}
.product___slider .slick-slide {
  display: block !important;
}
.product___slider .slick-dots li {
  display: none;
}
.slider__pdt_video video {
  object-fit: cover;
  width: 500px;
  height: 460px;
}
.slider__pdt_img img {
  object-fit: cover;
  cursor: default;
}
.arrow__wrap {
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  bottom: 1rem;
  right: 6rem;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow__wrap .react__arrowNext,
.arrow__wrap .react__arrowPrev {
  color: #111;
}
.arrow__wrap.rt {
  right: 1rem;
}
.product___details {
  align-items: stretch;
  margin-bottom: 4rem;
  justify-content: space-evenly;
  height: 500px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: transparent;
}

.product___content {
  max-width: 500px;
  width: 40%;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  color: #111;
  padding: 10px;
  padding-bottom: 0;
  height: auto;
}
.product___content span {
  font-size: 12px;
}
.product___content .card_content {
  text-align: start;
  width: 100%;
}
.product___content h1 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  color: #2e2e2e;
}
.table__item {
  font-size: 13px;
  margin-bottom: 1rem;
}
.table__items tr td:nth-child(3) {
  font-size: 14px;
}
.card_content p {
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  line-height: 21px;
  font-size: 13.5px;
  color: #111;
  max-width: 400px;
}
.currency__value{
  font-size: 18px !important;
  font-weight: 800 !important;
}
#stock_status {
  font-size: 12px;
}
.bi__rupee {
  margin-right: 0.5rem;
}
.currency__value {
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 0;

  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 2.5rem !important;
}
/* item table */
.table__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
}
.table__items tr td:nth-child(2) {
  padding-left: 1rem;
  padding-right: 2rem;
}
.table__items tr td:nth-child(1) {
  font-size: 13px;
  font-weight: 420;
  color: #232323;
  line-height: 26px;
  letter-spacing: 1px;
}
.weight__kg {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  height: 30px;
  margin-top: 1rem;
}
.weight__kg label {
  width: 75px;
  height: 30px;
  margin-right: 2rem;
  background: transparent;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 0;
}
.weight__kg label:hover input ~ .btn {
  background-color: #3a1b0c;
  color: #fff;
}

.weight_btn {
  background-color: #3a1b0c;
  color: #fff !important;
}

.weight_btn_normal {
  color: #fff;
}

.weight__kg .btn {
  width: 75px;
  height: 30px;
  border: 1px solid var(--dark-choco);
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a1b0c;
  transition: all 0.25s ease-in-out;
}
.weight__kg.checked button:checked,
.weight__kg button:hover {
  background: #3a1b0c;
  color: #fff;
}
.weight__kg button:focus {
  box-shadow: none;
}
.weights {
  -moz-height: fit-content;
  height: -webkit-fit-content;
  height: fit-content;

}
.weights p {
  margin-bottom: 0.3rem;
}
.weights label input {
  display: none;
}
.product___button {
  margin-top: 1rem;
}
.product___button .btn {
  width: 100%;
  text-align: center;
  background: #cc9b6d;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 30px;
  font-size: 12px;
  display: flex !important;
  text-shadow: 0px 0px 0px rgb(225 225 255);
  box-shadow: 0px 0px 0px rgb(225 225 225);
}
.product__data button:after,
.product___button .btn:after {
  background-color: var(--div-bg-white);
  content: "";
  height: 22px;
  opacity: 0.2;
  position: absolute;
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  width: 70px;
  z-index: -10;
  top: 0px;
  bottom: 0;
  left: -83px;
  right: 0;
}
.product___button .btn:hover::after {
  left: 41rem;
}
.product___button .btn:hover {
  background: #222;
}
/* relatd product section  */
.rld__pdt_section {
  background: #1b1b1b;
  height: 600px;
  color: #fff;
  font-family: var(--font-Montserrat);
}
.related__product {
  flex-direction: column;
  border-right: 1px solid #c4c4c4;
  padding-right: 3rem;
  height: 460px;
  justify-content: flex-start;
  padding-top: 2rem;
}
.related__product h1 {
  font-weight: bold;
  font-size: 34px;
}
.related__product > h1 {
  letter-spacing: 2px;
}
.product__data {
  flex-direction: column;
  align-items: flex-end;
}
.product__data img {
  width: 220px;
  height: 220px;
  cursor: default;
  margin-top: 0.5rem;
}
.product__data h1 {
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}
.product__data p {
  font-weight: 500;
  font-size: 28px;
  color: #95621a;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.product__data button {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  transition: all ease-in-out 0.4s;
  height: 36px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.product__data button:after {
  left: -4rem;
}
.product__data button:hover::after {
  left: 13rem;
}
.product__data button:hover {
  color: #fff;
}
/* about product section */
.about__wrap {
  width: 380px;
  height: 460px;
  margin-left: 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.about__wrap p {
  line-height: 30px;
}
.about__data {
  font-family: var(--font-Montserrat);
  color: #ffffff;
}
.about__title h1 {
  font-weight: bold;
  font-size: 34px;
  letter-spacing: 2px;
}

/* {explore more section start} */
.more__product {
  color: #111111;
  height: 440px;
  margin-left: 3rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
}
.more__product h1 {
  font-family: var(--font-Montserrat);
  font-weight: 800;
  font-size: 16px;
  margin-bottom: -1px;
}
.more__Pdt {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  padding-bottom: 6rem;
}
.img__wrap {
  width: 200px;
  height: 275px;
  margin-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.img__wrap h1 {
  font-size: 14px;
  font-weight: 500;
}
.img__wrap p {
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: -0.5rem;
}
.more__product hr {
  color: #3a1b0c;
}
.img__wrap img {
  object-fit: cover;
  padding-bottom: 0.7rem;
  transition: 0.3s linear;
  width: 200px;
  height: 275px;
  z-index: -1;
}
.more__Pdt::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.more__Pdt::-webkit-scrollbar-track-piece {
  background-color: #c4c4c4;
}
.more__Pdt::-webkit-scrollbar-thumb {
  background-color: #888;
}
.card_content.sptd {
  margin: 0 !important;
}

@media screen and (min-width: 1700px) {
  .slider__pdt_video,
  .product___slider .slick-list,
  .product___slider .slick-slider,
  .product___slider {
    width: 700px;
    height: 700px;
    position: sticky;
    top: 60px;
    max-width: 700px;
  }
  .product___details {
    height: 700px;
  }
  .product___content {
    max-width: 600px;
    width: 35%;
  }
}

@media screen and (max-width: 1080px) {
  .slider__pdt_video,
  .product___slider .slick-list,
  .product___slider .slick-slider,
  .product___slider {
    width: 400px;
    height: 400px;
  }
  .weights {
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 896px) {
  .slider__pdt_video,
  .product___slider .slick-list,
  .product___slider .slick-slider,
  .product___slider {
    width: 350px;
    height: 350px;
  }

  .weight__kg .btn {
    width: 60px;
    height: 25px;
  }
  .weight__kg label {
    width: 60px;
    height: 27px;
    margin-right: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .product___details > img {
    display: none;
  }
  .arrow__wrap {
    width: 35px;
    height: 35px;
    right: 5rem;
  }

  .product___slider .react__arrowPrev,
  .react__arrowPrev {
    font-size: 1.5rem;
  }
  .product___slider .react__arrowNext,
  .react__arrowNext {
    font-size: 1.5rem;
  }
  .slider__pdt_video,
  .product___slider .slick-list,
  .product___slider .slick-slider,
  .product___slider {
    width: 250px;
    height: 250px;
  }

  .weight__kg label {
    width: 60px;
    height: 27px;
    margin-right: 1rem;
  }

  .table__items {
    margin-bottom: 1rem;
  }

}
@media screen and (max-width: 580px) {
  .product___button.hide_small {
    display: none;
  }
  .product___button.hide_big {
    display: block;
  }
  .product___details {
    flex-flow: column;
    height: -webkit-fit-content;
    height: fit-content;
    -moz-height: fit-content;
    align-items: center;
    width: 100%;
  }

  .slider__pdt_video,
  .product___slider .slick-list,
  .product___slider .slick-slider,
  .product___slider {
    width: 100%;
    height: 100vw;
    margin-left: auto;
    margin-right: auto;
    position: inherit;
    top: 0;
  }

  .product___content {
    height: -webkit-fit-content;
    height: fit-content;
    -moz-height: fit-content;
    width: 100%;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    min-height: 430px;
    padding: 0;
  }

  .more__product h1 {
    font-size: 13px;
  }
  .product___button .btn {
    font-size: 14px;
  }
  .more__product {
    height: 350px;

    margin-top: 5rem;
  }
  .img__wrap img,
  .img__wrap {
    width: 105px;
    height: 165px;
  }
  .img__wrap p {
    font-size: 10px;
  }
  .arrow__wrap {
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 420px) {
  .card_content,
  .weights {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .arrow__wrap {
    width: 30px;
    height: 30px;
    right: 4rem;
  }
  .weights {
    margin-top: 1.4rem;
  }
  .table__items tr td:nth-child(3) {
    font-size: 13px;
  }
  .weight__kg {
    margin-top: -1rem;
  }
  .product___button .btn {
    height: 35px;
  }

  .product___button {
    position: sticky;
    bottom: 0;
  }
  .product___button .btn {
    position: sticky;
    top: 400px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 370px) {
  .product___button .btn {
    height: 45px;
  }
}

#new_select section {
  height: 22px;
  padding: 0;
  padding-left: 10px;
}
.profile_new .input__wrap {
  margin-bottom: 2rem;
}
.db__seting .db_sett {
  align-self: flex-start;
  margin-left: 2rem !important;
}
.db__seting .db_product_form {
  margin-left: 50px;
  margin-right: auto;
  width: 500px;
}
.db__seting {
  width: 400px;
  min-height: 300px !important;
  height: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 25% !important;
}
#new_date {
  padding: 1rem;
  padding-top: 0;
}
.reviewMessageText {
  height: -webkit-max-content !important;
  height: max-content !important;
}

.user__wrap {
  justify-content: space-between;
}

a:link {
  text-decoration: unset !important;
  color: unset !important;
  background-color: unset !important;
}

a:link::before {
  text-decoration: unset !important;
  color: unset !important;
  background-color: unset !important;
}

.billing__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
}

.starButton {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.starButton .starfill__icon,
.starButton .star__icon {
  font-size: 2rem !important;
}
.adminPanelText {
  transition: var(--animation);
  font-weight: 300;
  font-size: 12px;
  line-height: 36px;
  color: #545454;
  margin-bottom: 0;
  letter-spacing: 0.1rem;
}

.star__icon {
  font-size: 1rem;
  color: #545454;
  cursor: pointer;
}
.starfill__icon {
  font-size: 1rem;
  color: #d75105;

  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
}

.od__view .shipping__info {
  width: 300px;
}

#current .react-slidedown.closed {
  display: block !important;
  height: auto !important;
}
.pro-item-content a {
  color: currentColor !important;
}
.pro-item-content a:hover {
  color: currentColor !important;
}
.db__sidebar_wrap {
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
}

.db__sidebar_wrap::-webkit-scrollbar {
  display: none;
}
.db__home_left {
  background-color: #f5f5f5;
  width: calc(100% - 330px);
  margin-left: auto;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul,
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 0 !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-left: 1.5rem !important;
}
.pro-item-content {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.pro-icon .title_icon {
  color: #545454;
}
.react-slidedown.pro-inner-list-item,
.pro-menu.shaped.square ul {
  border-radius: 15px !important;
}
#current ul {
  background-color: rgb(245, 245, 245);
}
.pro-item-content a {
  font-size: 16px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding-right: 6rem !important;
}
.db_sd_title.current ul {
  background-color: rgb(245, 245, 245);
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b00 !important;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #fff !important;
}
.db_sd_title.current {
  background-color: rgb(245, 245, 245) !important;
}

.pro-inner-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#alpt .pro-inner-item .pro-item-content a {
  font-size: 16px !important;
}

.pro-menu-item.active .pro-item-content a {
  font-weight: bold !important;
  letter-spacing: 0.4px !important;
}
.pro-menu-item.active .pro-menu.shaped.square ul {
  background-color: rgb(245, 245, 245) !important;
}
.pro-menu-item .pro-sub-menu {
  background: #fff !important;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b00 !important;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b00;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul:hover {
  background-color: rgb(245, 245, 245) !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
  border-radius: 15px;
  justify-content: flex-start;
}
.pro-sidebar
  > .pro-sidebar-inner
  > .pro-sidebar-layout
  ul:hover
  .pro-icon-wrapper
  .pro-icon {
  background-color: rgb(245, 245, 245) !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  transition: var(--animation);
  font-weight: 300;
  font-size: 16px;
  line-height: 36px;
  color: #545454;
  cursor: pointer;
  margin-bottom: 0;
}
.title__icon {
  color: #545454;
  cursor: pointer;
  font-size: 16px;
  transition: var(--animation);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: rgb(245, 245, 245);
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: none !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 48px !important;
}
.pro-sidebar {
  background-color: rgb(245, 245, 245) !important;
}

.db_sd__title.current {
  background-color: rgb(245, 245, 245);
}

.db__home {
  min-height: 100vh;
  height: 100%;
  display: flex;
}
.db__sidebar a {
  text-decoration: none;
  color: currentColor;
}
.db__sidebar_wrap {
  width: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
}
.db__sidebar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}
.db__logo {
  width: 150px;
  height: 50px;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.db_sd__title {
  width: 261px;
  min-height: 60px;
  height: auto;
  justify-content: flex-start;
  border-radius: 15px;
  margin-bottom: 0.5rem;
  transition: var(--animation);
}
.db_sd__title:hover {
  background-color: rgb(245, 245, 245);
}

.db_sd__title h1 {
  transition: var(--animation);
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #545454;
  cursor: pointer;
  margin-bottom: 0;
}
.db__title_icon {
  color: #545454;
  cursor: pointer;
  font-size: 20px;
  margin-left: 2rem;
  margin-right: 1.5rem;
  transition: var(--animation);
}
.db_sd__title {
  position: relative;
}
.od_db_down,
.od_db_up {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
}

.order__wrap.show .od_db_down {
  opacity: 0;
  visibility: hidden;
}
.od_db_up {
  opacity: 0;
  visibility: hidden;
}
.od_db_down {
  color: #545454;
  opacity: 1;
  visibility: visible;
}
.order__wrap.show .od_db_up {
  opacity: 1;
  visibility: visible;
}
.db__user_wrap p,
.order__wrap p {
  display: none;
  margin-top: 1rem;
  opacity: 0.7;
  transition: var(--animation);
}
.db__user_wrap p:hover,
.order__wrap p:hover {
  opacity: 1;
}
.order__wrap .current {
  opacity: 1;
}
.db__user_wrap.show p,
.order__wrap.show p {
  display: flex;
  cursor: pointer;
  font-weight: 500;
}
.db_sd__title.current .order__wrap {
  padding-top: 1rem;
}
.db_sd__title.current .db__user_i {
  height: 148px;
  padding-top: 1.2rem;
}
.db__user_wrap.show p:nth-child(2) {
  margin-top: 8px;
}
.db__user_wrap.show p.current {
  opacity: 1;
}
.db__user_wrap.show .us_up {
  opacity: 1;
  visibility: visible;
}
.db__user_wrap.show .us__down {
  opacity: 0;
  visibility: hidden;
}

.Db__header {
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgb(0 0 0 / 5%);
  margin: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 0 0 12px 12px;
  display: flex;
  margin-top: -4px;
}
.header__name {
  flex: 1 1;
  justify-content: flex-start;
  margin-left: 2rem;
}
.header__name h1 {
  font-weight: 300;
  font-size: 15px;
  color: #545454;
  margin-bottom: 0;
}
.Db__head_icon {
  margin-right: 2rem;
}
.admin__avatar img,
.admin__avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}
.head__icon {
  font-size: 20px;
  cursor: pointer;
}
.head__icon:hover {
  color: #d75105;
}
.bell_icon_wrap {
  position: relative;
  display: none;
}
.bell_icon_wrap label {
  position: absolute;
  background: #d75105;
  border: 1.5px solid #ffffff;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 100%;
  right: 0;
  display: none;
}

.db__reviews h1 {
  font-weight: 500;
  font-size: 17px;
  line-height: 60px;
}
/* .db__home_left {
  width: 100%;
} */
.db__reviews {
  margin: 2rem;
  background: #fff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  min-height: 74vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.sad__icon {
  font-size: 11rem;
  opacity: 0.2;
}
.db__defualt_reviews {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
}
.db__defualt_reviews p {
  opacity: 0.3;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  color: #545454;
}

.newPtd {
  margin-left: 18rem !important;
}
.db_total__amount {
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
  margin: 1rem 2rem 1rem 2rem;
}
.db_total__amount p {
  font-weight: 300;
  font-size: 16px;
  color: #545454;
  margin-bottom: 0.5rem;
}
.db_total__amount h1 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
}
.db__info_wrap {
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem 1rem 2rem;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-start;
}
.db__info_wrap:last-child {
  margin-right: 0;
}
.db__pro {
  width: 23%;
  max-width: 240px;
  height: 170px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
}
.db__pro p {
  font-weight: 300;
  font-size: 14px;
  color: #545454;
  margin: 1rem;
  margin-bottom: 0.5rem;
}
.db__pro h1 {
  margin: 1rem;
  margin-top: 0;
  font-weight: 500;
  font-size: 20px;
}
.db__pro h1 span {
  line-height: 50px;
}
.db__pro button {
  width: 100%;
  position: absolute;
  bottom: 0;

  border-top: 1px solid #5454542f;
  border-radius: 0px 0px 15px 15px;
  height: 50px;
  font-weight: 300;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--animation);
}
.db__pro button:hover {
  letter-spacing: 1px;
  background: #184d47;
  color: #c6ebc9;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.fa-trash {
  transition: var(--animation);
}
.fa-trash:hover {
  color: red;
}

.db__orders {
  align-items: flex-start;
}
.db__orders h1 {
  margin: 1rem 2rem 3rem 3rem;
  width: 100%;
  width: -webkit-fit-content;
  width: fit-content;
  border-bottom: 2px solid #cfcfcf;
  height: 50px;
}

.db_sd__title.product {
  align-items: stretch;
  position: relative;
}
.db_sd__title.product.current .db__pdt_content h1 {
  font-weight: 500 !important;
}
.db__pdt_content {
  padding-top: 1rem;
}
.db__pdt_content h1 {
  margin-bottom: 1rem;
}
.db_sd__title.product h2 {
  font-size: 16px;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: none;
}
.db_sd__title.product p {
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: none;
}
.db_sd__title.product .current {
  opacity: 1;
}
.db__book_icon {
  padding-top: 1.3rem !important;
}
.db__pdt_content.show h2,
.db__pdt_content.show p {
  opacity: 0.7;
  transition: var(--animation);
}
.db__pdt_content.show h2:hover,
.db__pdt_content.show p:hover {
  opacity: 1;
}
.db__pdt_content.show h2,
.db__pdt_content.show p {
  display: flex;
}
.db_down,
.db_up {
  position: absolute;
  right: 2rem;
  color: #545454;
  top: 1.7rem;
  transition: var(--animation);
  cursor: pointer;
}
.db_up {
  opacity: 0;
  visibility: hidden;
}
.db__pdt_content.show .db_up {
  opacity: 1;
  visibility: visible;
}
.db__pdt_content.show .db_down {
  opacity: 0;
  visibility: hidden;
}
.osperb p {
  padding-bottom: 2rem;
  font-size: 13px;
  color: #545454;
}

.osperb {
  bottom: 0;
}
.db__sup {
  margin-bottom: 2rem;
}

.db__column_link {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 1.4rem;
  width: 150%;
}

.db__column_btn {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 1.4rem;
  width: 40%;
}

.db__column_btn button {
  color: #fff;
}

.db__column_btn button:hover {
  background-color: #fff;
  color: #111;
  border: 1px solid;
}
.freeSpace.new {
  width: 5rem;
}

.db__column {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  width: 100%;
}
.db__column label {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #545454;
  margin-bottom: 0.6rem;
  text-transform: capitalize;
}
.db_rdio {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.db_sheet_btn {
  width: -webkit-fit-content;
  width: fit-content;
}
.db__column .btn {
  height: 30px;
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.db__column .btn svg {
  height: 25px;
  width: 25px;
}
.db__column .btn svg:hover {
  color: var(--danger-color);
}
.db__column input {
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #545454;
  padding-left: 1rem;
  width: 100%;
}
.db__column input:focus {
  outline: none;
}
.db__column textarea {
  padding: 1rem;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
}
.db__column textarea:focus {
  outline: none;
}
.db__column .dropdown button {
  width: 358px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  color: #545454;
  text-transform: capitalize;
}
.db__column .dropdown button:focus {
  outline: none;
  box-shadow: none;
}
.db__column .dropdown-menu {
  width: 100%;
  height: auto;
  background: #fff;
  text-transform: capitalize;
}
.Category_wrap,
.db__prdt_name_price {
  display: flex;
}
.db__new_prdt {
  margin: 2rem;
  margin-top: 0;
  width: 100%;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.img__upload {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  color: #545454;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.db_product_form {
  margin-left: auto;
  margin-right: auto;
  width: 550px;
}
.dbb_new_input input {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 1.4rem;
  width: 25%;
}
.media__icon {
  cursor: pointer;
  font-size: 23px;
  margin-right: 2rem;
  height: 100%;
}
.db_rdio label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 19px !important;
  width: 50%;
}
.Seller_wrap img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: default;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.db__prices div {
  display: flex;
  flex-direction: column;
  width: 150px;
}
.db__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-right: 1rem;
}
.db__prices div label {
  text-transform: uppercase;
  font-size: 14px;
  color: #545454;
}
.db__prices div input {
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  color: #545454;
  margin-right: 1rem;
  padding: 0.5rem;
  font-size: 14px;
}
.db__prices div input:focus {
  outline: none;
}
.db__new_prdt p {
  color: #545454;
  text-transform: capitalize;
  margin-bottom: 0;
  width: 100%;
  text-align: start;
}
#checkbox_field1 {
  width: 15px;
  margin-left: -70px;
}
.add__prdt:hover {
  color: #fff;
}
.db_btn__add {
  width: 100%;
  margin-top: 1rem;
}

#checkbox_field1,
#checkbox_field2,
#checkbox_field3 {
  width: 15px;
  margin-left: -60px;
}

.add__prdt {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 30px;
  transition: var(--animation);
  background-color: darkgreen;
  color: #fff;
}
.add__prdt:focus {
  box-shadow: none;
}
.db__table {
  width: 90%;
  margin: 2rem;
  margin-top: 0;
}
.db__column .checkmark {
  border-radius: unset;
  border: 1px solid rgba(84, 84, 84, 0.5);
  opacity: 0;
}
.db__column .input__radio_wrap {
  margin-top: 0.5rem;
}

.db__testnomial {
  margin-left: auto;
  margin-right: auto;
}
#testnomial-img {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.order__heads {
  display: flex;
}
.od_head__text {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.od_head__text h1 {
  margin-bottom: 0.3rem;
  line-height: 30px;
}
.od_head__text p {
  margin-left: 2rem;
}
.order__heads {
  width: 100%;
}
.od__star {
  padding: 2rem;
  width: 150px;
  position: relative;
}
.od__start_toggle {
  width: 30px;
}
.star__icon {
  font-size: 1rem;
  color: #545454;
  cursor: pointer;
}
.starfill__icon {
  font-size: 1rem;

  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
}
.starfill__icon.active {
  visibility: visible;
  opacity: 1;
}
.order__info_wrap {
  margin-left: auto;
  margin-right: auto;
}
.db__home .order__info_wrap {
  padding: 2rem;
  margin-right: 2rem;
  width: 100%;
}
.db__home .db__orders h1 {
  border-bottom: none;
}
.od__view .order__info_wrap h1 {
  margin: 0;
  margin-top: 1rem;
  font-size: 20px;
}
.od__view .shipping__info {
  width: 300px;
}

.od__view .payment__data {
  width: 400px;
}
.status__dropdown .dropdown button {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  color: #545454;
}
.od__btn:focus,
.status__dropdown .dropdown button:focus {
  outline: none;
  box-shadow: none;
}
.status__dropdown .dropdown-menu.show {
  width: 100%;
  background: #fff;
}
.od__btn {
  width: 200px;
  margin-top: 4rem;
  background: #111;
  color: #fff;
  transition: var(--animation);
}
.od__btn:hover {
  background: #fff;
  color: #111;
  border: 1px solid #111;
}
.order__item__od {
  width: 100%;
  padding: 2rem;
}
.order__item__od .order__item__table {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #545454 !important;
}
.order__item__od h1 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 20px;
}
.od__view {
  color: #545454 !important;
}
.stared__wrap .order__item__table {
  height: 60px;
  width: 70%;
}
.stared__wrap .order__item__table div {
  height: 50px;
}
.stared__wrap .item__img {
  margin-top: -0.7rem;
  height: 50px;
  margin-bottom: 0.5rem;
  width: 70px;
}
.stared__wrap .item__img img {
  object-fit: cover;
}
.stared__wrap .uq .starfill__icon {
  left: 0.734rem;
}
.od__faeye {
  cursor: pointer;
}
.od__faeye:hover {
  color: #d75105;
}
.db__user_edit {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}
.db__user_edit .input__wrap {
  width: 500px;
}
.user__type label {
  color: #545454;
}
.user__type select {
  border: 1px solid #545454;
  text-transform: capitalize;
  width: 200px;
  color: #545454;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.user__type .dropdown .dropdown-menu.show {
  width: 200px;
  background: #fff;
}
.user__type .dropdown button:focus {
  outline: none;
  box-shadow: none;
}
.btn__user__up {
  margin-top: 2rem;
  width: 200px;
  background: #545454;
  color: #fff;
  transition: var(--animation);
}
.btn__user__up:hover {
  color: #111;
  background: #ccc;
}
.btn__user__up:focus {
  box-shadow: none;
}
.bd__tb-new p {
  font-size: 14px;
  color: #464646;
  margin-bottom: 2rem;
}
.bd_tb_span {
  color: #000;
  font-weight: 500;
}
.db_sett {
  align-self: center;
  margin-left: -9rem !important;
  margin-bottom: 3rem !important;
  margin-top: 2rem !important;
}
.suc-btn {
  width: 100%;
  margin-top: 2rem;
}
.bd__tb-new {
  width: -webkit-fit-content;
  width: fit-content;
  -moz-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.db_price_sheet {
  border-radius: 10px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

.btn:focus {
  box-shadow: none;
}
.displya_tyext {
  display: none;
  height: 100vh;
  width: 100vw;
}
@media screen and (max-width: 800px) {
  .db__home {
    display: none;
  }
  .displya_tyext {
    display: flex;
  }
}

/* astshelving */
.about__us {

  flex-direction: column;
  display: flex;
  min-height: 100vh;
  height: auto;
  padding-top: 130px;
}
.ab__wrapper {
  padding-left: 0rem;
}
.right__bgImg,
.left__content {
  width: 100%;
}
.right__bgImg {
  height: 45vh;
}
.reach__out {
  width: 177px;
  font-size: 22px;
  line-height: 33px;
}
.left__content {
  padding-top: 3rem;
  line-height: 28px;
}
.about__us {
  padding-top: 70px;
  /* margin-bottom: 5rem; */
}
.ab__wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 3rem;
}
.ab__wrapper div {
  margin-bottom: 4rem;
  width: 100%;
  padding: 0 2rem 0 2rem ;
}
.left__content.new{
  justify-content: space-between;
  padding: 0 0rem 0 4rem;
  align-items: flex-start;
}
.left__content.new .ab__div_three.any{
  width: 280px;
}
.icons_wrap_new{
  /* border: 5px solid #6b6b6b; */
  background: transparent;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  display: block;
  line-height: 65px;
  text-align: center;
  margin: 0px auto;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
margin-bottom: 1rem;
-webkit-animation: mymove 6s ease-in-out infinite;
        animation: mymove 6s ease-in-out infinite;
}
@-webkit-keyframes mymove {

  0%{-webkit-transform: rotate(0deg);transform: rotate(0deg)}
  50%{-webkit-transform: rotate(0deg);transform: rotate(0deg)}
  75% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
  100%{-webkit-transform: rotate(0deg);transform: rotate(0deg)}
}
@keyframes mymove {

  0%{-webkit-transform: rotate(0deg);transform: rotate(0deg)}
  50%{-webkit-transform: rotate(0deg);transform: rotate(0deg)}
  75% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
  100%{-webkit-transform: rotate(0deg);transform: rotate(0deg)}
}

.ab__wrapper div h4{
  text-align: center;
margin-bottom: 2rem;
font-size: 18px;
}
.icons_wrap_new svg{
color: #6b6b6b;
font-size: 2rem;
}
.ab__wrapper div p{
  /* text-align: center; */
  opacity: 0.9;
  font-size: 13px;

}
.left__content.new .fst p{
  line-height: 24px;
}
.left__content.new .fst p svg{
 margin-right: 1rem;
 color: teal;
 font-size: 17px;
}
.ab__div_three.any.tw h4{
  margin-bottom: 1.5rem ;
}
.ab__div_three.any.tw p{
  opacity: 0.8;
  line-height: 26px;

}
.ab__div_three.any h6{
font-size: 14px;
margin-bottom: 1.5rem ;

}





.retn__policy p,
.retn > p,
.retn__text ul li,
.retn__policy p,
.retn__support h4,
.faq__wrap h1,
.faq__wrap p,
.pp__wrap p,
.terms__ne_ol li,
.faq__ol li,
.terms__list ol li:nth-child(4) ul li,
.top p,
.terms__list ol li p,
.faq__wrap ol {
  font-size: 14px;
  line-height: 30px;
}
.pp__wrap h1,
.terms__list ol li h1,
.terms__wrap h1,
.retn__policy h1,
.terms__list ol,
.faq__wrap > h1,
.pp__wrap h1:nth-child(1) {
  font-size: 18px;
}
.faq__wrap h1 {
  font-size: 15px;
}
.faq__wrap > h1 {
  font-size: 20px;
}

/* .right__bgImg,
.left__content {
  width: 50%;
} */
.right__bgImg {
  position: relative;
}
.right__bgImg img {
  object-fit: cover;
}
.pp__wrap .pointer__img {
  height: 16px;
  width: 16px;
  margin-top: 0px;
}
.reach__out {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: absolute;
  width: 233px;
  font-family: var(--font-Montserrat);
  font-weight: 600;
  font-size: 42.627px;
  line-height: 50px;
  color: #ffffff;
  text-transform: capitalize;
}

.left__content {
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ab__div_two h6,
.ab__div_one h6 {
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 12px;
}
.ab__div_one p {
  font-weight: 300;
}
.ab__div_one .mail__icon {
  margin-right: 1rem;
}
.ab__div_two p {
  font-weight: 300;
  font-size: 15px;
}



/* trems  */
.pp__wrap h1,
.terms__list ol li h1,
.terms__wrap h1 {
  text-transform: uppercase;
  margin-top: 1.3rem;
  margin-bottom: 1rem;
  color: #1b1b1b;
  font-weight: 300;
}
.faq__wrap h1,
.faq__wrap p,
.pp__wrap p,
.terms__ne_ol li,
.faq__ol li,
.terms__list ol li:nth-child(4) ul li,
.top p,
.terms__list ol li p {
  font-weight: 300;
  color: #1b1b1b;
  margin-bottom: 1.5rem;
}
.terms__list ol li:nth-child(4) ul li {
  list-style: disc;
}
.product__home,
.pp_home,
.faq__home,
.terms__home {
  padding-top: 150px;
  margin-bottom: 5rem;
}
.faq__wrap,
.pp__wrap,
.terms__wrap {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.terms__wrap > h1 {
  text-align: center;
  margin-bottom: 3rem;
}
.top {
  margin-bottom: 2rem;
}
.faq__wrap > h1,
.pp__wrap h1:nth-child(1) {
  text-align: center;
  margin-bottom: 3rem;
}
.faq__wrap h1,
.pp__wrap h1 {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}
.faq__wrap a {
  color: #95652a;
}
.faq__wrap a:hover {
  color: #95652a;
  opacity: 0.8;
}

/* product  */
.product__wrapp h1 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.08em;
  color: #1b1b1b;
  margin-bottom: 2.5rem;
  text-transform: uppercase;
}
.pr_sh {
  margin-top: 3rem;
}
.product__home {
  min-height: 100vh;
}
.pr_qu_one h4 {
  font-weight: 300;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: 0.08em;
  color: #1b1b1b;
  position: relative;
}

.pr_qu_one {
  margin-bottom: 1.6rem;
}
.product__wrapp {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.pr_qu_one p {
  color: #1b1b1b;
  font-weight: 100;
  font-size: 14px;
  margin-top: 2rem;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
  height: 0;
}
.bi__plus {
  cursor: pointer;
}
.bi__plus:hover {
  color: #95652a;
}
.bi__plus {
  opacity: 1;
  visibility: visible;
  transition: var(--animation);
  margin-right: 0.5rem;
}
.bi__minus {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 7px;
  transition: var(--animation);
  margin-right: 0.5rem;
}
.pr_qu_one.active .bi__plus {
  opacity: 0;
  visibility: hidden;
}
.pr_qu_one.active .bi__minus {
  opacity: 1;
  visibility: visible;
}
.pr_qu_one.active p {
  opacity: 1;
  visibility: visible;
  height: -webkit-fit-content;
  height: fit-content;
  -moz-height: fit-content;
}
.pp__wrap ul li {
  list-style: none;
  display: flex;
  margin-bottom: 1.5rem;
}
.retn__text ul li {
  margin-bottom: 1rem;
}
.faq__ol li,
.terms__wrap .terms__ne_ol li,
.terms__list ol li:nth-child(4) ul li {
  list-style: none;
  margin-bottom: 1.5rem;
  display: -webkit-box;
}
.pointer__img {
  height: 10px;
  width: 10px;
  margin-right: 1.5rem;
  border-radius: 100%;
  background: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
}

/* return policy */

.retn__policy h1 {
  text-align: center;
  letter-spacing: 0.08em;
  color: #1b1b1b;
  margin-bottom: 3rem;
}
.retn__policy {
  min-height: 100vh;
}
.retn__policy p {
  font-weight: 300;
  line-height: 28px;
  color: #1b1b1b;
  margin-bottom: 1.5rem;
}
.retn > p {
  margin-bottom: 0.5rem;
}
.retn__policy p,
.retn > p,
.retn__text ul li {
  font-weight: 300;
  color: #1b1b1b;
  list-style: none;

  display: -webkit-box;
}
.retn__support h4 {
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #1b1b1b;
  margin-bottom: 0;
}
.retn__support {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}
.rent__mail__icon {
  font-size: 1.3rem;
  margin-right: 1rem;
  cursor: pointer;
}
.retn__policy {
  padding-top: 170px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.retn__heads {
  text-align: start;
}
.retn {
  width: 80%;
}


@media screen and (max-width: 896px) {

  .retn__policy {
    width: 70%;
  }

  .reach__out {
    width: 213px;
    font-size: 50.627px;
    line-height: 60px;
  }
}
@media screen and (max-width: 768px) {
  .pointer__img {
    height: 15px;
    width: 15px;
  }
  .retn__policy {
    padding-top: 120px;
  }
  .ab__wrapper{
    flex-direction: column;
  }
  /* .retn__policy p,
  .retn > p,
  .retn__text ul li,
  .retn__policy p,
  .retn__support h4,
  .faq__wrap h1,
  .faq__wrap p,
  .pp__wrap p,
  .terms__ne_ol li,
  .faq__ol li,
  .terms__list ol li:nth-child(4) ul li,
  .top p,
  .terms__list ol li p,
  .faq__wrap ol  {
    font-size: 12px;
    line-height: 22px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1,
  .retn__policy h1,
  .terms__list ol,
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    font-size: 14px;
  } */
  .product__home,
  .pp_home,
  .faq__home,
  .terms__home {
    padding-top: 100px;
  }
  .retn__policy {
    width: 80%;
  }
  .about__us {
    padding-top: 130px;
  }
  .left__content {
    line-height: 25px;
  }

  .reach__out {
    width: 160px;
    font-size: 30.627px;
    line-height: 44px;
  }
}
@media screen and (max-width: 580px) {
  .about__us {
    display: flex;
    flex-direction: column;
  }
  .about__us {
    height: auto;
  }

  .right__bgImg,
  .left__content {
    width: 100%;
  }
  .right__bgImg {
    height: 25vh;
  }
  .reach__out {
    width: 177px;
    font-size: 22px;
    line-height: 33px;
  }
  .left__content {
    padding-top: 3rem;
    line-height: 28px;
  }
 
  .pointer__img {
    height: 12px;
    width: 12px;
  }

  .ab__wrapper div {
    margin-bottom: 3rem;
  }
  .retn__policy p,
  .retn > p,
  .retn__text ul li,
  .retn__policy p,
  .retn__support h4,
  .faq__wrap h1,
  .faq__wrap p,
  .pp__wrap p,
  .terms__ne_ol li,
  .faq__ol li,
  .terms__list ol li:nth-child(4) ul li,
  .top p,
  .terms__list ol li p,
  .faq__wrap ol {
    font-size: 12px;
    line-height: 28px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1,
  .retn__policy h1,
  .terms__list ol,
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    font-size: 14px;
  }
  .retn__policy {
    padding-top: 90px;
  }
  .product__home,
  .pp_home,
  .faq__home,
  .terms__home {
    padding-top: 60px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1 {
    line-height: 15px;
  }
}

@media screen and (max-width: 420px) {
  .pointer__img {
    height: 10px;
    width: 10px;
    margin-top: 5px;
  }

  .retn__policy p,
  .retn > p,
  .retn__text ul li,
  .retn__policy p,
  .retn__support h4,
  .faq__wrap h1,
  .faq__wrap p,
  .pp__wrap p,
  .terms__ne_ol li,
  .faq__ol li,
  .terms__list ol li:nth-child(4) ul li,
  .top p,
  .terms__list ol li p,
  .faq__wrap ol {
    font-size: 11px;
    line-height: 24px;
  }
  .pp__wrap h1,
  .terms__list ol li h1,
  .terms__wrap h1,
  .retn__policy h1,
  .terms__list ol,
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    font-size: 12px;
  }
}
@media screen and (max-width: 370px) {
  .ab__wrapper {
    padding: 1rem;
  }
  .left__content {
    font-size: 12px;
  }
  .ab__div_two p {
    font-size: 13px;
  }
  .pointer__img {
    height: 8px;
    width: 8px;
    margin-top: 6px;
  }
  ol,
  ul {
    padding-left: 1rem;
  }
  .faq__wrap > h1,
  .pp__wrap h1:nth-child(1) {
    text-align: center;
    margin-bottom: 2rem;
  }
}


.scraf__section {
  margin-top: 2rem;
}
.product__scarf {
  background: #fff;
  color: #1b1b1b;
  padding-top: 130px;
}
.product-hero {
  height: 55vh;
  padding-top: 133px;
  position: relative;
}
.product__scarf .mail_section label {
  width: 100%;
}
.product__scarf .mail_section label h4 {
  font-size: 20px;
  color: #fff;
  letter-spacing: 1.5px;
  font-weight: bold;
}
.product-hero img {
  object-fit: cover;
  cursor: default;
}
.product-hero span {
  position: absolute;
  background: #fff;
  width: 19px;
  height: 19px;
  background: rgba(253, 253, 253, 0.85);
  box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.31);
  border-radius: 100%;
  cursor: pointer;
}
.product-hero span:nth-child(2) {
  left: 14rem;
  top: 12rem;
}
.product-hero span:nth-child(3) {
  left: 20rem;
  top: 4rem;
  z-index: 16;
}
.product-hero span:nth-child(4) {
  left: 29rem;
  top: 29rem;
}
.product-hero span:nth-child(5) {
  top: 7rem;
  left: 47rem;
}
.product-hero span:nth-child(6) {
  left: 52rem;
  top: 21rem;
}
.product-hero span:nth-child(7) {
  left: 60rem;
  top: 15rem;
}
.product__nav {
  margin-top: 2rem;
  margin-right: auto;
  position: relative;
  justify-content: space-around;
}

.product__nav h1 {
  font-weight: normal;
  font-size: 35px;
  line-height: 75px;
}
.product_list hr {
  height: 3px;
  border-radius: 5px;
  width: 93%;
  margin-bottom: 3rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.center_align {
  justify-content: center;
}

.card__contianer .card_wrap {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.dropdown-menu {
  bottom: -27rem;
  margin-top: 0.5rem !important;
  transition: var(--animation);
  background: linear-gradient(
    164.53deg,
    rgba(64, 64, 64, 0.258) -28.7%,
    rgba(41, 37, 37, 0) 152.85%
  );
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  border-radius: 10px;
  min-width: 6rem;
}
.dropdown-toggle {
  background: #fff;
  color: #111;
  border: 1px solid rgb(145, 144, 144);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 10px;
}
.dropdown-toggle:hover {
  background: transparent;
  color: #111;
  border: 1px solid #1b1b1b;
}
.dropdown-toggle:focus {
  outline: none !important;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  background: #fff;
  color: #111;
  border: 1px solid #1b1b1b;
}
.dropdown-item:focus {
  background: #cccc;
  color: #111;
}

@media screen and (max-width: 768px) {
  .product__scarf .mail_section label h4 {
    font-size: 18px;
  }
  .product-hero {
    padding-top: 100px;
    height: 49vh;
  }
}

@media screen and (max-width: 536px) {
  .card__contianer .card_wrap {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .fade__bg_ptd {
    padding-left: 2rem;
  }
}


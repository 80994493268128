/* alwanu shurooq */

/* contact form */
.form-group div {
  padding: 0;
}
#contact {
  width: 100%;
  height: 100%;
  background: #f1f1f1;
}
#contact.white {
  background: #fff;
}
.hero_text_new {
  font-size: 16px;
  line-height: 25px;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  color: rgba(0, 0, 0, 0.782);
  text-transform: uppercase;
  letter-spacing: 4px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  max-width: 840px;
  padding-bottom: 60px;
}

/* Left contact page */
.form-horizontal {
  max-width: 400px;
  font-family: "Lato";
  font-weight: 400;
}
.form-control,
textarea {
  width: 100%;
  background-color: #0000;
  color: rgb(0, 0, 0);
  letter-spacing: 1px;
  height: 35px;
  border: 1px solid #777 !important;
}

#new_edit .alt-send-button svg {
  margin-top: 10px;
}

.fa-phone,
.envolp {
  display: flex;
  font-size: 15px;
  margin-right: 1.5rem;
  margin-top: 5px;
}

.send-button {
  height: 30px;
  width: 400px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cc9b6d;
  border-color: #cc9b6d;
}

.alt-send-button {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
}
.alt-send-button svg {
  margin-top: 7px;
  font-size: 18px;
}
.send-text {
  display: block;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  line-height: unset !important;
}

.send-button:hover .alt-send-button {
  transform: translate3d(0px, -29px, 0px);
}

/* Begin Right Contact Page */
.direct-contact-container {
  max-width: 400px;
}

/* Location, Phone, Email Section */
.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}
.list-item.tw {
  align-items: flex-end !important;

  margin-bottom: 0;
  margin-top: 5rem;
}
.list-item {
  color: #424242;
  display: flex;
  align-items: unset;
  justify-content: flex-start;
  margin-bottom: 2rem;
  font-size: 14px;
}
.contact-list hr {
  margin-bottom: 2rem;
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  margin: 0;
  font-size: 14px;
}
.place {
  line-height: 15px;
  margin-bottom: 1rem;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

/* Social Media Icons */
.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.social-media-list li a {
  color: #fff;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 3px;
  line-height: 60px;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #fff;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

.social-media-list li:hover a {
  color: #000;
}

.copyright {
  font: 200 14px "Oswald", sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

.Blog_new .slick-list {
  width: 80% !important;
  margin-left: auto;
}

/* Begin Media Queries*/
@media screen and (max-width: 850px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container,
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  .form-control,
  textarea {
    margin: 0 auto;
  }

  .name,
  .email,
  textarea {
    width: 280px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }
  .social-media-list {
    left: 0;
  }
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
}

.Blog_new {
  height: 80vh;
  background: #f2dac3;
  position: relative;
  min-height: 70vh;
}
.Blog_new .slick-slider,
.Blog_new .slick-list,
.Blog_new .slick-track {
  height: 100%;
}
.fading h1 {
  font-size: 60px;
  padding-left: 4rem;
  font-weight: 600;
  margin-bottom: 5rem;
  letter-spacing: 2px;
}
.arrowBgPrev.wrap,
.arrowBgNext.wrap {
  position: absolute;
  top: 60%;
  left: 5rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 10 !important;
  color: #000;
  background: #fff;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrowBgPrev,
.arrowBgNext {
  font-size: 2rem;
}
.arrowBgNext.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 60%;
  cursor: pointer;
  opacity: 0.6;
  left: 10rem;
  color: #000;
  left: 12rem;
  z-index: 10 !important;
}
.Blog_new .fading {
  width: 250px;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: opacity(0.9);
  background: #f2dac3;

  position: absolute;
  left: 0;
  z-index: 9;
}
.Blog_new .slick-slide {
  min-height: 100px !important;
  width: 280px !important;
  height: auto !important;
  background: #ffffff;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.Blog_new .slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_card {
  padding: 2rem;
}
.blog__title {
  font-size: 16px;
  margin-bottom: 1.5rem;
}
.blog__header {
  font-size: 11px;
  margin-bottom: 1rem;
}
.blog__img {
  width: 232px;
  height: 232px;
}
.blog__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0rem;
  border-radius: 10px;
}

/* //herosection SART// */
.hero_section {
  height: 100vh;
  width: 100%;
}
.hero_section img {
  z-index: -9;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* {HERO SLIDER START} */
.hero__slider {
  height: var(--home-height);
  background: transparent;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--text-color-black);
  line-height: 60px;
  text-align: center;
  font-family: var(--font-Montserrat);
}
.slick-slide .slick-active .slick-current {
  background: #fff;
}
.slick-dots {
  bottom: 20px !important;
}
.slick-prev,
.slick-next {
  z-index: 11 !important;
}
.slick-prev {
  left: 1rem !important;
}
.hero__slider .react__arrowPrev,
.hero__slider .react__arrowNext {
  color: #fff;
}

.react__arrowPrev {
  position: absolute;
  top: 50%;
  left: 10px;
  font-size: 3rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #000;
}

.react__arrowNext {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 3rem;
  cursor: pointer;
  opacity: 0.6;
  z-index: 13;
  color: #000;
}
.react__arrowPrev:hover,
.react__arrowNext:hover {
  opacity: 1;
}
.slick-next {
  right: 1rem !important;
}
.slick-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: var(--home-height) !important;
}

#slider_text h3 {
  margin-bottom: 2rem;
}

.slider_content_one {
  overflow: hidden;
  height: 100vh;
  width: 100vw !important;
  display: block !important;
}

/* {HERO SLIDER END} */
/* {PREFERENCE MODAL START} */
.preference__wrap {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100vh;
  position: fixed;
  z-index: 16;
  display: none;
}
.preference {
  position: absolute;
  top: 50%;
  position: initial;
  width: 705px;
  height: 380px;
  flex-direction: column;
  align-items: flex-start;
  color: #1b1b1b;
  justify-content: space-evenly;
  background: linear-gradient(
    164.53deg,
    rgba(41, 41, 41, 0.102) -28.7%,
    rgba(41, 37, 37, 0.15) 152.85%
  );
  backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);
  border-radius: 25px;
  transition: all ease-in-out 0.6s;
  transform: translateY(-100rem);
}
.preference.active {
  transform: translateY(0rem);
}
.preference__wrap.show {
  display: flex;
}

.pref__head h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  color: #1b1b1b;
  margin-bottom: -3rem;
  margin-left: 3rem;
}
.preference hr {
  width: 80%;
  opacity: 0.2;
  border: 1px solid #ffffff;
  margin-left: 3rem;
  margin-right: auto;
}
.pref__drop {
  margin-left: auto;
  margin-right: auto;
}
.pref__drop_wrap {
  padding-right: 3rem;
}
.pref__drop_wrap p {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
}
.country__dropdown,
.currency__dropdown,
.country__drop__list {
  width: 277px;
  height: 47px;
  justify-content: flex-start;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.2652) -28.7%,
    rgba(255, 255, 255, 0.39) 152.85%
  );
  backdrop-filter: blur(63px);
  -moz-backdrop-filter: blur(63px);

  border-radius: 10px;
}
.country__drop__list {
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.3652) -28.7%,
    rgba(255, 255, 255, 0.39) 152.85%
  );
  backdrop-filter: blur(0px);
  -moz-backdrop-filter: blur(0px);

  backdrop-filter: contrast(0%);
  -moz-backdrop-filter: contrast(0%);
}
.currency__dropdown p,
.country__dropdown p,
.country__drop__list option {
  flex: 1;
  display: flex;
  margin-bottom: 0;
  margin-left: 1.5rem;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.down_arrow {
  margin-right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.country__drop__list {
  max-height: 15rem;
  height: 12.4rem;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
  background: #dadadad7;
}
.country__drop__list.active {
  visibility: visible;
  opacity: 1;
}
.country__drop__list::-webkit-scrollbar {
  display: none;
}
.country__drop__list option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.3rem;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0.3rem;
  padding-left: 1.5rem;
  cursor: pointer;
}
.country__drop__list option:hover {
  color: var(--text-color-white);
  background: #1b1b1b;
}
.pref__footer {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
}
.pref__footer button {
  font-weight: var(--normal-font-weight);
  font-size: var(--text-font-size);
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.04em;
  width: 270px;
  height: 45px;
  border-radius: 10px;
  margin-bottom: 0.8rem;
  font-size: 18px;
  color: var(--text-color-black);
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.43) -28.7%,
    rgba(255, 251, 251, 0) 152.85%
  );
  border: none;
  transition: var(--animation);
}
.pref__footer button:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
  color: #95621a;
}

.pref__footer p {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.pref_close_btn {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
/* {PREFERENCE MODAL END} */

/* HERO SECTION END */

/* {INTRODUCTION SECTION START} */
.intro_section {
  padding: 0;
  margin: 0;
  position: relative;
  min-height: 50vh;
  background: #8c5c37;
  color: var(--text-color-white);
  width: 100vw;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: baseline;
  justify-content: center;
  height: fit-content;

  -moz-height: fit-content;
}
.intro_logo > img {
  width: 200px;
  height: 220px;
}
.intro_section > img {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: -1;
  object-fit: cover;
}
.content_text p {
  font-weight: var(--normal-font-weight);
  font-size: var(--normal-font-size);
  line-height: 28px;
  color: var(--text-color-white);
}
/* {INTRODUCTION SECTION END} */

/* {PRODUCT SECTION START} */
.section__head {
  text-align: center;
  font-family: var(--font-Montserrat);
  font-weight: 800;
  font-size: 40px;
  color: var(--text-color-black);
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.product_section {
  height: fit-content;
  -moz-height: fit-content;

  background: var(--div-bg-white);
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  width: 100vw;
}
.product_name_wrap {
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.product_section h1 {
  letter-spacing: 0.02em;
  color: #333;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  text-transform: capitalize;
  width: fit-content;
  -moz-width: fit-content;

  align-self: flex-start;
  border-bottom: 1px solid #333;
  margin-left: 2rem;
}
.product_section > hr {
  background-color: #8c5c37;
  width: 95%;
  margin-top: 0.3rem;
}
/* PRODUCT SECTION CARD START */

.card__contianer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.card__contianer .card_wrap {
  width: 250px !important;
  margin-left: 2rem;
  margin-right: 2rem;
  height: fit-content;
  -moz-height: fit-content;

  padding: 0;
}
.product_name_div {
  width: 100%;
}
.card__contianer .row {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  margin-left: 0;
  justify-content: center;
}
.card_wrap {
  position: relative;
  z-index: 0;
}
.card__img {
  width: 250px;
  height: 250px;
}
.card__img img {
  object-fit: cover;
}
.card__label {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.card__label label {
  width: 128px;
  font-size: 29px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--text-color-white);
}
.AddToCart__label {
  position: absolute;
  z-index: 1;
  width: 200px;
  top: 200px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background: linear-gradient(
    164.53deg,
    rgba(255, 255, 255, 0.102) -28.7%,
    rgba(255, 255, 255, 0.15) 152.85%
  );
  backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  visibility: hidden;
  opacity: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddToCart__label p {
  margin: 10px;
  color: #5c3d2e;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.02em;
  overflow: hidden;
  transition: var(--animation);
}
.add__cart_btn .btn {
  font-size: 14px;
}
.add__cart_btn a {
  text-decoration: none;
  color: currentColor;
  transition: var(--animation);
}
.add__cart_btn:hover a {
  letter-spacing: 3px;
  color: #95621a;
}
.add__cart_btn a span {
  position: absolute;
  display: block;
  transition: 0.5s ease;
}
.add__cart_btn a span:nth-child(1) {
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(1) {
  width: 100%;
  transform: translateX(0%);
}
.add__cart_btn a span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(3) {
  width: 100%;
  transform: translateX(0%);
}
.add__cart_btn a span:nth-child(2) {
  top: 0;
  left: 0;
  width: 1px;
  height: 0;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(2) {
  height: 100%;
  transform: translateY(0%);
}
.add__cart_btn a span:nth-child(4) {
  bottom: 0;
  right: 0;
  width: 1px;
  height: 0;
  background: #8c5c37;
}
.add__cart_btn a:hover span:nth-child(4) {
  height: 100%;
  transform: translateY(0%);
}
.add__cart_btn a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(-40deg) translateX(0);
  transition: all 0.5s ease-out;
}
.add__cart_btn:hover a::before {
  transform: skewX(0deg) translateX(100%);
  border-radius: 10px;
}
.product_name h4 {
  padding-top: 1rem;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
  color: #333;
}
.product_name h4:nth-child(2) {
  font-weight: 600;
}
.product_name p {
  color: #95621a;
  font-weight: 300;
  font-size: 15px;
  line-height: 50px;
  text-align: start;
  margin-bottom: 0;
  margin-top: -1rem;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.product_name p:hover {
  color: #a86d20d5;
}
.card_wrap:hover .AddToCart__label {
  opacity: 1;
  visibility: visible;
}
.card_wrap:hover .card__img_second {
  opacity: 1;
  visibility: visible;
}
.card__img_second {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation-4s);
}
.card__img_second img {
  object-fit: cover;
  cursor: default;
}

/* PRODUCT SECTION CARD END    */

/* {PRODUCT SECTION END} */

/* {TESTIMONIAL SECTION -SLIDER START} */
.testimonial {
  --bg-testimonial: #ffffff;
  background: var(--bg-testimonial);
  height: 570px;
  color: #000;
}
.testimonial .slick-slider,
.testimonial .slick-list,
.testimonial .slick-track {
  height: 570px !important;
}
.testimonial .slick-initialized .slick-slide {
  height: 570px !important;
  width: 100vw;
}
#tm__slider__wrap {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
.quotation__mark {
  width: 30px;
  height: 30px;
  margin-bottom: 2rem;
}
.quotation__mark svg {
  font-size: 2rem;
  color: #cc9b6d;
}
.quote__text {
  width: 500px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
.tm__images {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  border: 2px solid #fff;
  margin-bottom: 10px;
}
.tm__images img {
  border-radius: 100%;
  object-fit: cover;
}
#tm__slider__wrap h1 {
  font-size: 18px;
  margin-bottom: 0;
}
.testimonial .slick-dots li {
  margin-left: 20px;
}
.testimonial .slick-dots li button:before {
  color: var(--bg-testimonial);
  background: rgb(0, 0, 0);
  width: 35px;
  height: 4px;
  border-radius: 10px;
}
.testimonial .slick-dots li.slick-active button:before {
  color: rgb(255, 255, 255);
}
.testimonial .slick-dots {
  margin-left: -23px;
}
/* {TESTIMONIAL SECTION -SLIDER END} */

/* //mailing section START// */

.fade__bg {
  width: 45%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.fade__bg label {
  width: 100%;
  padding: 8rem;
}
.fade__bg_rt {
  background: linear-gradient(-45deg, rgba(0, 0, 0, 1), transparent);
  width: 100%;
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.fade__bg.second label {
  margin-left: 11rem;
}
.fade__bg_rt label {
  margin-right: 4rem;
  width: 300px;
  text-align: end;
}
.mail_section {
  width: 100vw;
  height: 240px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 0rem;
  background: #fff;
  background-position-x: right;
}
.mail_section img {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.mail_section h4 {
  font-weight: 200;

  font-size: 30px;

  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0;
}
.mail_section h1 {
  font-weight: 600;

  font-size: 30px;

  color: #ffffff;
  text-transform: uppercase;
}
.mail_header > h1 {
  font-family: var(--font-Montserrat);
  font-weight: 800;
  font-size: 40px;
  line-height: var(--extra-line-height);
  text-align: center;
  margin-bottom: 20px;
  --text-color: #1b1b1b;
}
.mail_section_content {
  flex-direction: column;
  font-weight: var(--normal-font-weight);
  font-size: var(--normal-font-size);
  line-height: 28px;
  text-align: center;
  color: #1b1b1b;
}
.mail_section_content p {
  margin-bottom: 30px;
}
.join_btn {
  background: var(--div-bg);
  border: 2px solid #1b1b1b;
  padding: 18px;
  width: 285px;
  font-size: larger;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  transition: var(--animation);
  z-index: 0;
  height: 50px;
}
.join_btn::after {
  content: "";
  background-color: var(--div-bg-black);
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0);
  transform-origin: top left;
  transition: var(--animation);
  z-index: -1;
}
.join_btn:hover::after {
  transform: translate(0, 0);
  animation: fillup var(--animation);
}

/* mahalo extra css */
.hero_section {
  position: relative;
  width: 100vw;
}
.hero_section label {
  position: absolute;
  font-family: var(--font-Lacquer);
  font-size: 42.125px;
  line-height: 52px;
  color: #e5dac8;
  left: 8rem;
  bottom: 17rem;
  width: 477px;
}
.txt__hover {
  font-weight: 600;
}
.hero__fade {
  background: linear-gradient(
    179.09deg,
    rgba(0, 0, 0, 0.86) 0.58%,
    rgba(0, 0, 0, 0) 80.22%
  );
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes drop {
  0% {
    bottom: 0px;
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    bottom: -100px;
  }
}
.drops {
  -webkit-filter: url("#liquid");
  filter: url("#liquid");
  z-index: 1;
  opacity: 0;
  animation: fade-in 0.1s linear 0.4s forwards;
}
.drop {
  bottom: 0;
  margin: auto;
  background-color: #572a12;
  top: 3rem;
  opacity: 0;
  visibility: hidden;
  border-radius: 50%;
  position: absolute;
}
.drop.t {
  left: 3px;
  width: 8px;
  height: 18px;
  top: 6px;
}
.txt__hover {
  position: relative;
}
.txt__hover:hover {
  color: #cc9b6d;
}

.drop.f {
  width: 6px;
  height: 17px;
  left: 3rem;
  top: -2rem;
}

.drop.s {
  width: 8px;
  height: 18px;
  left: 3.6rem;
  top: 4rem;
}
.txt__hover.b:hover .drop.f.se,
.txt__hover.e:hover .drop.tr.se,
.txt__hover.e:hover .drop.s {
  animation: drop 4.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
  opacity: 1;
  visibility: visible;
}
.txt__hover.e:hover .drop.s.se {
  animation: drop 6.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
  opacity: 1;
  visibility: visible;
}
.drop.tr {
  width: 8px;
  height: 18px;
  left: 1.2rem;
  top: 4rem;
}
.txt__hover.b:hover .drop.f,
.txt__hover.e:hover .drop.tr {
  animation: drop 2.3s cubic-bezier(1, 0.19, 0.66, 0.12) 0.5s infinite;
  opacity: 1;
  visibility: visible;
}
/* induduction */

.content_heder h1 {
  font-family: var(--font-raleway);
  font-weight: 800;
  font-size: 100.582px;
  line-height: 85px;
  text-shadow: -1px 1px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255),
    1px -1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
  color: #8c5c37;
  text-transform: uppercase;
  margin-right: 2rem;
}
.content_text p {
  font-family: var(--font-raleway);
  font-size: 16px;
  color: #ffffff;
}
.content_text {
  display: flex;
}
.intro_content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;

  height: fit-content;
  -moz-height: fit-content;

  padding: 3rem;
}
.content_heder {
  height: fit-content;
  -moz-height: fit-content;

  width: 800px;
}
@keyframes fillup {
  100% {
    transform: translate(0, 0);
  }
  0% {
    transform: translate(-100%, 0);
  }
}
.join_btn:hover {
  color: var(--text-color-white);
}

@media screen and (max-width: 896px) {
  .layamer_logo_wrap {
    margin-left: 3rem;
  }
  .hero_section label {
    bottom: 0rem;
  }
  .hero_section label {
    width: 448px;
  }
  .content_heder h1 {
    font-size: 90px;
    line-height: 75px;
  }
  .content_text {
    top: 7rem;
    left: 12rem;
  }

  .arrowBgNext.wrap {
    left: 15%;
  }
}

@media screen and (max-width: 768px) {
  .Blog_new .slick-slide {
    width: 180px !important;
    border-radius: 12px;
  }
  .arrowBgNext.wrap {
    left: 11rem;
  }
  .blog__img {
    width: 160px;
    height: 160px;
  }
  .fade__bg_rt label {
    width: 182px;
    margin-right: 1rem;
  }
  .content_heder h1 {
    font-size: 65px;
    line-height: 55px;
  }

  .hero_section label {
    width: 470px;
  }
}

@media screen and (max-width: 580px) {
  .Blog_new .slick-list {
    width: 100% !important;
    margin-left: auto;
  }
  .hero_section label {
    font-size: 30.125px;
    line-height: 40px;
    width: 336px;
    left: 5rem;
  }
  .quote__text {
    width: 350px;
  }
  .card__img {
    width: 180px;
    height: 180px;
  }
  .card__img_second {
    width: 180px;
    height: 180px;
  }
  .product_name_wrap {
    width: 180px;
  }
  .AddToCart__label {
    width: 150px;
    top: 150px;
  }
  .mail_section h4 {
    font-size: 28px;
  }
  .mail_section h1 {
    font-size: 28px;
  }
  .fade__bg label {
    margin-left: 1rem;
    width: 100px;
  }
  .card__img,
  .card__img_second {
    width: 160px;
    height: 160px;
  }

  .content_text p {
    font-size: 14px;
  }
  .AddToCart__label {
    width: 130px;
    top: 130px;
    height: 30px;
  }
  .AddToCart__label p {
    font-size: 11px;
  }
  .product_name_wrap {
    width: 160px;
  }
  .card__contianer .card_wrap {
    width: 160px !important;
  }
  .footer_foot p {
    text-align: center;
    margin-left: 0rem;
  }
  .content_text {
    flex-direction: column;
    align-items: center;
  }
  .content_heder {
    position: relative;
  }
  .content_heder h1 {
    font-size: 40px;
    line-height: 35px;
    margin-bottom: 2rem;
  }
  .content_text h1 {
    position: absolute;
    top: 0;
    left: 155px;
  }
  .product_section > h1 {
    font-size: 18px;
  }
  .card__contianer {
    padding-top: 1rem;
  }
  .product_name p {
    font-size: 12px;
    line-height: 50px;
  }

  .Blog_new .fading {
    width: 200px;
  }
  .arrowBgPrev.wrap,
  .arrowBgNext.wrap {
    bottom: 10px !important;
    top: unset;
  }
  .Blog_new .fading {
    display: none;
  }
  .fading h1 {
    font-size: 21px;
  }
  .Blog_new {
    height: 80vh;
  }
  .fade__bg label {
    width: 100%;
    padding: 2rem;
  }
}
@media screen and (max-width: 420px) {
  .mail_section.two img {
    object-position: right;
  }
  .fade__bg label {
    margin-left: -8rem;
  }
  .fade__bg.two label h4 {
    color: #333 !important;
  }
  .fade__bg.two label h1 {
    color: #333 !important;
  }
  .mail_section img {
    object-fit: cover;
    object-position: left !important;
  }

  .mail_section.two img {
    object-position: right !important;
  }
  .fade__bg {
    left: auto;
  }
  .hero_section label {
    font-size: 26.125px;
    line-height: 31px;
    width: 292px;
    left: 3rem;
  }
  .hero_text_new {
    font-size: 15px;
    line-height: 22px;
  }
  .cart__wrapper {
    padding: 1.5rem;
  }
  .mail_section h4 {
    font-size: 20px;
  }
  .mail_section h1 {
    font-size: 20px;
  }

  .fade__bg_rt label {
    width: 215px;
  }
  .quote__text {
    width: 275px;
  }
  .tm__images {
    height: 100px;
    width: 100px;
  }

  .product_section > h1 {
    font-size: 14px !important;
  }
  .product_name h4 {
    font-size: 13px;
  }

  .mail_section .fade__bg label {
    margin-bottom: 0;
  }
  .card__img,
  .card__img_second {
    width: 130px;
    height: 130px;
  }
  .card__contianer .card_wrap {
    width: 130px !important;
  }
  .card__contianer .row {
    padding: 0 !important;
  }
}
@media screen and (max-width: 370px) {
  .arrowBgNext.wrap {
    left: 8rem;
  }
  .arrowBgPrev.wrap {
    left: 3rem;
  }
  .card__img,
  .card__img_second {
    width: 120px;
    height: 120px;
  }
  .card__contianer .card_wrap {
    width: 120px !important;
  }
  .footer_foot p {
    font-size: 11px;
  }

  .fade__bg label {
    margin-left: -8rem;
    width: 125px;
  }
  .fade__bg_rt label {
    width: 134px;
    margin-bottom: 0;
    margin-right: 1rem;
  }
  .hero_section label {
    font-size: 26.125px;
    line-height: 33px;
    width: 277px;
    text-align: start;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, 50%) !important;
  }
  .content_heder h1 {
    font-size: 35px;
  }
  .content_text h1 {
    left: 138px;
  }
}

@media screen and (max-width: 320px) {
  .quote__text {
    width: 225px;
  }
  .mail_section h4 {
    font-size: 20px;
  }
  .mail_section h1 {
    font-size: 20px;
  }
}


.scraf__section {
  margin-top: 2rem;
}
.product__scarf {
  background: #fff;
  color: #1b1b1b;
  padding-top: 130px;
}
.product-hero {
  height: 55vh;
  padding-top: 133px;
  position: relative;
}
.product__scarf .mail_section label {
  width: 100%;
}
.product__scarf .mail_section label h4 {
  font-size: 20px;
  color: #fff;
  letter-spacing: 1.5px;
  font-weight: bold;
}
.product-hero img {
  object-fit: cover;
  cursor: default;
}
.product-hero span {
  position: absolute;
  background: #fff;
  width: 19px;
  height: 19px;
  background: rgba(253, 253, 253, 0.85);
  box-shadow: 3px 3px 9px 3px rgba(0, 0, 0, 0.31);
  border-radius: 100%;
  cursor: pointer;
}
.product-hero span:nth-child(2) {
  left: 14rem;
  top: 12rem;
}
.product-hero span:nth-child(3) {
  left: 20rem;
  top: 4rem;
  z-index: 16;
}
.product-hero span:nth-child(4) {
  left: 29rem;
  top: 29rem;
}
.product-hero span:nth-child(5) {
  top: 7rem;
  left: 47rem;
}
.product-hero span:nth-child(6) {
  left: 52rem;
  top: 21rem;
}
.product-hero span:nth-child(7) {
  left: 60rem;
  top: 15rem;
}
.product__nav {
  margin-top: 2rem;
  margin-right: auto;
  position: relative;
  justify-content: space-around;
}

.product__nav h1 {
  font-weight: normal;
  font-size: 35px;
  line-height: 75px;
}
.product_list hr {
  height: 3px;
  border-radius: 5px;
  width: 93%;
  margin-bottom: 3rem;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.center_align {
  justify-content: center;
}

.card__contianer .card_wrap {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}

.dropdown-menu {
  bottom: -27rem;
  margin-top: 0.5rem !important;
  transition: var(--animation);
  background: linear-gradient(
    164.53deg,
    rgba(64, 64, 64, 0.258) -28.7%,
    rgba(41, 37, 37, 0) 152.85%
  );
  backdrop-filter: blur(15px);
  -moz-backdrop-filter: blur(15px);
  border-radius: 10px;
  min-width: 6rem;
}
.dropdown-toggle {
  background: #fff;
  color: #111;
  border: 1px solid rgb(145, 144, 144);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 10px;
}
.dropdown-toggle:hover {
  background: transparent;
  color: #111;
  border: 1px solid #1b1b1b;
}
.dropdown-toggle:focus {
  outline: none !important;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
  background: #fff;
  color: #111;
  border: 1px solid #1b1b1b;
}
.dropdown-item:focus {
  background: #cccc;
  color: #111;
}

@media screen and (max-width: 768px) {
  .product__scarf .mail_section label h4 {
    font-size: 18px;
  }
  .product-hero {
    padding-top: 100px;
    height: 49vh;
  }
}

@media screen and (max-width: 536px) {
  .card__contianer .card_wrap {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .fade__bg_ptd {
    padding-left: 2rem;
  }
}

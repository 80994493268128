#new_select section {
  height: 22px;
  padding: 0;
  padding-left: 10px;
}
.profile_new .input__wrap {
  margin-bottom: 2rem;
}
.db__seting .db_sett {
  align-self: flex-start;
  margin-left: 2rem !important;
}
.db__seting .db_product_form {
  margin-left: 50px;
  margin-right: auto;
  width: 500px;
}
.db__seting {
  width: 400px;
  min-height: 300px !important;
  height: 300px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 25% !important;
}
#new_date {
  padding: 1rem;
  padding-top: 0;
}
.reviewMessageText {
  height: max-content !important;
}

.user__wrap {
  justify-content: space-between;
}

a:link {
  text-decoration: unset !important;
  color: unset !important;
  background-color: unset !important;
}

a:link::before {
  text-decoration: unset !important;
  color: unset !important;
  background-color: unset !important;
}

.billing__info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
}

.starButton {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.starButton .starfill__icon,
.starButton .star__icon {
  font-size: 2rem !important;
}
.adminPanelText {
  transition: var(--animation);
  font-weight: 300;
  font-size: 12px;
  line-height: 36px;
  color: #545454;
  margin-bottom: 0;
  letter-spacing: 0.1rem;
}

.star__icon {
  font-size: 1rem;
  color: #545454;
  cursor: pointer;
}
.starfill__icon {
  font-size: 1rem;
  color: #d75105;

  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
}

.od__view .shipping__info {
  width: 300px;
}

#current .react-slidedown.closed {
  display: block !important;
  height: auto !important;
}
.pro-item-content a {
  color: currentColor !important;
}
.pro-item-content a:hover {
  color: currentColor !important;
}
.db__sidebar_wrap {
  position: fixed;
  height: 100vh;
  overflow-y: scroll;
}

.db__sidebar_wrap::-webkit-scrollbar {
  display: none;
}
.db__home_left {
  background-color: #f5f5f5;
  width: calc(100% - 330px);
  margin-left: auto;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul,
.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item {
  padding: 0 !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding-left: 1.5rem !important;
}
.pro-item-content {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.pro-icon .title_icon {
  color: #545454;
}
.react-slidedown.pro-inner-list-item,
.pro-menu.shaped.square ul {
  border-radius: 15px !important;
}
#current ul {
  background-color: rgb(245, 245, 245);
}
.pro-item-content a {
  font-size: 16px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding-right: 6rem !important;
}
.db_sd_title.current ul {
  background-color: rgb(245, 245, 245);
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b00 !important;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #fff !important;
}
.db_sd_title.current {
  background-color: rgb(245, 245, 245) !important;
}

.pro-inner-item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
#alpt .pro-inner-item .pro-item-content a {
  font-size: 16px !important;
}

.pro-menu-item.active .pro-item-content a {
  font-weight: bold !important;
  letter-spacing: 0.4px !important;
}
.pro-menu-item.active .pro-menu.shaped.square ul {
  background-color: rgb(245, 245, 245) !important;
}
.pro-menu-item .pro-sub-menu {
  background: #fff !important;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b00 !important;
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2b2b2b00;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul:hover {
  background-color: rgb(245, 245, 245) !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
  border-radius: 15px;
  justify-content: flex-start;
}
.pro-sidebar
  > .pro-sidebar-inner
  > .pro-sidebar-layout
  ul:hover
  .pro-icon-wrapper
  .pro-icon {
  background-color: rgb(245, 245, 245) !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  transition: var(--animation);
  font-weight: 300;
  font-size: 16px;
  line-height: 36px;
  color: #545454;
  cursor: pointer;
  margin-bottom: 0;
}
.title__icon {
  color: #545454;
  cursor: pointer;
  font-size: 16px;
  transition: var(--animation);
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: rgb(245, 245, 245);
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: none !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 48px !important;
}
.pro-sidebar {
  background-color: rgb(245, 245, 245) !important;
}

.db_sd__title.current {
  background-color: rgb(245, 245, 245);
}

.db__home {
  min-height: 100vh;
  height: 100%;
  display: flex;
}
.db__sidebar a {
  text-decoration: none;
  color: currentColor;
}
.db__sidebar_wrap {
  width: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
}
.db__sidebar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}
.db__logo {
  width: 150px;
  height: 50px;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.db_sd__title {
  width: 261px;
  min-height: 60px;
  height: auto;
  justify-content: flex-start;
  border-radius: 15px;
  margin-bottom: 0.5rem;
  transition: var(--animation);
}
.db_sd__title:hover {
  background-color: rgb(245, 245, 245);
}

.db_sd__title h1 {
  transition: var(--animation);
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #545454;
  cursor: pointer;
  margin-bottom: 0;
}
.db__title_icon {
  color: #545454;
  cursor: pointer;
  font-size: 20px;
  margin-left: 2rem;
  margin-right: 1.5rem;
  transition: var(--animation);
}
.db_sd__title {
  position: relative;
}
.od_db_down,
.od_db_up {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;
}

.order__wrap.show .od_db_down {
  opacity: 0;
  visibility: hidden;
}
.od_db_up {
  opacity: 0;
  visibility: hidden;
}
.od_db_down {
  color: #545454;
  opacity: 1;
  visibility: visible;
}
.order__wrap.show .od_db_up {
  opacity: 1;
  visibility: visible;
}
.db__user_wrap p,
.order__wrap p {
  display: none;
  margin-top: 1rem;
  opacity: 0.7;
  transition: var(--animation);
}
.db__user_wrap p:hover,
.order__wrap p:hover {
  opacity: 1;
}
.order__wrap .current {
  opacity: 1;
}
.db__user_wrap.show p,
.order__wrap.show p {
  display: flex;
  cursor: pointer;
  font-weight: 500;
}
.db_sd__title.current .order__wrap {
  padding-top: 1rem;
}
.db_sd__title.current .db__user_i {
  height: 148px;
  padding-top: 1.2rem;
}
.db__user_wrap.show p:nth-child(2) {
  margin-top: 8px;
}
.db__user_wrap.show p.current {
  opacity: 1;
}
.db__user_wrap.show .us_up {
  opacity: 1;
  visibility: visible;
}
.db__user_wrap.show .us__down {
  opacity: 0;
  visibility: hidden;
}

.Db__header {
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgb(0 0 0 / 5%);
  margin: 2rem;
  margin-bottom: 0.5rem;
  border-radius: 0 0 12px 12px;
  display: flex;
  margin-top: -4px;
}
.header__name {
  flex: 1;
  justify-content: flex-start;
  margin-left: 2rem;
}
.header__name h1 {
  font-weight: 300;
  font-size: 15px;
  color: #545454;
  margin-bottom: 0;
}
.Db__head_icon {
  margin-right: 2rem;
}
.admin__avatar img,
.admin__avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}
.head__icon {
  font-size: 20px;
  cursor: pointer;
}
.head__icon:hover {
  color: #d75105;
}
.bell_icon_wrap {
  position: relative;
  display: none;
}
.bell_icon_wrap label {
  position: absolute;
  background: #d75105;
  border: 1.5px solid #ffffff;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 100%;
  right: 0;
  display: none;
}

.db__reviews h1 {
  font-weight: 500;
  font-size: 17px;
  line-height: 60px;
}
/* .db__home_left {
  width: 100%;
} */
.db__reviews {
  margin: 2rem;
  background: #fff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  min-height: 74vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.sad__icon {
  font-size: 11rem;
  opacity: 0.2;
}
.db__defualt_reviews {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
}
.db__defualt_reviews p {
  opacity: 0.3;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  color: #545454;
}

.newPtd {
  margin-left: 18rem !important;
}
.db_total__amount {
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 2rem;
  margin: 1rem 2rem 1rem 2rem;
}
.db_total__amount p {
  font-weight: 300;
  font-size: 16px;
  color: #545454;
  margin-bottom: 0.5rem;
}
.db_total__amount h1 {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
}
.db__info_wrap {
  display: flex;
  justify-content: space-between;
  margin: 1rem 2rem 1rem 2rem;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: flex-start;
}
.db__info_wrap:last-child {
  margin-right: 0;
}
.db__pro {
  width: 23%;
  max-width: 240px;
  height: 170px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
}
.db__pro p {
  font-weight: 300;
  font-size: 14px;
  color: #545454;
  margin: 1rem;
  margin-bottom: 0.5rem;
}
.db__pro h1 {
  margin: 1rem;
  margin-top: 0;
  font-weight: 500;
  font-size: 20px;
}
.db__pro h1 span {
  line-height: 50px;
}
.db__pro button {
  width: 100%;
  position: absolute;
  bottom: 0;

  border-top: 1px solid #5454542f;
  border-radius: 0px 0px 15px 15px;
  height: 50px;
  font-weight: 300;
  font-size: 14px;
  line-height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: var(--animation);
}
.db__pro button:hover {
  letter-spacing: 1px;
  background: #184d47;
  color: #c6ebc9;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}
.fa-trash {
  transition: var(--animation);
}
.fa-trash:hover {
  color: red;
}

.db__orders {
  align-items: flex-start;
}
.db__orders h1 {
  margin: 1rem 2rem 3rem 3rem;
  width: 100%;
  width: fit-content;
  border-bottom: 2px solid #cfcfcf;
  height: 50px;
}

.db_sd__title.product {
  align-items: stretch;
  position: relative;
}
.db_sd__title.product.current .db__pdt_content h1 {
  font-weight: 500 !important;
}
.db__pdt_content {
  padding-top: 1rem;
}
.db__pdt_content h1 {
  margin-bottom: 1rem;
}
.db_sd__title.product h2 {
  font-size: 16px;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: none;
}
.db_sd__title.product p {
  font-size: 16px;
  font-weight: 200;
  cursor: pointer;
  display: none;
}
.db_sd__title.product .current {
  opacity: 1;
}
.db__book_icon {
  padding-top: 1.3rem !important;
}
.db__pdt_content.show h2,
.db__pdt_content.show p {
  opacity: 0.7;
  transition: var(--animation);
}
.db__pdt_content.show h2:hover,
.db__pdt_content.show p:hover {
  opacity: 1;
}
.db__pdt_content.show h2,
.db__pdt_content.show p {
  display: flex;
}
.db_down,
.db_up {
  position: absolute;
  right: 2rem;
  color: #545454;
  top: 1.7rem;
  transition: var(--animation);
  cursor: pointer;
}
.db_up {
  opacity: 0;
  visibility: hidden;
}
.db__pdt_content.show .db_up {
  opacity: 1;
  visibility: visible;
}
.db__pdt_content.show .db_down {
  opacity: 0;
  visibility: hidden;
}
.osperb p {
  padding-bottom: 2rem;
  font-size: 13px;
  color: #545454;
}

.osperb {
  bottom: 0;
}
.db__sup {
  margin-bottom: 2rem;
}

.db__column_link {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 1.4rem;
  width: 150%;
}

.db__column_btn {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 1.4rem;
  width: 40%;
}

.db__column_btn button {
  color: #fff;
}

.db__column_btn button:hover {
  background-color: #fff;
  color: #111;
  border: 1px solid;
}
.freeSpace.new {
  width: 5rem;
}

.db__column {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem;
  width: 100%;
}
.db__column label {
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #545454;
  margin-bottom: 0.6rem;
  text-transform: capitalize;
}
.db_rdio {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.db_sheet_btn {
  width: fit-content;
}
.db__column .btn {
  height: 30px;
  width: fit-content;
  -moz-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.db__column .btn svg {
  height: 25px;
  width: 25px;
}
.db__column .btn svg:hover {
  color: var(--danger-color);
}
.db__column input {
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  color: #545454;
  padding-left: 1rem;
  width: 100%;
}
.db__column input:focus {
  outline: none;
}
.db__column textarea {
  padding: 1rem;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
}
.db__column textarea:focus {
  outline: none;
}
.db__column .dropdown button {
  width: 358px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  color: #545454;
  text-transform: capitalize;
}
.db__column .dropdown button:focus {
  outline: none;
  box-shadow: none;
}
.db__column .dropdown-menu {
  width: 100%;
  height: auto;
  background: #fff;
  text-transform: capitalize;
}
.Category_wrap,
.db__prdt_name_price {
  display: flex;
}
.db__new_prdt {
  margin: 2rem;
  margin-top: 0;
  width: 100%;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.img__upload {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  color: #545454;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.db_product_form {
  margin-left: auto;
  margin-right: auto;
  width: 550px;
}
.dbb_new_input input {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 1.4rem;
  width: 25%;
}
.media__icon {
  cursor: pointer;
  font-size: 23px;
  margin-right: 2rem;
  height: 100%;
}
.db_rdio label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 19px !important;
  width: 50%;
}
.Seller_wrap img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: default;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.db__prices div {
  display: flex;
  flex-direction: column;
  width: 150px;
}
.db__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-right: 1rem;
}
.db__prices div label {
  text-transform: uppercase;
  font-size: 14px;
  color: #545454;
}
.db__prices div input {
  border: 1px solid rgba(84, 84, 84, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  color: #545454;
  margin-right: 1rem;
  padding: 0.5rem;
  font-size: 14px;
}
.db__prices div input:focus {
  outline: none;
}
.db__new_prdt p {
  color: #545454;
  text-transform: capitalize;
  margin-bottom: 0;
  width: 100%;
  text-align: start;
}
#checkbox_field1 {
  width: 15px;
  margin-left: -70px;
}
.add__prdt:hover {
  color: #fff;
}
.db_btn__add {
  width: 100%;
  margin-top: 1rem;
}

#checkbox_field1,
#checkbox_field2,
#checkbox_field3 {
  width: 15px;
  margin-left: -60px;
}

.add__prdt {
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 30px;
  transition: var(--animation);
  background-color: darkgreen;
  color: #fff;
}
.add__prdt:focus {
  box-shadow: none;
}
.db__table {
  width: 90%;
  margin: 2rem;
  margin-top: 0;
}
.db__column .checkmark {
  border-radius: unset;
  border: 1px solid rgba(84, 84, 84, 0.5);
  opacity: 0;
}
.db__column .input__radio_wrap {
  margin-top: 0.5rem;
}

.db__testnomial {
  margin-left: auto;
  margin-right: auto;
}
#testnomial-img {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.order__heads {
  display: flex;
}
.od_head__text {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.od_head__text h1 {
  margin-bottom: 0.3rem;
  line-height: 30px;
}
.od_head__text p {
  margin-left: 2rem;
}
.order__heads {
  width: 100%;
}
.od__star {
  padding: 2rem;
  width: 150px;
  position: relative;
}
.od__start_toggle {
  width: 30px;
}
.star__icon {
  font-size: 1rem;
  color: #545454;
  cursor: pointer;
}
.starfill__icon {
  font-size: 1rem;

  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: var(--animation);
}
.starfill__icon.active {
  visibility: visible;
  opacity: 1;
}
.order__info_wrap {
  margin-left: auto;
  margin-right: auto;
}
.db__home .order__info_wrap {
  padding: 2rem;
  margin-right: 2rem;
  width: 100%;
}
.db__home .db__orders h1 {
  border-bottom: none;
}
.od__view .order__info_wrap h1 {
  margin: 0;
  margin-top: 1rem;
  font-size: 20px;
}
.od__view .shipping__info {
  width: 300px;
}

.od__view .payment__data {
  width: 400px;
}
.status__dropdown .dropdown button {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  color: #545454;
}
.od__btn:focus,
.status__dropdown .dropdown button:focus {
  outline: none;
  box-shadow: none;
}
.status__dropdown .dropdown-menu.show {
  width: 100%;
  background: #fff;
}
.od__btn {
  width: 200px;
  margin-top: 4rem;
  background: #111;
  color: #fff;
  transition: var(--animation);
}
.od__btn:hover {
  background: #fff;
  color: #111;
  border: 1px solid #111;
}
.order__item__od {
  width: 100%;
  padding: 2rem;
}
.order__item__od .order__item__table {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #545454 !important;
}
.order__item__od h1 {
  margin: 0;
  margin-bottom: 1rem;
  font-size: 20px;
}
.od__view {
  color: #545454 !important;
}
.stared__wrap .order__item__table {
  height: 60px;
  width: 70%;
}
.stared__wrap .order__item__table div {
  height: 50px;
}
.stared__wrap .item__img {
  margin-top: -0.7rem;
  height: 50px;
  margin-bottom: 0.5rem;
  width: 70px;
}
.stared__wrap .item__img img {
  object-fit: cover;
}
.stared__wrap .uq .starfill__icon {
  left: 0.734rem;
}
.od__faeye {
  cursor: pointer;
}
.od__faeye:hover {
  color: #d75105;
}
.db__user_edit {
  margin-left: auto;
  margin-right: auto;
  width: 500px;
}
.db__user_edit .input__wrap {
  width: 500px;
}
.user__type label {
  color: #545454;
}
.user__type select {
  border: 1px solid #545454;
  text-transform: capitalize;
  width: 200px;
  color: #545454;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.user__type .dropdown .dropdown-menu.show {
  width: 200px;
  background: #fff;
}
.user__type .dropdown button:focus {
  outline: none;
  box-shadow: none;
}
.btn__user__up {
  margin-top: 2rem;
  width: 200px;
  background: #545454;
  color: #fff;
  transition: var(--animation);
}
.btn__user__up:hover {
  color: #111;
  background: #ccc;
}
.btn__user__up:focus {
  box-shadow: none;
}
.bd__tb-new p {
  font-size: 14px;
  color: #464646;
  margin-bottom: 2rem;
}
.bd_tb_span {
  color: #000;
  font-weight: 500;
}
.db_sett {
  align-self: center;
  margin-left: -9rem !important;
  margin-bottom: 3rem !important;
  margin-top: 2rem !important;
}
.suc-btn {
  width: 100%;
  margin-top: 2rem;
}
.bd__tb-new {
  width: fit-content;
  -moz-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.db_price_sheet {
  border-radius: 10px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
}

.btn:focus {
  box-shadow: none;
}
.displya_tyext {
  display: none;
  height: 100vh;
  width: 100vw;
}
@media screen and (max-width: 800px) {
  .db__home {
    display: none;
  }
  .displya_tyext {
    display: flex;
  }
}
